<template>
  <div class="slider swiper" :id="`${component.name}Slider`" :class="{skeleton : !state.loaded }">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, idx) in state.items" :key="idx">
        <!-- location.origin 부분을 빈 값으로 REPLACE 하고 싶다면 UTM 트래킹 부분 고려해야 함. -->
        <Anchor class="wrapper" :href="item.bannerUrl">
          <span class="img" :style="{backgroundImage: `url('${item.imagePath}${item.imageSaveName}')`}"></span>
          <!--            <div class="image">-->
          <!--              <span class="img" :style="{backgroundImage: `url('${item.imagePath}${item.imageSaveName}')`}"></span>-->
          <!--            </div>-->
          <div class="bg" :style="{backgroundColor: item.bgColor || '#000'}"></div>
          <div class="container">
            <div class="text">
              <div class="wrapper">
                <div class="sub">
                  <span>{{ item.subTitle }}</span>
                </div>
                <div class="title">
                  <span :style="{color: item.titleColor || '#fff'}">{{ item.title }}</span>
                </div>
                <div class="desc">
                  <span :style="{color: item.titleColor || '#fff'}">{{ item.communityDesc }}</span>
                </div>
              </div>
            </div>
            <div :id="`${component.name}Pagination`" class="pagination"></div>
          </div>
        </Anchor>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentSlider";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      loaded: false,
      items: [{
        imagePath: "",
        imageSaveName: "",
        subTitle: "wait a moment",
        title: "Wait a moment\nWait a moment",
        communityDesc: "Please wait a moment",
        bannerUrl: "",
      }],
      swiper: null
    });

    const swiperRef = ref();

    const instantiate = () => {
      state.swiper = new window.Swiper(`#${component.name}Slider`, {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        autoHeight: true,
        autoplay: {
          delay: 4000,
        },
        pagination: {
          el: `#${component.name}Pagination`,
          clickable: true,
        }
      });
    };

    onMounted(() => {
      state.loaded = false;

      http.get("/api/main/banners/big", undefined, {cache: true}).then(({data}) => {
        state.loaded = true;
        state.items = data.body;
        nextTick(instantiate);
      }).catch(httpError());
    });

    return {component, state, swiperRef};
  },
});
</script>

<style lang="scss" scoped>
.slider::v-deep {
  background: $colorBackground;

  .swiper-wrapper {
    > .swiper-slide {
      background: $colorBackground;

      > .wrapper {
        display: block;
        position: relative;
        height: $px350;

        > .img {
          background-position: center;
          background-size: cover;
          display: block;
          height: 100%;
          width: 100%;
        }

        .bg {
          position: absolute;
          filter: blur(65px);
          bottom: $px-40;
          left: $px-65;
          opacity: 0.37;
          height: calc(50% + 20px);
          width: calc(100% + 200px);
        }

        > .container {
          position: absolute;
          bottom: $px34;
          left: 50%;
          transform: translateX(-50%);

          .text {
            width: 35%;
            color: #fff;

            > .wrapper {
              > .sub {
                font-size: $px20;
                padding-bottom: $px15;
              }

              > .title {
                cursor: pointer;
                font-size: $px28;
                line-height: 1.5;
                font-weight: 600;
                padding-bottom: $px20;
                white-space: pre-line;
              }

              > .desc {
                cursor: pointer;
                font-size: $px18;
              }

              > .btn {
                margin-top: $px30;
                padding: $px15 $px25;
              }
            }
          }
        }

        //.image {
        //  float: right;
        //  width: 720px;
        //  font-size: 0;
        //  cursor: pointer;
        //
        //  > .img {
        //    background-color: #eee;
        //    width: 100%;
        //    padding-bottom: 58.056%;
        //    background-size: cover;
        //    background-position: 50% 50%;
        //    border: $px1 solid #eee;
        //    position: relative;
        //    left: $px1;
        //  }
        //}
      }
    }

    .pagination {
      position: absolute;
      right: $px15;
      bottom: 0;
      z-index: 1;

      > span {
        margin: 0 $px5;

        &.swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
  }

  &.skeleton {
    > .swiper-wrapper > .swiper-slide > .wrapper {
      .text > .wrapper {
        > .sub > span {
          @include skeleton;
        }

        > .title > span {
          @include skeleton;
        }

        > .desc > span {
          @include skeleton;
        }

        > .btn {
          @include skeleton;
        }
      }

      .image > .img {
        @include skeleton;
      }
    }
  }

  @media(max-width: 1199px) {
    > .swiper-wrapper {
      > .swiper-slide > .wrapper .image {
        width: 600px;
      }

      .pagination {
        right: $px20;
        bottom: 0;
      }
    }
  }

  @media(max-width: 991px) {
    > .swiper-wrapper {
      > .swiper-slide {
        padding: 0;
        border-width: 0;
        //border-bottom-width: $px1;

        > .wrapper {
          cursor: pointer;
          overflow: hidden;
          height: $px250;

          .bg {
            filter: blur(50px);
            opacity: 0.5;
          }

          > .container {
            bottom: $px5;

            > .text {
              transform: none;
              position: absolute;
              bottom: $px30;
              text-align: left;
              width: 100%;

              > .wrapper {
                position: relative;

                > .sub {
                  padding-bottom: $px1;
                  font-size: $px14;
                }

                > .title {
                  padding-bottom: $px8;
                  font-size: $px24;
                  word-break: break-word;
                  line-height: 1.35;
                }

                > .desc {
                  font-size: $px12;
                }

                > .btn {
                  display: none;
                }
              }
            }
          }

          .pagination {
            left: $px20;
            right: auto;
            bottom: $px12;

            > span {
              background: #aaa;
              border: $px1 solid rgba(255, 255, 255, 0.15);
              opacity: 0.9;
              width: $px5;
              height: $px5;
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
  }
}
</style>