<template>
  <div class="search">
    <div class="title font-md">
      <span>프로젝트 검색</span>
    </div>
    <div class="desc font-sm">검색 키워드를 입력하신 후 엔터를 눌러주세요.</div>
    <div class="form">
      <span class="img pointer" style="background-image:url(/assets/ico/modal.search.svg)" @click="search()" title="검색" alt="검색"></span>
      <input :id="`${component.name}Keyword`" type="text" class="font-sm form-control border-focus-point" :placeholder="state.defaultKeyword" ref="inputRef" @keyup="keyup"/>
    </div>
    <div class="part recommend">
      <div class="subject font-sm">
        <span>이런 키워드는 어떠신가요?</span>
      </div>
      <ul class="tight">
        <li v-for="(k, idx) in $definitions.keywords.tags" :key="idx">
          <button class="btn font-sm" @click="search(k)">
            <span># {{ k }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="part history" v-if="state.keywords.history.length">
      <div class="subject font-sm">
        <span>이전에 검색하신 키워드 목록</span>
      </div>
      <ul class="tight">
        <template v-for="(k, idx) in state.keywords.history">
          <li v-if="idx < 5" :key="idx">
            <button class="btn btn-default font-sm" @click="search(k)">
              <span># {{ k }}</span>
              <span class="remove" @click.stop="removeKeyword(idx)" alt="삭제" title="삭제">&times;</span>
            </button>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "modalSearch";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams?.keyword) {
        store.commit("closeModal", {name: component.name});
        return search(modalParams.keyword);
      }

      store.commit("tuneModal", {component, size: "sm"});
      state.keywords.history = getKeywords();
    });

    const state = reactive({
      keyword: "",
      keywords: {
        history: [],
      },
      defaultKeyword: ""
    });

    const inputRef = ref();
    const modalParams = store.getters.modalParams(component);

    const search = (keyword) => {
      if (keyword) {
        state.keyword = keyword;
      } else if (!state.keyword || !state.keyword.trim()) {
        state.keyword = state.defaultKeyword;
      }

      if (!state.keyword) {
        document.getElementById(`${component.name}Keyword`)?.focus();
        return store.commit("setSwingMessage", "검색 키워드를 입력해주세요.");
      }

      state.keyword = state.keyword.replaceAll("#", "").trim();

      if (router.app.$route.path === "/main/search" && router.app.$route.query?.searchKeyword === state.keyword) {
        // store.commit("refresh");
        return store.commit("closeModal", {name: component.name});
      }

      saveKeyword();

      router.push({
        path: "/main/search",
        query: {searchKeyword: state.keyword},
      });
    };

    const getKeywords = () => {
      const keywords = storage.get("local", "searchKeywords");

      if (keywords) {
        const keywordArr = JSON.parse(keywords);

        if (Array.isArray(keywordArr)) {
          return keywordArr;
        }
      }

      return [];
    };

    const saveKeyword = () => {
      let keywords = getKeywords();
      const maxCount = 100;

      for (let i in keywords) {
        if (keywords[i] === state.keyword) {
          keywords.splice(Number(i), 1);
          break;
        }
      }

      keywords.unshift(state.keyword);

      if (keywords.length > maxCount) {
        keywords.splice(maxCount, keywords.length - maxCount);
      }

      storage.set("local", "searchKeywords", JSON.stringify(keywords));
    };

    const removeKeyword = (idx) => {
      state.keywords.history.splice(idx, 1);
      storage.set("local", "searchKeywords", JSON.stringify(state.keywords.history));
    };

    const keyup = (e) => {
      if (e.key === "Enter") {
        search();
      } else {
        state.keyword = e.target.value;
      }
    };

    onMounted(() => {
      inputRef.value.focus();
    });

    return {component, state, inputRef, search, keyup, removeKeyword};
  }
});
</script>

<style lang="scss" scoped>
.search {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .form {
    padding-left: $px45;
    position: relative;
    border: $px1 solid $colorPoint;
    height: $px61;

    .img {
      position: absolute;
      top: 50%;
      margin-top: $px-10;
      left: $px15;
      height: $px21;
      width: $px21;
    }

    input {
      border: 0;
      height: 100%;
      padding: 0;
    }
  }

  .part {
    margin: $px30 0;
    padding-top: $px5;

    .subject {
      color: #888;

      img, span {
        vertical-align: middle;
      }

      img {
        margin-left: $px5;
        height: $px15;
      }
    }

    &.recommend {
      ul {
        padding-top: $px5;

        li {
          display: inline-block;
          margin-top: $px10;

          button {
            padding: $px5 $px15;
            border-radius: $px20;
            border: $px1 solid #ddd;

            img {
              margin-right: $px7;
              height: $px14;
            }

            &:hover {
              background: $colorBackground;
            }
          }

          &:not(:last-child) {
            margin-right: $px5;
          }
        }
      }
    }

    &.history {
      ul {
        padding-top: $px15;

        li {
          button {
            padding: $px10 $px15;
            border: 0;
            width: 100%;
            text-align: left;
            background: $colorBackground;
            margin-bottom: $px8;
            border: $px1 solid #eee;
            position: relative;

            .remove {
              display: none;
              position: absolute;
              top: 0;
              right: $px5;
              color: #888;
              font-size: $px20;
              font-family: initial;
              transition: color 0.18s;
              margin-top: $px-1;
              padding: $px5;

              &:hover {
                color: #666;
              }
            }

            &:hover {
              background: #eee;

              .remove {
                display: inline-block;
              }
            }
          }

          &:last-child {
            button {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>