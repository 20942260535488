<template>
  <input :id="id" type="tel" class="phone form-control font-sm" :class="[className || 'border-focus-point']" :placeholder="placeholder" maxlength="11" @input="change($event)" :value="value"
         @keyup.enter="typeof enter === 'function' && enter()"/>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPhone";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    value: String,
    placeholder: String,
    enter: Function,
    className: String,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const change = (e) => {
      let value = e ? e.target.value : props.value;
      value = lib.getPhoneNumberFormat(value);

      if (e) {
        e.target.value = value;
      }

      emit("update:value", value);
    };

    return {component, change};
  }
});
</script>

<style lang="scss" scoped>
.phone {
  height: $formHeight;
}
</style>