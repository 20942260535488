export default {
  get(type, key) {
    if (type === "session") {
      return sessionStorage.getItem(key);
    }

    return localStorage.getItem(key);
  },
  set(type, key, value) {
    if (type === "session") {
      return sessionStorage.setItem(key, value);
    }

    return localStorage.setItem(key, value);
  },
  remove(type, key) {
    if (type === "session") {
      return sessionStorage.removeItem(key);
    }
    
    return localStorage.removeItem(key);
  }
};