<template>
  <div :id="id" class="my-picture" :style="{width: size + 'px', height: size + 'px'}">
    <span class="img" ref="imgRef" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>
    <div class="actions" v-if="!readonly && ($route.fullPath.includes('/mypage/account'))">
      <label class="fa fa-trash pointer" title="삭제">
        <button class="hide" @click="remove()"></button>
      </label>
      <label class="fa fa-pencil pointer" title="수정">
        <input type="file" :accept="$definitions.limits.fileExtensions.imgStr" class="hide" ref="fileRef" value="" @change="setImage($event)"/>
      </label>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "componentMyPicture";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    size: Number,
    readonly: Boolean,
  },
  setup() {
    const component = new Component(() => {
    });

    const imgRef = ref();
    const fileRef = ref();

    const remove = () => {
      store.commit("confirm", {
        message: "프로필 사진을 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/members/${store.state.account.memberSeq}/account/uploads`).then(() => {
            store.commit("checkAccount");
            store.commit("setSwingMessage", "프로필 사진을 삭제했습니다.");
            store.commit("increaseKey", {key: "profile"});
          }).catch(httpError());
          store.commit("checkAccount");
        }
      });
    };

    const setImage = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      store.commit("confirm", {
        message: "프로필 사진을 업로드하시겠습니까?",
        allow() {
          const formData = new FormData();
          formData.append("memberImgFile", e.target.files[0]);
          e.target.value = "";
          http.put(`/api/members/${store.state.account.memberSeq}/account/uploads`, formData).then(() => {
            store.commit("checkAccount");
            store.commit("setSwingMessage", "프로필 사진을 업로드하였습니다.");
            store.commit("increaseKey", {key: "profile"});
          }).catch(httpError());
        },
        disallow() {
          e.target.value = "";
        }
      });
    };

    return {component, imgRef, fileRef, setImage, remove};
  }
});
</script>

<style lang="scss" scoped>
.my-picture {
  position: relative;
  height: $px70;
  width: $px70;

  .img {
    border: $px1 solid #eee;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .actions {
    position: absolute;
    bottom: 0;
    right: $px-10;

    label {
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      width: $px18;
      text-align: center;
      padding: $px3 0;
      font-size: $px12;
      border-radius: $px4;
      margin-left: $px1;
    }
  }
}
</style>