<template>
  <div class="join-step3">
    <div class="form">
      <div class="form-group font-sm">
        <div class="type">
          <button class="btn" @click="select('P')" :class="{active: state.form.memberType === 'P'}">개인회원</button>
          <button class="btn" @click="select('I')" :class="{active: state.form.memberType === 'I'}">개인사업자</button>
          <button class="btn" @click="select('C')" :class="{active: state.form.memberType === 'C'}">법인사업자</button>
        </div>
      </div>
      <template v-for="(i, idx) in computedFormItems">
        <div class="form-group" :key="idx" v-if="i.visible">
          <label :for="`${component.name + i.id}`" class="font-sm">
            <span class="color-point" v-if="i.required">* </span>
            <span>{{ i.title }}</span>
          </label>
          <div class="wrapper">
            <Number v-if="i.type === 'number'" :id="`${component.name + i.id}`" :value.sync="state.form[i.name]" :allowZero="true" :noComma="true" :maxlength="i.maxlength" :placeholder="i.placeholder"/>
            <Phone v-else-if="i.type === 'phone'" :id="`${component.name + i.id}`" :placeholder="i.placeholder" :value.sync="state.form[i.name]"/>
            <input v-else :type="i.type" :id="`${component.name + i.id}`" class="form-control border-focus-point font-sm" :placeholder="i.placeholder" @input="input(i.name, $event)" :value="state.form[i.name]" autocomplete="new-password">
            <button class="btn btn-light font-xs check" @click="checkNum(i.name, `${component.name + i.id}`, i.title)" v-if="i.check">중복 확인</button>
          </div>
        </div>
      </template>
      <div class="form-check font-sm">
        <input type="checkbox" :id="`${component.name}CheckMessage`" class="form-check-input" :value="true" v-model="state.form.checkMessage">
        <label :for="`${component.name}CheckMessage`" class="form-check-label">새로운 프로젝트 소식을 이메일과 SMS로 받겠습니다.</label>
      </div>
      <div class="form-check font-sm">
        <input type="checkbox" :id="`${component.name}CheckPrivacy`" class="form-check-input" :value="true" v-model="state.form.checkPrivacy">
        <label :for="`${component.name}CheckPrivacy`" class="form-check-label">
          <span>본인은 만 14세 이상이며, </span>
          <a href="/policy/terms" target="_blank" rel="noopener noreferrer">회원 기본약관</a>
          <span>, </span>
          <a href="/policy/rewardTerms" target="_blank" rel="noopener noreferrer">이용약관</a>
          <span>, </span>
          <a href="/policy/rewardPrivacy" target="_blank" rel="noopener noreferrer">개인정보처리방침</a>
          <span>을 확인하였고, 이 내용에 동의합니다.</span>
        </label>
      </div>
      <div class="actions">
        <button class="btn btn-point font-sm" @click="submit()">가입 완료</button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import env from "@/scripts/env";
import Phone from "@/components/Phone";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Number from "@/components/Number";
import track from "@/scripts/track";

function Component(initialize) {
  this.name = "pageJoinStep3";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number, Phone},
  setup() {
    const component = new Component(() => {
      if (!store.state.joinForm.memberType || !state.form.memberType) {
        select("P");
      }

      state.form.checkCompany = state.form.memberType === "I" || state.form.memberType === "C";

      for (let i in state.form) {
        if (i in store.state.joinForm && store.state.joinForm[i]) {
          state.form[i] = store.state.joinForm[i];
        }
      }
    });

    const state = reactive({
      form: {
        memberName: "",
        memberType: "",
        mobile: "",
        passwd: "",
        corporateName: "",
        ceoName: "",
        corporateNum: "",
        businessNum: "",
        businessTel: "",
        email: "",
        checkCompany: false,
        checkMessage: false,
        checkPrivacy: false,
      },
      confirmed: {
        memberName: false,
        mobile: false,
        passwd: false,
        corporateName: false,
        ceoName: false,
        corporateNum: false,
        businessNum: false,
        email: false,
      },
      status: {
        corporateNum: "",
        businessNum: "",
      },
    });

    const computedFormItems = computed(() => {
      const items = [{
        title: "이름",
        name: "memberName",
        id: "MemberName",
        type: "text",
        required: true,
        placeholder: "ex) 오마이",
        visible: true
      }, {
        title: "휴대전화",
        name: "mobile",
        id: "Mobile",
        type: "phone",
        required: true,
        placeholder: "ex) 01012345678",
        visible: true
      }, {
        title: "비밀번호",
        name: "passwd",
        id: "Password",
        type: "password",
        required: true,
        placeholder: "영문, 숫자, 특수문자 포함 (10~16자)",
        visible: store.state.joinForm.joinType === "homepage"
      }];

      (store.state.joinForm.snsType === "apple" && store.state.joinForm.memberId.includes("@privaterelay.appleid.com")) && items.push({
        title: "이메일",
        name: "email",
        id: "Email",
        type: "text",
        required: true,
        placeholder: "ex) omc@ohmycompany.com",
        visible: true
      });

      (state.form.memberType === "C" || state.form.memberType === "I") && items.push({
        title: "사업자명",
        name: "corporateName",
        id: "CorporateName",
        type: "text",
        required: true,
        placeholder: "ex) 오마이컴퍼니",
        visible: true
      }, {
        title: "대표자명",
        name: "ceoName",
        id: "CeoName",
        type: "text",
        required: true,
        placeholder: "ex) 오마이",
        visible: true
      }, {
        title: "사업자등록번호",
        name: "businessNum",
        id: "BusinessNum",
        type: "number",
        required: true,
        maxlength: 10,
        placeholder: "ex) 0001234567",
        check: true,
        visible: true
      });

      state.form.memberType === "C" && items.push({
        title: "법인등록번호",
        name: "corporateNum",
        id: "CorporateNum",
        type: "number",
        required: true,
        maxlength: 13,
        placeholder: "ex) 1111223333334",
        check: true,
        visible: true
      });

      return items;
    });

    const submit = () => {
      const warn = (message, id) => {
        store.commit("setSwingMessage", message);
        document.getElementById(id)?.focus();
      };

      if (!state.form.memberType) {
        return warn("유형을 선택해주세요");
      } else if (!state.form.memberName || state.form.memberName?.length <= 1) {
        return warn("이름을 두글자 이상 입력해주세요.", `${component.name}MemberName`);
      } else if (!state.form.mobile) {
        return warn("휴대전화를 입력해주세요.", `${component.name}Mobile`);
      } else if (!lib.isValidMobileNum(state.form.mobile)) {
        return warn("휴대전화 값이 올바른지 확인해주세요.", `${component.name}Mobile`);
      }

      if (store.state.joinForm.snsType === "apple" && store.state.joinForm.memberId.includes("@privaterelay.appleid.com")) {
        if (!state.form.email) {
          return warn("이메일 주소를 입력해주세요.", `${component.name}Email`);
        } else if (!lib.isValidEmail(state.form.email)) {
          return warn("이메일 주소를 올바르게 입력해주세요.", `${component.name}Email`);
        }
      }

      if (!store.state.joinForm.snsType) {
        if (!state.form.passwd) {
          return warn("비밀번호를 입력해주세요.", `${component.name}Password`);
        } else if (state.form.passwd.length < 10 || state.form.passwd.length > 16) {
          return warn("비밀번호는 10자리 이상 16자리 이하로 입력해주세요.", `${component.name}Password`);
        }
      }

      if (state.form.checkCompany) {
        state.form.businessNum = state.form.businessNum?.trim();

        if (!state.form.corporateName) {
          return warn("사업자명을 입력해주세요.", `${component.name}CorporateName`);
        } else if (!state.form.ceoName || state.form.ceoName.length <= 1) {
          return warn("대표자명을 두글자 이상 입력해주세요.", `${component.name}CeoName`);
        } else if (!state.form.businessNum) {
          return warn("사업자등록번호를 입력해주세요.", `${component.name}BusinessNum`);
        } else if (state.form.businessNum.length !== 10 || !lib.isNumeric(state.form.businessNum)) {
          return warn("사업자등록번호 값이 올바른지 확인해주세요.", `${component.name}BusinessNum`);
        } else if (!state.status.businessNum) {
          return warn("사업자등록번호 중복 확인을 클릭해주세요.", `${component.name}BusinessNum`);
        } else if (state.status.businessNum === "duplicated") {
          return warn("이미 등록된 사업자등록번호입니다.", `${component.name}BusinessNum`);
        } else if (state.form.businessTel && !lib.isValidPhoneNum(state.form.businessTel)) {
          return warn("대표 전화번호 값이 올바른지 확인해주세요.", `${component.name}BusinessTel`);
        }

        if (state.form.memberType === "C") {
          state.form.corporateNum = state.form.corporateNum?.trim();

          if (!state.form.corporateNum) {
            return warn("법인등록번호를 입력해주세요.", `${component.name}CorporateNum`);
          } else if (state.form.corporateNum.length !== 13 || !lib.isNumeric(state.form.corporateNum)) {
            return warn("법인등록번호 값이 올바른지 확인해주세요.", `${component.name}CorporateNum`);
          } else if (!state.status.corporateNum) {
            return warn("법인등록번호 중복 확인을 클릭해주세요.", `${component.name}CorporateNum`);
          } else if (state.status.corporateNum === "duplicated") {
            return warn("이미 등록된 법인등록번호입니다.", `${component.name}CorporateNum`);
          }
        }
      }

      if (!state.form.checkPrivacy) {
        return warn("약관에 및 개인정보처리방침에 동의하셔야 회원가입이 가능합니다.", `${component.name}CheckPrivacy`);
      }

      const obj = {};

      for (let i in state.form) {
        if (i in store.state.joinForm) {
          let value = state.form[i];

          if (["mobile", "businessNum", "businessTel"].includes(i)) {
            value = value.replaceAll("-", "");
          }

          obj[i] = value;
        } else if (i === "checkMessage") {
          const value = state.form[i] ? "Y" : "N";
          obj.emailRecv = value;
          obj.smsRecv = value;
        } else if (i === "checkCompany") {
          if (obj.memberType !== "C") {
            obj.memberType = state.form[i] ? "I" : "P";
          }
        }
      }

      store.commit("setJoinForm", obj);

      const urlPath = "/api/member/join/process";

      http.post(urlPath, store.state.joinForm).then(() => {
        store.commit("refresh", {
          callback() {
            store.dispatch("redirect", () => {
              store.commit("openModal", {name: "JoinComplete"});
            });

            track.post({
              name: "memberJoin",
              category: "회원",
              topic: "회원가입 완료",
              urlPath: urlPath,
              type: "api",
              httpMethod: "POST",
              memo: store.state.joinForm.joinType === "homepage" ? undefined : store.state.joinForm.joinType
            });
          }
        });
      });
    };

    const checkNum = (key, id, title) => {
      const value = state.form[key];

      if (!value) {
        document.getElementById(id)?.focus();
        return store.commit("setSwingMessage", title + "를 입력해주세요.");
      }

      const set = val => state.status[key] = val;
      const args = {};
      args[key] = value;

      http.get("/api/member/valid-business-corp-num", args).then(({data}) => {
        set("pass");
        store.commit("setSwingMessage", data.message);
      }).catch(httpError((err) => {
        err?.response?.status === 409 && set("duplicated");
        document.getElementById(id)?.focus();
      }));
    };

    const select = (type) => {
      focus();
      state.form.checkCompany = type === "I" || type === "C";

      state.form.memberType = type;
    };

    const input = (key, e) => {
      state.form[key] = e.target.value;
    };

    const focus = () => {
      env.device === "desktop" &&
      nextTick(() => {
        if (store.state.joinForm.memberName) {
          document.getElementById(`${component.name}Mobile`)?.focus();
        } else {
          document.getElementById(`${component.name}MemberName`).focus();
        }
      });
    };

    watch(() => state.form.corporateNum, () => {
      state.status.corporateNum = "";
    });

    watch(() => state.form.businessNum, () => {
      state.status.businessNum = "";
    });

    onMounted(() => {
      focus();
      lib.setBodyScroll();
    });

    return {component, state, computedFormItems, checkNum, submit, select, input};
  }
});

</script>

<style lang="scss" scoped>
@import "../../styles/page.account";

.join-step3 {
  .form {
    .form-group {
      .type {
        .btn {
          width: 33.3333%;
          border-radius: 0;
          font-size: $px14;
          height: $formHeight;
          border: $px1 solid $colorBorder;

          &:first-child {
            border-radius: $px4 0 0 $px4;
          }

          &:last-child {
            border-radius: 0 $px4 $px4 0;
          }

          &:not(:last-child) {
            border-right: none;
          }

          &.active {
            background-color: #fff;
            border: $px1 solid $colorPoint;
            color: $colorPoint;
          }
        }
      }

      .wrapper {
        position: relative;

        .btn {
          position: absolute;

          &.check {
            border: 0;
            border-left: $px1 solid #eee;
            top: $px1;
            right: $px1;
            height: calc(100% - 2px);
            border-radius: 0 $px4 $px4 0;
          }
        }
      }
    }

    .actions {
      padding-top: $px20;
    }
  }
}
</style>