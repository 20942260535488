<template>
  <div class="review-card" :class="{skeleton: skeleton}">
    <template v-if="blindFlag === 'Y'">
      <div class="blocked">
        <div>
          <span v-if="!state.showBlockedContent">규정을 위반하여 관리자에 의해 차단된 댓글입니다.</span>
          <span v-else>{{ content }}</span>
          <a class="color-anchor pointer pl-2" v-if="$store.state.account.memberAuth.includes('ADMIN') && !state.showBlockedContent" @click="() => state.showBlockedContent = true">내용 보기</a>
        </div>
        <div class="actions">
          <div class="act" :class="{'dropdown-menu': $lib.isMobile()}" aria-labelledby="dropdownMenuButton">
            <button class="btn btn-default" @click="remove()" title="삭제하기" v-if="memberSeq === $store.state.account.memberSeq"><i class="fa fa-trash"></i> 삭제</button>
            <button class="btn btn-default" title="후기 복구" @click="restore()" v-if="$store.state.account.memberAuth.includes('ADMIN')"><i class="fa fa-repeat"></i> 복구</button>
          </div>
          <button class="dot btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  v-if="$store.state.account.memberSeq === memberSeq || ((builderSeq && $store.state.account.memberSeq === builderSeq) || $store.state.account.memberAuth.includes('ADMIN'))"></button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="top">
        <div class="info">
          <div class="left">
            <Anchor :href="`/channel/${memberSeq}?tabs=projects`" v-if="memberName && hideInvestorNameYn === 'N'">
              <MemberPicture :memberSeq="memberSeq"/>
            </Anchor>
            <MemberPicture v-else/>
            <div class="member">
              <Anchor :href="`/channel/${memberSeq}?tabs=projects`" v-if="memberName && hideInvestorNameYn === 'N'">
                <span class="name">{{ memberName }}</span>
              </Anchor>
              <span class="name" v-else>키다리 후원자</span>
              <span class="email" v-if="email">({{ email }})</span>
              <span class="date">{{ updateDate || createDate }}</span>
              <span class="title" v-if="projectSeq">
            <Anchor :href="`/reward/${projectSeq}`">{{ projectName }}</Anchor>
          </span>
              <span class="second" v-if="rewardTitle">{{ rewardTitle }}</span>
            </div>
          </div>
          <div class="act" :class="{'dropdown-menu': $lib.isMobile()}" aria-labelledby="dropdownMenuButton">
            <template v-if="$store.state.account.memberSeq === memberSeq">
              <button class="btn btn-default" @click="edit()" title="수정하기"><i class="fa fa-pencil"></i> 수정</button>
              <button class="btn btn-default" @click="remove()" title="삭제하기"><i class="fa fa-trash"></i> 삭제</button>
            </template>
            <button class="btn btn-default" title="댓글달기" @click="openReply()" v-if="$store.state.account.memberSeq === builderSeq || $store.state.account.memberAuth.includes('ADMIN')">
              <i class="fa fa-comment-o"></i> 댓글
            </button>
            <template v-if="$store.state.account.memberAuth.includes('ADMIN')">
              <button class="btn btn-default" title="후기 복구" @click="restore()" v-if="blindFlag === 'Y'">
                <i class="fa fa-repeat"></i> 복구
              </button>
              <button class="btn btn-default" title="후기 가리기" @click="blind()" v-else>
                <i class="fa fa-ban"></i> 차단
              </button>
            </template>
          </div>
          <button class="dot btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  v-if="$store.state.account.memberSeq === memberSeq || ((builderSeq && $store.state.account.memberSeq === builderSeq) || $store.state.account.memberAuth.includes('ADMIN'))"></button>
        </div>
      </div>
      <div class="content">
        <div class="wrapper" :class="{ limit: photos && photos.length > 0}">
          <div class="tags">
            <div class="tag" v-for="(t, idx) in selectedTags.split(',')" :key="idx">
              <span>{{ getSelectedTags(t) }}</span>
            </div>
          </div>
          <div class="text">{{ content }}</div>
        </div>
        <div class="photos" v-if="photos && photos.length" :class="{row: $lib.isMobile()}">
          <div class="wrapper" @click="openPhotoSlider()" :class="{'col-6': photos.length > 1 && $lib.isMobile(), 'col-12': photos.length === 1}">
            <span class="img" :style="{backgroundImage: `url('${computedImageFileUrl(0)}')`}"></span>
            <span class="count" v-if="photos.length > 1 && !$lib.isMobile()">{{ photos.length }}</span>
          </div>
          <div class="wrapper col-6" @click="openPhotoSlider(1)" v-if="photos.length > 1 && $lib.isMobile()">
            <span class="img" :style="{backgroundImage: `url('${computedImageFileUrl(1)}')`}"></span>
            <span class="count" v-if="photos.length > 2">{{ `+ ${photos.length - 2}` }}</span>
          </div>
        </div>
      </div>
      <template v-if="!skeleton && replies && replies.length">
        <div class="expand" v-if="page === 'admin'">
        <span @click="setExpansion()">
          <i class="fa" :class="[state.expandedReplies ? 'fa-angle-up': 'fa-angle-down']"></i>댓글 {{ replies.length }}개
        </span>
        </div>
        <template v-if="(page === 'admin' && state.expandedReplies) || !page">
          <div class="reply" v-for="(r, idx) in replies" :key="idx">
            <div class="info">
              <div class="left">
                <template v-if="r.memberName !== '오마이컴퍼니'">
                  <Anchor :href="`/channel/${r.createId}?tabs=projects`">
                    <MemberPicture :memberSeq="`${r.createId}`"/>
                  </Anchor>
                  <div class="member">
                    <Anchor :href="`/channel/${r.createId}?tabs=projects`">
                      <span class="name">{{ r.memberName }}</span>
                    </Anchor>
                    <span class="date">{{ r.createDate }}</span>
                  </div>
                </template>
                <template v-else>
                  <span class="img" :style="{backgroundImage: 'url(/assets/img/common.logo.o.svg)'}"></span>
                  <div class="member">
                    <span class="name">{{ r.memberName }}</span>
                    <span class="date">{{ r.createDate }}</span>
                  </div>
                </template>
              </div>
              <div class="dropdown" v-if="Number($store.state.account.memberSeq) === r.createId">
                <div class="act" aria-labelledby="dropdownMenuButton" :class="{'dropdown-menu': $lib.isMobile()}">
                  <button class="btn btn-default" @click="editReply(r.id, idx)" title="수정하기"><i class="fa fa-pencil"></i> 수정</button>
                  <button class="btn btn-default" @click="removeReply(r.id)" title="삭제하기"><i class="fa fa-trash"></i> 삭제</button>
                </div>
                <button class="dot btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="skeleton"></button>
              </div>
            </div>
            <div class="content">
              <span>{{ r.content }}</span>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";
import definitions from "@/scripts/definitions";
import NoData from "@/components/NoData.vue";

function Component(initialize) {
  this.name = "componentReviewCard";
  this.initialize = initialize;
}

export default defineComponent({
  props: {
    blindFlag: String,
    builderSeq: String,
    content: String,
    createDate: String,
    delFlag: String,
    email: String,
    hideInvestorNameYn: String,
    memberSeq: String,
    memberName: String,
    page: String,
    photos: Array,
    projectName: String,
    projectSeq: Number,
    replies: Array,
    reviewId: Number,
    rewardTitle: String,
    selectedTags: String,
    skeleton: Boolean,
    updateDate: String,
  },
  components: {NoData, Anchor, MemberPicture},
  mixins: [mixin],
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      expandedReplies: false,
      showBlockedContent: false,
    });

    const getSelectedTags = (tag) => {
      if (definitions.keywords.reviews?.some(k => k.name === tag)) {
        return definitions.keywords.reviews[definitions.keywords.reviews.findIndex(k => k.name === tag)]?.title;
      } else {
        return "WAIT";
      }
    };

    const computedImageFileUrl = computed(() => {
      if (!props.photos) {
        return;
      }
      return (i) => {
        return store.getters.thumbnailUrl(`${props.photos[i]?.filePath + props.photos[i]?.fileSaveName}`);
      };
    });

    const openPhotoSlider = (currentNum) => {
      store.commit("openModal", {
        name: "ReviewPhotoSlider",
        params: {
          photos: props.photos,
          currentNum,
        }
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "해당 후기를 삭제하시겠습니까?",
        subMessage: "삭제 시 복구 및 재작성이 불가능합니다.",
        allow() {
          http.delete(`/api/project/review/${props.reviewId}`);
          store.commit("refresh");
        }
      });
    };

    const edit = () => {
      store.commit("openModal", {
        name: "Review",
        params: {
          edit: true,
          id: props.reviewId,
        }
      });
    };

    const submit = (params) => {
      if (params.edit) {
        http.put(`/api/maker/project/review/reply/${params.replyId}`, {content: params.content}).then(() => {
          store.commit("refresh");
          store.commit("setSwingMessage", "댓글을 수정하였습니다");
        });
        return;
      }

      const args = {
        content: params.content,
        reviewId: params.reviewId,
      };

      http.post(`/api/maker/project/review/reply`, args).then(() => {
        store.commit("refresh");
        store.commit("setSwingMessage", "댓글을 달았습니다");
      });
    };

    const openReply = () => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "댓글달기",
          reviewId: props.reviewId,
        },
        callback: `${component.name}.submit`
      });
    };

    const editReply = (id, idx) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          content: props.replies[idx].content,
          replyId: id,
          edit: true,
        },
        callback: `${component.name}.submit`
      });
    };

    const removeReply = (id) => {
      store.commit("confirm", {
        message: "댓글을 삭제하시겠습니까?",
        allowTxt: "삭제",
        allow() {
          http.delete(`/api/maker/project/review/reply/${id}`).then(() => {
            store.commit("refresh");
            store.commit("setSwingMessage", "댓글이 삭제되었습니다.");
          });
        }
      });
    };

    const blind = () => {
      store.commit("confirm", {
        message: "후기를 비공개 처리하시겠습니까?",
        subMessage: "'규정을 위반하여 관리자에 의해 차단된 댓글입니다.' 라는 문구가 출력됩니다.",
        allow() {
          http.put(`/api/admin/review/${props.reviewId}/blind`, {blindFlag: "Y"}).then(() => {
            store.commit("setSwingMessage", "후기가 비공개 처리되었습니다.");
            props.blindFlag = "Y";
          });
        }
      });
    };

    const restore = () => {
      http.put(`/api/admin/review/${props.reviewId}/blind`, {blindFlag: "N"}).then(() => {
        store.commit("setSwingMessage", "후기가 복구되었습니다.");
        props.blindFlag = "N";
      });
    };

    const setExpansion = () => {
      return !state.expandedReplies ? state.expandedReplies = true : state.expandedReplies = false;
    };

    return {component, state, computedImageFileUrl, getSelectedTags, openPhotoSlider, remove, edit, submit, openReply, editReply, removeReply, setExpansion, blind, restore};
  }
});
</script>
<style lang="scss" scoped>
.review-card {
  font-size: $px14;
  padding-bottom: $px35;

  > .blocked {
    display: flex;
    justify-content: space-between;

    .actions {
      flex-shrink: 0;
    }
  }

  > .top {
    position: relative;
    padding-bottom: $px10;
    margin-bottom: $px10;

    .info {
      .left {
        .member {
          span {
            &.name {
              padding-right: $px5;
            }

            &.email {
              color: #aaa;
              padding-right: $px5;
            }

            &.date {
              color: #aaa;
            }

            &.title {
              display: block;
              margin-top: $px5;
            }

            &.second {
              display: block;
              font-weight: 500;
              margin-top: $px5;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  > .content {
    display: flex;
    justify-content: space-between;

    .wrapper {
      .tags {
        margin-left: $px-5;

        .tag {
          border: $px1 solid $colorPoint;
          border-radius: $px100;
          color: $colorPoint;
          display: inline-block;
          font-size: $px10;
          margin: 0 $px3 $px8 $px3;
          padding: $px4 $px8;
        }
      }

      .text {
        white-space: pre-line;
        padding-top: $px5;
      }

      &.limit {
        width: 70%;
      }
    }

    .photos {
      > .wrapper {
        background: $colorBackground;
        border: $px1 solid #eee;
        cursor: pointer;
        display: inline-block;
        width: $px120;
        height: $px120;
        position: relative;

        > .img {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        > .count {
          background-color: rgba(0, 0, 0, 0.7);
          color: #fff;
          text-align: center;
          line-height: $px25;
          width: $px25;
          height: $px25;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &.row {
        margin: 0;

        > .wrapper {
          width: auto;
          padding-top: 50%;

          > .count {
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            line-height: $px164;
            font-size: $px24;
          }

          &.col-12 {
            padding-top: 100%;
          }
        }
      }
    }
  }

  > .expand {
    margin-top: $px10;

    > span {
      cursor: pointer;
      color: #aaa;
      font-size: $px12;

      > i {
        font-size: $px14;
        padding-right: $px10;
        vertical-align: middle;
      }
    }
  }

  > .reply {
    margin-top: $px30;
    position: relative;
    padding-left: $px40;

    .info .left .member {
      span {
        &.date {
          display: block;
          margin-top: $px5;
        }
      }
    }

    .content {
      margin-top: $px10;
      white-space: pre-line;
    }

    &:before {
      content: " ";
      background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
      background-size: 100% 100%;
      width: $px20;
      height: $px20;
      position: absolute;
      top: $px3;
      left: $px11;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;

    .left {
      align-items: center;
      display: flex;
      overflow: hidden;
      white-space: nowrap;

      > .img, > a .img {
        flex-shrink: 0;
      }

      .member {
        margin-left: $px10;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: $px11;
      }
    }
  }

  .act {
    > button {
      font-size: $px12;
      padding: $px4 $px8;
      position: relative;

      > i {
        margin-right: $px2;
      }

      &:not(:last-child) {
        margin-right: $px8;
      }
    }
  }

  .dot {
    display: none;
    cursor: pointer;
  }

  &:not(:first-child) {
    border-top: $px1 solid #eee;
    padding-top: $px30;
  }

  &.skeleton {
    .top {
      .info .left {
        .member > span, .member a > span, .img {
          @include skeleton;
        }
      }
    }

    .content {
      .wrapper {
        .tags .tag, > span, .text {
          @include skeleton;
        }
      }

      .photos > .wrapper, .photos > .wrapper > span {
        @include skeleton;
      }
    }

    .info button {
      @include skeleton;
    }
  }

  @media (max-width: 767px) {
    .act {
      border-radius: $px4;
      border: none;
      box-shadow: 0 0 1.125rem 0 rgb(136 152 170 / 15%);
      background-color: #fff;
      position: absolute;
      right: 0;
      white-space: nowrap;
      padding: $px10;

      button {
        background-color: #fff;
        border: none;
        font-size: $px11;
        display: block;
        width: 100%;
        margin-left: 0;

        > i {
          margin-right: $px5;
        }

        &:not(:last-child) {
          margin-bottom: $px5;
        }
      }

      &.dropdown-menu {
        top: $px30 !important;
        left: initial !important;
        transform: none !important;
        min-width: initial;
      }
    }

    .dot {
      background-image: url("/assets/ico/component.reviewcard.dot.vertical.svg");
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      display: block;
      padding: $px10;
    }

    .content {
      flex-direction: column;

      .wrapper {
        &.limit {
          width: 100%;
        }
      }

      .photos {
        padding-top: $px15;
      }
    }
  }
}
</style>
