<template>
  <div class="profile" :class="{'skeleton': !state.maker.loaded}">
    <div class="title">
      <span>진행자 소개</span>
    </div>
    <div class="basic">
      <Anchor :href="`/channel/${builderSeq}?tabs=projects`" class="picture">
        <MemberPicture :memberSeq="builderSeq" size="50" :skeleton="!state.maker.loaded"/>
      </Anchor>
      <div class="right-side">
        <div class="name pointer font-sm">
          <Anchor :href="`/channel/${builderSeq}?tabs=projects`" :title="state.maker.info.memberName">{{ state.maker.info.memberName }}</Anchor>
        </div>
        <div class="contacts">
          <a class="homepage img" :href="state.maker.info.homepage.startsWith('https://') ? state.maker.info.homepage : 'http://' + state.maker.info.homepage.replace('http://', '')" target="_blank" rel="noopener noreferrer"
             title="홈페이지" v-if="state.maker.info.homepage"></a>
          <template v-if="state.maker.info.email">
            <a class="email img" title="메일 주소 복사" @click="copyEmailAddress(state.maker.info.email)" v-if="$env.device === 'desktop'"></a>
            <a class="email img" :href="`mailto:${state.maker.info.email}`" v-else></a>
          </template>
          <a class="facebook img" :href="state.maker.info.facebook.startsWith('https://') ? state.maker.info.facebook : 'http://' + state.maker.info.facebook.replace('http://', '')" target="_blank" rel="noopener noreferrer"
             title="페이스북" v-if="state.maker.info.facebook"
          ></a>
          <a class="blog img" :href="state.maker.info.blog.startsWith('https://') ? state.maker.info.blog : 'http://' + state.maker.info.blog.replace('http://', '')" target="_blank" rel="noopener noreferrer"
             title="블로그" v-if="state.maker.info.blog"
          ></a>
          <a class="insta img" :href="state.maker.info.insta.startsWith('https://') ? state.maker.info.insta : 'http://' + state.maker.info.insta.replace('http://', '')" target="_blank" rel="noopener noreferrer"
             title="인스타그램" v-if="state.maker.info.insta"
          ></a>
        </div>
      </div>
    </div>
    <div class="intro">
      <span>{{ state.maker.info.introduction ? state.maker.info.introduction.trim() : "등록된 소개 내용이 없습니다." }}</span>
    </div>
    <div class="actions" v-if="builderSeq !== $store.state.account.memberSeq">
      <button class="font-xs btn" :class="builderSeq && $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq) ? 'following' : 'follow'"
              :disabled="!$store.state.follows.loaded || !state.maker.loaded"
              @click="toggleFollow(builderSeq)">
                      <span :class="{'text-muted': $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq)}">
                        {{ $store.state.follows.memberSequences.find(m => m.makerSeq === builderSeq) ? "팔로잉" : "+ 팔로우" }}
                      </span>
        <span v-if="state.maker.info.followers">{{ state.maker.info.followers }}</span>
      </button>
      <button class="font-xs btn btn-default message" @click="openMessageModal()">문의하기</button>
    </div>
    <div class="actions" v-else-if="state.maker.loaded">
      <Anchor :href="`/mypage/account/channel`" class="font-xs btn btn-default edit">채널 관리하기</Anchor>
    </div>
    <ReviewTagBar
        v-if="projectType === 'reward' && builderSeq"
        classType="maker"
        :skeleton="!state.maker.loaded"
        :builderSeq="builderSeq"
        :url="`/api/project/maker/${builderSeq}/review/tags`"
    />
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive, watch} from "@vue/composition-api";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture";
import definitions from "@/scripts/definitions";
import ReviewTagBar from "@/components/ReviewTagBar";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "componentMakerInfo";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ReviewTagBar, MemberPicture, Anchor},
  mixins: [mixin],
  props: {
    builderSeq: String,
    projectType: String,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      maker: {
        loaded: false,
        info: {
          blog: "",
          cheers: 0,
          email: "",
          facebook: "",
          followers: 0,
          followings: 0,
          homepage: "",
          insta: "",
          introduction: "",
          memberName: "",
          memberProfileUrl: "",
          news: 0,
          projects: 0,
          reviews: 0,
          supportedOpenFlag: "",
          supports: 0,
        },
      }
    });

    const load = () => {
      if (!props.builderSeq) {
        return;
      }

      store.commit("setFollows");
      state.maker.loaded = false;

      http.get(`/api/channel/${props.builderSeq}`).then(({data}) => {
        state.maker.loaded = true;
        state.maker.info = data.body;
      });
    };

    const copyEmailAddress = (mailAddress) => {
      lib.copyToClipboard(mailAddress.trim());
      store.commit("setSwingMessage", "이메일 주소를 클립보드에 복사하였습니다.");
    };

    const openMessageModal = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      } else if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: props.builderSeq,
            name: state.maker.info.memberName,
          }]
        }
      });
    };

    const toggleFollow = (seq) => {
      const args = {makerSeq: seq};

      if (!store.state.account.loggedIn) {
        redirectCallback.setAndLogin("toggleMemberFollow", args);
        return;
      }

      redirectCallback.run("toggleMemberFollow", args);
      setFollowerCount();
    };

    const setFollowerCount = () => {
      if (store.state.follows.memberSequences.find(m => m.makerSeq === props.builderSeq)) {
        state.maker.info.followers -= 1;
      } else {
        state.maker.info.followers += 1;
      }
    };

    watch(() => props.builderSeq, () => {
      load();
    });

    return {component, state, copyEmailAddress, openMessageModal, toggleFollow};
  }

});
</script>
<style lang="scss" scoped>

.profile {
  position: relative;
  padding: $px20;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: $px4;
  background: #fff;
  border: $px1 solid #eee;

  .title {
    font-size: $px14;
    font-weight: 500;
    margin-bottom: $px20;
  }

  .basic {
    display: flex;

    .picture {
      > .img {
        background-position: center;
        background-size: auto 100%;
      }
    }

    .right-side {
      padding-top: $px2;
      padding-left: $px15;

      .name {
        display: inline-block;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .contacts {
        a {
          cursor: pointer;
          height: $px20;
          width: $px20;
          vertical-align: middle;
          background-color: $colorBackground;
          background-position: center;
          border: $px1 solid #eee;
          border-radius: 50%;
          background-size: $px21;

          &:not(:first-child) {
            margin-left: $px10;
          }

          &.homepage {
            background-size: $px15;
            background-image: url(/assets/ico/page.project.detail.profile.sns.homepage.svg)
          }

          &.email {
            background-size: $px13;
            background-image: url(/assets/ico/page.project.detail.profile.sns.email.svg)
          }

          &.facebook {
            background-size: $px15;
            background-image: url(/assets/ico/page.project.detail.profile.sns.facebook.svg)
          }

          &.blog {
            background-size: $px13;
            background-image: url(/assets/ico/page.project.detail.profile.sns.blog.svg);
          }

          &.insta {
            background-size: $px14;
            background-image: url(/assets/ico/page.project.detail.profile.sns.instagram.svg)
          }
        }
      }
    }
  }

  .intro {
    display: -webkit-box;
    margin: $px20 0;
    max-height: $px200;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    line-height: 1.2;

    > span {
      white-space: break-spaces;
      font-size: $px13;
      color: $colorSecondary;
    }
  }

  .actions {
    .btn {
      border: $px1 solid #eee;
      background: #fff;
      color: #828282;
      width: calc(50% - $px10);
      margin: 0;
      padding: $px10 0;

      &.follow {
        background-color: $colorPurple;
        color: #fff;

        &:hover {
          background-color: $colorPurpleActive;
        }
      }

      &.following {
        background-color: #efefef;
        color: $colorSecondary;

        &:hover {
          background-color: $colorBackground;
        }
      }

      &.message {
        margin-left: $px20;
      }

      &.edit {
        width: 100%;
        background-color: $colorPurple;
        color: #fff;

        &:hover {
          background-color: $colorPurpleActive;
        }
      }

      &:hover {
        background: $colorBackground;
      }
    }
  }

  &.skeleton {
    .title {
      > span {
        @include skeleton;
      }
    }

    .basic {
      > a > span, .right-side .name > a, .right-side .contacts > a {
        @include skeleton;
      }
    }

    .intro > span, .actions > .btn, .actions > .btn span {
      @include skeleton;

      &.follow, &.following, &.message {
        @include skeleton;
      }
    }
  }

  @media (max-width: 1200px) {
    .basic {
      .right-side {
        .contacts {
          display: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .basic {
      .right-side {
        .contacts {
          display: inherit;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .basic {
      > a {
        span.img {
          width: $px40 !important;
          height: $px40 !important;
        }
      }
    }
  }
}
</style>