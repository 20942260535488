<template>
  <div class="error">
    <div class="container">
      <div class="wrapper">
        <div class="inner">
          <div class="image">
            <img src="/assets/img/component.contact.question.gif" alt="물음표"/>
          </div>
          <div class="form-group">
            <label>{{ computedStatus.title }}</label>
            <small class="form-text text-muted">{{ computedStatus.desc }}</small>
            <a :href="`tel:${computedStatus.phone}`" v-if="computedStatus.phone">
              <i class="fa fa-phone"></i>
              <span>{{ computedStatus.phone }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageEtcError";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "오류");
    });

    const computedStatus = computed(() => {
      const status = {
        title: "오류 발생",
        desc: "아래 번호로 문의하시면 안내해드리겠습니다.",
        phone: "02-388-2556"
      };

      switch (router.app.$route.params.code) {
        case "401":
          status.title = "권한 없음";
          status.desc = "이 페이지에 접근할 권한이 없습니다.";
          status.phone = "";
          break;

        case "404":
          status.title = "찾을 수 없음";
          status.desc = "이 페이지(혹은 파일)를 찾을 수 없습니다.";
          status.phone = "";
          break;
      }

      return status;
    });

    return {component, computedStatus};
  }
});
</script>

<style lang="scss" scoped>
.error {
  background: $colorBackground;
  padding-top: $px50;
  padding-bottom: $px50;
  min-height: 100vh;

  > .container {
    max-width: 576px;

    > .wrapper {
      background: #fff;
      border: 1px solid $colorBorder;

      > .inner {
        max-width: 300px;
        margin: 0 auto;
        padding: 50px 0 65px 0;
        text-align: center;
      }
    }

    .image {
      height: 160px;

      img {
        height: 100%;
      }
    }

    .form-group {
      > label {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      > small {
        margin-bottom: 20px;
      }

      > a {
        background: $colorBackground;
        border: 1px solid $colorBorder;
        padding: 15px;
        height: 53px;
        display: block;
        text-align: center;
        text-decoration: none;

        i {
          margin-right: 9px;
          transform: rotate(5deg);
        }
      }
    }
  }
}
</style>