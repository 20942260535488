<template>
  <div class="empty">
    <RouterView/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent} from "@vue/composition-api";

function Component(initialize) {
  this.name = "layoutEmpty";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.empty {
}
</style>