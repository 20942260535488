const elements = {};

const observer = MutationObserver && new MutationObserver(function (entries) {
  for (const entry of entries) {
    typeof elements[entry.target] === "function"
    && elements[entry.target](entry.target);
  }
});

const resizeObserver = ResizeObserver && new ResizeObserver((entries) => {
  for (const entry of entries) {
    typeof elements[entry.target] === "function"
    && elements[entry.target](entry.target);
  }
});

export const observe = (element, callback) => {
  if (typeof observer?.observe !== "function" || typeof resizeObserver?.observe !== "function") {
    return;
  }

  elements[element] = callback;

  observer.observe(element, {
    attributes: true,
    attributeFilter: ["class"],
    childList: false,
    characterData: false
  });

  resizeObserver.observe(element);
};