<template>
  <input :id="id" :type="type ? type : 'tel'" pattern="[0-9]*" inputmode="numeric" :autocomplete="type === 'password' ? 'new-password' : autocomplete" class="number form-control font-sm" :class="[className || 'border-focus-point']"
         :placeholder="placeholder"
         @input="input($event)" @keypress="keypress($event)" :maxlength="maxlength" :value="computedNumber" @keyup="typeof keyup === 'function' && keyup($event)" @keyup.enter="typeof enter === 'function' && enter()"
         :readonly="readonly"
  />
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentNumber";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    value: [String, Number],
    placeholder: String,
    type: String,
    autocomplete: String,
    keyup: Function,
    enter: Function,
    change: Function,
    allowZero: Boolean,
    noComma: Boolean,
    readonly: Boolean,
    maxlength: Number,
    maxvalue: Number,
    className: String,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const computedNumber = computed(() => {
      let value = "";

      if (props.value !== undefined && props.value !== null && props.value !== "" && (props.allowZero || props.value !== 0)) {
        value = props.value.toString().replace(/\D/g, "");

        if (props.maxvalue && Number(value) > props.maxvalue) {
          value = props.maxvalue.toString();
        }

        if (!props.allowZero) {
          value = Number(value).toString();
        }

        if (!props.noComma) {
          value = lib.getNumberFormat(value);
        }
      } else {
        value = null;
      }

      return value;
    });

    const input = (e) => {
      let value = e.target.value;

      if (value) {
        value = value.toString().replace(/\D/g, "");

        if (!props.noComma) {
          value = value.replace(/\D/g, "").replaceAll(",", "");
        }

        if (props.maxvalue && Number(value) > props.maxvalue) {
          value = props.maxvalue.toString();
          e.target.value = lib.getNumberFormat(value);
        }

        if (!props.allowZero) {
          value = Number(value);
        }
      }

      emit("update:value", value);
      typeof props.change === "function" && props.change();
    };

    const keypress = (e) => {
      if (e.which < 48 || e.which > 57) {
        e.preventDefault();
      }
    };

    return {component, computedNumber, input, keypress};
  }
});
</script>