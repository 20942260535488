<template>
  <Board category="fundingtip"/>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Board from "@/components/Board";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageCommunityFundingTip";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Board},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "펀딩 소식");
    });

    return {component};
  }
});
</script>