<template>
  <div class="sticky-message shadow alert alert-dismissible" :class="$store.state.sticky.classes" :data-show="$store.state.sticky.show ? 'Y' : 'N'" :data-fade="$store.state.sticky.fade ? 'Y' : 'N'" :style="state.style"
       ref="stickyMessageRef">
    <div class="message">{{ $store.state.sticky.message }}</div>
    <button type="button" class="close" aria-label="닫기" title="닫기" @click="$store.commit('closeStickyMessage')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentStickyMessage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      style: {
        left: "0",
        marginBottom: "0",
        transform: "none",
      }
    });

    const stickyMessageRef = ref();

    const draw = () => {
      state.style.left = "0";
      state.style.marginBottom = "0";
      state.style.transform = "none";

      nextTick(() => {
        const bodyCenter = document.body.offsetWidth / 2;
        const elemWidth = stickyMessageRef.value.offsetWidth;
        const elemHeight = stickyMessageRef.value.offsetHeight;
        const elemCenter = elemWidth / 2;

        state.style.left = (bodyCenter - elemCenter) + "px";
        state.style.marginBottom = (elemHeight * -1) + "px";
        state.style.transform = "none";
      });
    };

    watch(() => store.state.sticky.message, () => {
      draw();
    });

    onMounted(() => {
      draw();
    });

    return {component, state, stickyMessageRef};
  }
});
</script>

<style lang="scss" scoped>
.sticky-message {
  position: fixed;
  width: fit-content;
  max-width: 767px;
  bottom: 100%;
  border: $px1 solid #eee;
  border-radius: $px4;
  padding: $px20 $px50 $px20 $px20;
  transition: bottom 0.37s, opacity 0.37s, margin-bottom 0.37s;
  opacity: 0;
  z-index: 9999;

  .message {
    white-space: pre-line;
    font-size: $px15;
  }

  > .close {
    top: 50%;
    font-size: $px19;
    transform: translateY(-50%);
  }

  &[data-show=Y] {
    bottom: calc(100% - 100px);
    opacity: 1;
  }

  &[data-show=N] {
    margin-bottom: 0 !important;
  }

  &[data-fade=Y] {
    bottom: calc(100% - 65px);
    opacity: 0;
  }

  @media (max-width: 991px) {
    max-width: calc(100% - 30px);

    .message {
      font-size: $px13;
    }
  }
}
</style>