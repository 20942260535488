<template v-if="props">
  <div class="reservation-btn" :class="{ skeleton, applied: project.applyFlag === '1' && (page === 'detail' || !clickable)}" :data-page="page">
    <button class="share btn btn-block btn-default" :class="{on: project.applyFlag === '1'}" title="공유하기" @click="openShareModal()">
      <span class="img"></span>
    </button>
    <button class="notification btn btn-block btn-default" :class="{on: project.applyFlag === '1'}" @click="book()" v-if="!clickable || page === 'detail'">
      <span class="txt">{{ project.applyFlag === "1" ? "알림 신청 완료" : "알림 신청" }}</span>
    </button>
    <Anchor :href="share.link" class="notification btn btn-block btn-default" v-else>
      <span class="txt">상세보기</span>
    </Anchor>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";
import redirectCallback from "@/scripts/redirectCallback";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentReservationBtn";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    project: Object,
    page: String,
    share: Object,
    change: Function,
    skeleton: Boolean,
    clickable: Boolean,
    link: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const book = () => {
      const args = {
        reservationSeq: props.project.reservationSeq,
        projectName: props.project.projectName,
        applyFlag: props.project.applyFlag,
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("reserveProjectOpenNotice", args);
      }

      redirectCallback.run("reserveProjectOpenNotice", {
        ...args,
        change: props.change
      });
    };

    const openShareModal = () => {
      store.commit("openModal", {
        name: "Share",
        params: {
          path: props.share.link,
          title: props.share.title,
          img: {
            url: props.share.imgUrl,
            width: definitions.thumbnail.width,
            height: definitions.thumbnail.height
          }
        }
      });
    };

    return {component, book, openShareModal};
  }
});
</script>

<style lang="scss" scoped>
.reservation-btn {
  position: relative;
  padding-left: $px69;
  height: $px69;

  .btn {
    height: 100%;
    box-shadow: none !important;
    margin-top: 0;

    &.share {
      position: absolute;
      top: 0;
      left: 0;
      width: $px69;
      text-align: center;
      border-radius: 0;

      .img {
        background-image: url('/assets/ico/common.share.svg');
        height: $px22;
        width: $px22;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.notification {
      font-size: 0;
      border-radius: 0;
      border-left: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .txt {
        font-size: $px16;
      }
    }

    &.on {
      color: $colorPoint;
    }
  }

  &.applied {
    .btn {
      border-color: $colorPoint;

      &.share {
        .img {
          background-image: url("/assets/ico/common.share.active.svg");
        }
      }

      &:hover {
        background-color: $colorBoxShadow;
      }
    }
  }

  &:not([data-page=detail]) {
    padding-left: $px50;
    height: $px50;
    //border-top: $px1 solid #eee;

    .btn {
      &.share {
        border-radius: 0 0 0 $px4;
        width: $px50;

        .img {
          width: $px20;
          height: $px20;
        }
      }

      &.notification {
        border-radius: 0 0 $px4 0;
      }
    }
  }

  &[data-page=home] {
    height: $px40;
    padding-left: $px40;

    .btn {
      &.share {
        width: $px40;

        .img {
          width: $px16;
          height: $px16;
        }
      }

      &.notification {
        .txt {
          font-size: $px13;
        }
      }
    }
  }

  &[data-page=list] {
    .btn {
      padding: 0 !important;
    }
  }

  &.skeleton {
    .btn {
      @include skeleton;

      .img {
        visibility: hidden;
      }
    }
  }

  @media(max-width: 767px) {
    &[data-page=home] {
      height: auto;
      padding-left: 0;

      .btn {
        padding: $px5;
        border-radius: $px4;
        border: $px1 solid $colorBorder;

        &.share {
          display: none;
        }

        &.notification {
          .txt {
            font-size: $px10;
          }
        }
      }
    }

    &[data-page=detail] {
      padding-left: $px46;
      height: $px46;

      .btn {
        &.share {
          width: $px46;

          .img {
            width: $px18;
            height: $px18;
          }
        }

        &.notification .txt {
          font-size: $px14;
        }
      }
    }
  }
}
</style>