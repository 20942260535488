<template>
  <div class="lnb">
    <ul class="tight">
      <li v-for="(item1, idx1) in items" :key="idx1">
        <router-link :class="{ 'color-point bold': $route.path === item1.url }" :to="item1.url">{{ item1.title }}</router-link>
        <ul v-if="item1.children && item1.children.length">
          <li v-for="(item2, idx2) in item1.children" :key="idx2">
            <router-link :class="{ 'color-point bold': $route.path === item2.url }" :to="item2.url">{{ item2.title }}</router-link>
            <ul v-if="item2.children && item2.children.length">
              <li v-for="(item3, idx2) in item2.children" :key="idx2">
                <router-link :to="item3.url">{{ item3.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageGuideLnb";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const items = [
      {
        title: "공통",
        url: "/guide/common",
        children: [
          {title: "크라우드펀딩이란?", url: "/guide/common#comCro"},
          {title: "회원가입", url: "/guide/common#comJoi"},
          {title: "FAQ", url: "/guide/common#comFaq"},
        ],
      },
      {
        title: "펀딩 후원자",
        url: "/guide/guest/reward",
        children: [
          {
            title: "후원형",
            url: "/guide/guest/reward",
            children: [
              {
                title: "결제",
                url: "/guide/guest/reward#gstRewPay",
                children: [],
              },
              {
                title: "리워드",
                url: "/guide/guest/reward#gstRewRew",
                children: [],
              },
              {
                title: "FAQ",
                url: "/guide/guest/reward#gstRewFaq",
                children: [],
              },
            ],
          },
          {
            title: "증권형",
            url: "/guide/guest/invest#gstInvCer",
            children: [
              {
                title: "투자자 인증",
                url: "/guide/guest/invest#gstInvCer",
                children: [],
              },
              {
                title: "투자 진행",
                url: "/guide/guest/invest#gstInvPro",
                children: [],
              },
              {
                title: "FAQ",
                url: "/guide/guest/invest#gstInvFaqr",
                children: [],
              },
            ],
          },
        ],
      },
      {
        title: "펀딩 진행자",
        url: "/guide/maker/reward",
        children: [
          {
            title: "후원형",
            url: "/guide/maker/reward#mkrRewApp",
            children: [
              {
                title: "프로젝트 신청",
                url: "/guide/maker/reward#mkrRewApp",
                children: [],
              },
              {
                title: "프로젝트 심사",
                url: "/guide/maker/reward#mkrRewChe",
                children: [],
              },
              {
                title: "펀딩 진행",
                url: "/guide/maker/reward#mkrRewPro",
                children: [],
              },
              {
                title: "정산",
                url: "/guide/maker/reward#mkrRewCal",
                children: [],
              },
              {
                title: "FAQ",
                url: "/guide/maker/reward#mkrRewFaq",
                children: [],
              },
            ],
          },
          {
            title: "증권형",
            url: "/guide/guest/invest#gstInvCer",
            children: [
              {
                title: "투자받기 신청",
                url: "/guide/maker/invest/#mkrInvApp",
                children: [],
              },
              {
                title: "내부심사 및 실사",
                url: "/guide/maker/invest/#mkrInvChe",
                children: [],
              },
              {
                title: "펀딩 등록",
                url: "/guide/maker/invest/#mkrInvReg",
                children: [],
              },
              {
                title: "펀딩 진행",
                url: "/guide/maker/invest/#mkrInvPro",
                children: [],
              },
              {
                title: "증권 발행",
                url: "/guide/maker/invest/#mkrInvDel",
                children: [],
              },
              {
                title: "FAQ",
                url: "/guide/maker/invest/#mkrInvFaq",
                children: [],
              },
            ],
          },
        ],
      },
    ];

    return {component, items};
  },
});
</script>

<style lang="scss" scoped>
.lnb {
  border: $px1 solid #eee;
  padding: $px10 $px30 0 $px30;
  font-size: $px14;

  a {
    display: block;
  }

  > ul {
    > li {
      display: block;
      padding: $px15 0;

      > a {
        font-weight: 500;
      }

      ul {
        margin: 0;
        list-style: none;

        li {
          a {
            padding: $px5 0;
          }
        }
      }

      > ul {
        border-top: $px1 solid #dedede;
        padding: $px10 0 $px10 $px5;

        ul {
          padding: $px5 0 $px10 $px5;

          li {
            a {
              &:before {
                content: " - ";
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: $px10;
        border-bottom: 0;
      }
    }
  }
}
</style>