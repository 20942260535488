import store from "@/scripts/store";
import track from "@/scripts/track";
import http from "@/scripts/http";
import caches from "@/scripts/caches";
import storage from "@/scripts/storage";

// 이 객체는 로그인 리다이렉트 콜백 시 사용합니다.
// ex) 미 로그인 상태에서 관심 프로젝트 등록 클릭 -> 세션 스토리지 저장 -> 로그인 페이지로 이동 -> 로그인 후 세션 스토리지 실행
export default {
  defines: {
    toggleProjectInterest({projectSeq, projectType, projectName, refresh, onSuccess, onFail}) {
      store.commit("toggleInterest", {
        projectSeq,
        projectType,
        projectName,
        refresh,
        onSuccess,
        onFail
      });
    },
    toggleMemberFollow({makerSeq, refresh}) {
      if (makerSeq === store.state.account.memberSeq) {
        return store.commit("setSwingMessage", "내 계정입니다.");
      }

      store.commit("toggleFollow", {makerSeq, refresh});
    },
    reserveProjectOpenNotice({reservationSeq, projectName, applyFlag, change}) {
      const urlPath = `/api/reservation/${reservationSeq}`;

      const postTrack = (apply) => {
        track.post({
          name: apply ? "reservationProjectNotificationApply" : "reservationProjectNotificationCancel",
          category: "오픈예정",
          topic: apply ? "알림 신청" : "알림 취소",
          title: projectName,
          urlPath: urlPath,
          type: "api",
          httpMethod: apply ? "POST" : "DELETE",
        });
      };

      if (applyFlag === "1") {
        http.delete(urlPath).then(() => {
          caches.resetHttps();
          store.commit("setSwingMessage", "알림 신청을 취소하였습니다.");
          typeof change === "function" ? change("0") : store.commit("refresh");
          postTrack(false);
        });
      } else if (!store.state.account.mobile?.trim()) {
        store.commit("openModal", {name: "MobileNumber"});
        return store.commit("setSwingMessage", "오픈 소식을 수신하실 휴대전화 번호를 입력해주세요.");
      } else {
        const urlPath = `/api/reservation/${reservationSeq}`;

        http.post(urlPath).then(() => {
          caches.resetHttps();
          store.commit("setSwingMessage", "알림 신청을 완료하였습니다.");
          typeof change === "function" ? change("1") : store.commit("refresh");
          postTrack(true);
        });
      }
    },
  },
  setAndLogin(name, params) {
    storage.set("session", "redirectCallback", window.JSON.stringify({name, params}));
    store.dispatch("goLoginPage").then();
  },
  run(name, params) {
    if (name) {
      typeof this.defines[name] === "function" && this.defines[name](params);
    } else {
      const session = storage.get("session", "redirectCallback");

      if (session) {
        const callback = window.JSON.parse(session);

        if (callback?.name && callback?.params) {
          this.run(callback.name, callback.params);
          this.destroy();
        }
      }
    }
  },
  destroy() {
    storage.remove("session", "redirectCallback");
  },
};