<template>
  <aside :id="`${component.name}Aside`" class="aside navbar-vertical navbar navbar-light" :class="{collapsed}">
    <div class="wrapper thin-scrollbar">
      <nav class="nav navbar-inner">
        <ul class="navbar-nav">
          <li v-for="(m1, idx) in menus" :key="idx" class="nav-item" :class="getClass(m1)" v-show="m1.visible">
            <Anchor :href="m1.children.length ? '' : m1.path"
                    :data-toggle="m1.children.length ? 'collapse' : '' "
                    :data-target="`#${component.name + m1.name}`"
                    class="nav-link"
                    role="button">
              <i :class="[m1.ico, m1.classes]"></i>
              <span class="nav-link-text">{{ m1.title }}</span>
            </Anchor>
            <ul :id="component.name + m1.name" class="nav nav-sm collapse">
              <li v-for="(m2, idx) in m1.children" :key="idx" class="nav-item" :class="getClass(m2)" v-show="m2.visible">
                <Anchor :href="m2.children.length ? '' : m2.path"
                        :data-toggle="m2.children.length ? 'collapse' : ''"
                        :data-target="`#${component.name + m1.name + m2.name}`"
                        class="nav-link" role="button">
                  <span class="sidenav-normal">{{ m2.title }}</span>
                </Anchor>
                <ul :id="component.name + m1.name + m2.name" class="collapse nav nav-sm">
                  <li v-for="(m3, idx) in m2.children" :key="idx" class="nav-item" :class="getClass(m3)">
                    <Anchor :href="m3.path" class="nav-link extra-menus">
                      <span>{{ m3.title }}</span>
                    </Anchor>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div :id="`${component.name}Toggle`" class="toggle" :class="{move: state.toggleMove}" @click="toggle()" :title="collapsed ? '메뉴 펴기' : '메뉴 접기'">
      <i class="fa" :class="[collapsed ? 'fa-angle-right' : 'fa-angle-left']"></i>
    </div>
  </aside>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageManageHome";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    menus: Array,
    breadcrumbs: Array,
    toggle: Function,
    collapsed: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      toggleMove: false
    });

    const getClass = (menu) => {
      const arr = [];
      if (props.breadcrumbs.includes(menu)) {
        props.breadcrumbs[0] === menu && arr.push("end");
        arr.push("active");
      }

      return arr.join(" ");
    };

    onMounted(() => {
      setTimeout(() => {
        state.toggleMove = true;
      }, 1000);

      if (!props.breadcrumbs.length) {
        return;
      } else if (router.app.$route.path === "/new-admin") {
        return;
      }

      const $anchors = document.querySelectorAll(`#${component.name}Aside nav ul li a[href*="${props.breadcrumbs[0].path}"]`);

      if ($anchors.length) {
        const $anchor = $anchors[$anchors.length - 1];
        const $list = window.$($anchor).parents("li");

        for (let i = $list.length - 1; i > 0; i -= 1) { // skip first element
          $list[i].querySelector("a")?.click();
        }
      }
    });

    return {component, state, getClass};
  }
});
</script>

<style lang="scss" scoped>
.aside {
  background: #fff;
  box-shadow: 0 0 $px32 0 rgba(136, 152, 170, .15);
  border-right: $px1 solid #eee;
  display: block;
  max-width: none;
  width: $px250;
  height: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: left 0.25s;
  z-index: 9;

  > .wrapper {
    height: 100%;
    overflow: auto;
    padding: $px22 $px30 $px20 $px30;

    > .nav {
      display: block;

      ul {
        list-style: none;

        > li {
          width: 100%;

          > a {
            &[data-toggle=collapse] {
              &[aria-expanded=true] {
                color: rgba(0, 0, 0, .9);

                &:after {
                  color: #3c3c3c;
                }
              }

              &:after {
                font-family: FontAwesome, serif;
              }
            }
          }

          &:hover > a {
            color: rgba(0, 0, 0, .9);
          }

          &.active > a > span {
            font-weight: 500;
          }

          &.end > a > span {
            text-decoration: underline;
          }
        }
      }

      > ul {
        font-size: $px15;

        > li {
          > a {
            font-weight: normal;
            padding-top: $px5;
            padding-bottom: $px5;
            color: #3c3c3c;

            i {
              min-width: $px30;
            }
          }

          ul {
            padding: $px1 0;
            position: relative;

            > li {
              > a {
                position: relative;

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: $px22;
                  transform: translateY(-50%);
                  width: $px18;
                  height: $px1;
                  background-image: url(/assets/ico/common.dotted.png);
                  background-size: $px3 $px1;
                  background-repeat: repeat-x;
                }
              }

              ul {
                li a:before {
                  width: $px38
                }
              }
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: $px20;
              width: $px1;
              height: calc(100% - 14px);
              background-image: url(/assets/ico/common.dotted.vertical.png);
              background-size: $px1 $px3;
              background-repeat: repeat-y;
            }
          }

          > ul > li {
            margin: $px1 0;

            > a.nav-link {
              padding: $px5 $px15 $px5 $px48 !important;
            }

            > ul > li {
              margin: $px1 0;

              > a.nav-link {
                padding-left: $px66 !important;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            &:last-child > a {
              padding-bottom: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          &.end > a > span {
            text-decoration: none;
          }

          &:not(:last-child) {
            margin-bottom: $px15;
          }
        }
      }
    }
  }

  > .toggle {
    display: inline-block;
    height: $px50;
    width: $px50;
    padding: $px9 0;
    cursor: pointer;
    position: absolute;
    bottom: $px50;
    left: $px-50;
    transition: left 0.25s;
    background: #fff;
    text-align: center;
    border-radius: 0 $px4 $px4 0;
    border: $px1 solid #eee;
    border-left-width: 0;
    line-height: 0;
    z-index: 1;

    i {
      font-size: $px30;
      color: $colorPurple;
      transition: filter 0.25s;
    }

    &.move {
      left: 100%;
    }

    &:hover i {
      filter: brightness(0.5);
    }
  }

  &.collapsed {
    left: $px-250;
  }
}
</style>