<template>
  <div class="callback">
    <div class="outer">
      <div class="container">
        <div class="wrapper">
          <RouterView/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "layoutCallback";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.callback {
  > .outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    > .container {
      display: table;
      height: 100%;

      > .wrapper {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        word-break: keep-all;
      }
    }
  }
}
</style>