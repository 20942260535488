import lib from "@/scripts/lib";

const caches = {
  https: [],
  getHttp(url, params) {
    this.https = this.https.filter(h => h.expTime >= new Date().getTime());

    const http = this.https.find(h =>
        h.req.url === url
        && ((!h.req.params && !params) || JSON.stringify(h.req.params) === JSON.stringify(params))
    );

    if (http) {
      return lib.getRenewed(http);
    }

    return undefined;
  },
  push(value) {
    this.https.push(lib.getRenewed(value));
  },
  resetHttps() {
    this.https = [];
  }
};

export default caches;