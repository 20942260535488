<template>
  <div class="planning-reward">
    <div class="header" :class="{skeleton:!state.loaded.header}" :style="{color:state.detail.topColorText, backgroundColor:state.detail.topColorBg}">
      <div class="container">
        <div class="row">
          <div class="left col-lg-3">
            <div class="banner">
              <img :src="computedThumbnailUrl"/>
            </div>
            <div class="share">
              <button @click="share('facebook')" :disabled="!state.loaded.header">
                <img src="/assets/ico/page.partnership.reward.sns.facebook.svg" title="페이스북으로 공유하기"/>
              </button>
              <button @click="share('twitter')" :disabled="!state.loaded.header">
                <img src="/assets/ico/page.partnership.reward.sns.twitter.svg" title="트위터로 공유하기"/>
              </button>
              <button @click="share('kakaolink')" :disabled="!state.loaded.header">
                <img src="/assets/ico/page.partnership.reward.sns.kakaotalk.svg" title="카카오톡으로 공유하기"/>
              </button>
              <button @click="share('band')" :disabled="!state.loaded.header">
                <img src="/assets/ico/page.partnership.reward.sns.band.svg" title="밴드로 공유하기"/>
              </button>
            </div>
          </div>
          <div class="txt col-lg-9">
            <div class="wrapper">
              <div class="tag">
                <b>{{ state.detail.tag }}</b>
              </div>
              <div class="title font-xl">
                <b>{{ state.detail.planningTitle }}</b>
              </div>
              <div class="duration">
                <span>기간 : {{ state.detail.startDate }} ~ {{ state.detail.endDate }}</span>
              </div>
              <div class="desc">
                <span>{{ state.detail.explanation }}</span>
              </div>
              <div class="logos">
                <img :src="l" v-for="(l, idx) in state.detail.partnerLogoPaths" :key="idx"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article">
      <div class="tabs" v-if="state.tabs.length > 1">
        <div class="container">
          <ul class="tight">
            <li :class="{'active color-point': $route.params.projectType === t.name }" v-for="(t, idx) in state.tabs" :key="idx">
              <router-link :to="`/planning/${state.planningSeq}/${t.name}`">{{ t.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="cards">
        <div class="container">
          <div class="acts clearfix">
            <div class="wrapper">
              <div class="side font-sm">
                <div class="dropdown" title="정렬">
                  <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ computedSorting }}</span>
                  <div class="dropdown-menu">
                    <a class="dropdown-item pointer" :class="{ on: s.name === state.sorting }" @click="sort(s.name)" v-for="(s, idx) in sorts" :key="idx">{{ s.title }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="state.loaded.projects">
            <ul class="tight clearfix" v-if="state.projects.length" ref="cardsRef">
              <li v-for="(p, idx) in state.projects" :key="idx">
                <Card
                    :projectSeq="p.projectSeq"
                    :projectType="p.projectType"
                    :link="`/${p.projectType}/${p.projectSeq}`"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :simpleText="p.simpleText"
                    :projectCate="p.projectCateName"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="p.investRate"
                    :dday="Number(p.dday)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.openId ? p.openId : p.builderSeq"
                    :successFailName="p.successFailName"
                    :interest="true"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-30 pb-30" v-else>
              <NoData/>
            </div>
            <ul class="tight clearfix" v-if="!state.loaded.more">
              <li v-for="i in 3" :key="i">
                <Card :skeleton="true" builderName="Wait a moment" projectName="Wait" projectCate="Wait"
                      simpleText="Please wait a moment" fundingType="A" :progressOrder="1" :count="100" :amount="100" :percent="10"
                />
              </li>
            </ul>
          </template>
          <ul class="tight clearfix" v-else>
            <li v-for="i in 3" :key="i">
              <Card :skeleton="true" builderName="Wait a moment" projectName="Wait" projectCate="Wait"
                    simpleText="Wait" fundingType="A" :progressOrder="1" :count="0" :amount="0" :percent="0"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Card from "@/components/Card";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pagePartnerReward";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Card},
  setup() {
    const component = new Component(() => {
      state.planningSeq = router.app.$route.params.planningSeq;
      store.dispatch("initSnsShare");

      state.loaded.header = false;
      http.get(`/api/planning/${state.planningSeq}`).then(({data}) => {
        state.loaded.header = true;
        state.detail = data.body;

        if (state.detail.explanation?.length > 400) {
          state.detail.explanation = state.detail.explanation.substring(0, 397) + "...";
        }

        load();
      });

      const args = {
        projectType: router.app.$route.params.projectType === "reward" ? "planningInvest" : "planningReward",
        planningSeq: state.planningSeq
      };

      http.get("/api/search/projects/count", args).then(({data}) => {
        if (data.body > 0) {
          state.tabs.push({
            name: "invest",
            title: "증권형"
          });
        }
      });
    });

    const state = reactive({
      planningSeq: "",
      sorting: "largest",
      loaded: {
        header: false,
        projects: false,
        more: true,
      },
      tabs: [{
        name: "reward",
        title: "후원형"
      }],
      detail: {
        contestName: "Please wait a moment",
        contestExplanationText: "Please wait a moment",
        topColorBg: "",
        topColorText: "",
        fundingStartDate: "0000년 00월 00일",
        fundingEndDate: "0000년 00월 00일",
        thumbImagePath: "",
        posterImagePath: "",
        partnerLogoPaths: [],
        investorCount: 0,
        dashboardUseYn: "",
      },
      projects: [],
      args: {
        pageIndex: 1,
        pageUnit: 9,
        pageSize: 5,
      },
      pagination: null,
    });

    const cardsRef = ref();

    const computedSorting = computed(() => {
      return sorts.find(s => s.name === state.sorting)?.title;
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(state.detail.imagePath, {skipSize: true});
    });

    const sorts = [
      {name: "highest", title: "모집금액순"},
      {name: "largest", title: "인기순"},
    ];

    const sort = (sorting) => {
      state.sorting = sorting;
      load();
    };

    const move = (e) => {
      router.push({path: `/partnership/${e.target.value}/reward`});
      store.commit("refresh");
    };

    const load = (more) => {
      if (more) {
        state.loaded.more = false;
        state.args.pageIndex += 1;
      } else {
        state.args.pageIndex = 1;
        state.loaded.projects = false;
      }

      const args = {
        projectType: router.app.$route.params.projectType === "reward" ? "planningReward" : "planningInvest",
        sorting: state.sorting,
        planningSeq: state.planningSeq,
        ...state.args,
      };

      http.get("/api/search/projects", args).then(({data}) => {
        state.loaded.projects = true;
        if (more) {
          for (let i in data.body.list) {
            state.projects.push(data.body.list[i]);
          }

          state.loaded.more = true;
          return;
        }

        state.pagination = data.body.paginationInfo;
        state.projects = data.body.list;
      });
    };

    const share = (platform) => {
      const url = location.href;
      const title = state.detail.planningTitle;
      const desc = state.detail.explanation;
      const imgUrl = computedThumbnailUrl.value;

      lib.loadImage(imgUrl, (imgWidth, imgHeight) => {
        store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
      });
    };

    const onScroll = () => {
      if (!state.loaded.header || !state.loaded.projects || state.args.pageIndex >= state.pagination.lastPageNo) {
        return;
      }

      const $card = cardsRef.value.querySelector("li .card");

      $card?.offsetHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY) && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, cardsRef, move, load, computedSorting, computedThumbnailUrl, sort, sorts, share};
  }
});
</script>

<style lang="scss" scoped>
.planning-reward {
  .header {
    background: $colorBackground;
    padding: $px30 0 $px21 0;

    .row > div {
      &.left {
        .share {
          text-align: center;
          margin-top: $px20;
          min-height: $px45;

          button {
            display: inline-block;
            cursor: pointer;
            width: $px40;
            padding: 0;
            background: none;
            color: inherit;
            border: none;
            font: inherit;
            outline: inherit;
            margin: 0 $px5;

            img {
              opacity: 0.9;
              transition: opacity 0.18s;
              width: 100%;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }

      &.txt {
        padding-top: $px9;

        > .wrapper {
          position: relative;

          .tag {
            padding-bottom: $px5;
          }

          .duration {
            padding: $px15 0;
          }

          .desc {
            word-break: break-all;
          }

          .logos {
            padding-top: $px20;

            img {
              margin: $px20 $px10 0 $px10;
              max-height: $px40;
            }
          }
        }
      }
    }
  }

  .article {
    padding: $px25 0 $px40 0;

    .status {
      padding-top: $px15;
      padding-bottom: $px25;
      margin: 0 auto;
      max-width: 991px;

      .part {
        position: relative;
        padding-left: $px72;
        float: left;
        width: calc(100% / 3 - 1px);

        .img {
          width: $px58;
          height: $px58;
          position: absolute;
          top: 0;
          left: 0;
        }

        .subject {
          font-weight: 600;
        }

        .cnt {
          > b {
            display: inline-block;
            width: auto;
            margin-right: $px3;
          }

          > span {
            display: inline-block;
            width: auto;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .tabs {
      text-align: center;
      padding-top: $px15;

      ul > li {
        display: inline-block;
        margin: $px5;
        padding: $px8;

        &.active {
          position: relative;

          > a {
            font-weight: 500;
          }

          &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 50%;
            width: $px20;
            height: $px2;
            margin-left: $px-10;
            background: $colorPoint;
          }
        }
      }
    }

    .cards {
      padding-top: $px5;

      .acts {
        > .wrapper {
          .side {
            padding-top: $px5;
            padding-bottom: $px3;

            .dropdown {
              float: right;

              > span {
                cursor: pointer;

                i {
                  margin-right: $px5
                }
              }

              .dropdown-menu {
                padding: 0;
                top: $px27 !important;
                transform: none !important;
                min-width: $px120;
                border-color: $colorPoint;
                border-radius: $px2;
                left: auto !important;
                right: 0 !important;

                a {
                  font-size: $px14;
                  padding: $px7 $px10;

                  &:hover {
                    background-color: $colorPoint;
                    color: #fff;
                  }

                  &.on {
                    background-color: $colorPoint;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      ul {
        margin: 0 $px-15;

        li {
          float: left;
          width: calc(100% / 3 - 0.1px);
          padding: $px15 $px15 $px16 $px15;
        }
      }

      .more {
        padding: $px50 $px15 $px20 $px15;
        text-align: center;

        button {
          padding: $px13 $px20;
        }
      }
    }
  }

  @media(min-width: 992px) {
    .header .row > div {
      &.left {
        padding-right: 0;
      }

      &.txt {
        padding-left: $px53;
      }
    }

    .article {
      .status {
        padding-left: $px100;
      }
    }
  }

  @media(max-width: 991px) {
    .header .row > div {
      &.left {
        > .banner {
          text-align: center;

          img {
            width: $px140;
            height: $px140;
          }
        }
      }

      &.txt {
        > .wrapper {
          padding-top: $px25;

          .logos {
            text-align: center;
          }
        }
      }
    }

    .article {
      .status {
        .part {
          padding: $px5 $px50;
          width: 100%;

          .img {
            display: none;
          }

          .subject {
            display: inline-block;
          }

          .cnt {
            float: right;
          }
        }
      }

      .cards ul li {
        width: 100%;
      }
    }
  }

  .header.skeleton .row > div.txt {
    .title b,
    .duration > span,
    .desc > span {
      @include skeleton;
    }
  }
}
</style>