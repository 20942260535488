<template>
  <div class="no-login">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 login">
          <div class="card">
            <div class="card-img-top text-center">
              <span class="ico" style="background-image:url(/assets/ico/component.nologin.character.svg)"></span>
            </div>
            <div class="card-body">
              <div class="card-title bold">로그인이 필요합니다.</div>
              <p class="card-text font-sm">이 페이지를 조회하시려면 로그인이 필요합니다. 아래 버튼을 눌러 로그인을 진행해주세요.</p>
              <button class="btn btn-point btn-block font-sm" @click="login()">
                <span>로그인</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 join">
          <div class="card">
            <div class="card-img-top text-center">
              <span class="ico" style="background-image:url(/assets/ico/page.join.indi.svg)"></span>
            </div>
            <div class="card-body">
              <div class="card-title bold">아직 회원이 아니신가요?</div>
              <p class="card-text font-sm">가입을 하기 원하시면 아래 버튼을 눌러 회원가입을 진행해주세요.</p>
              <button class="btn btn-secondary btn-block font-sm" @click="$store.commit('openModal', {name: 'Join'})">
                <span>회원가입</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentNoLogin";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    width: Number,
    refresh: Boolean
  },
  setup() {
    const component = new Component(() => {
    });

    const login = () => {
      store.dispatch("goLoginPage");
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {component, login};
  }
});
</script>

<style lang="scss" scoped>
.no-login {
  padding: $px50 $px15;
  text-align: center;

  > .container {
    max-width: 767px;

    > .row > div {
      .card {
        border: $px1 solid rgba(0, 0, 0, .125);
        border-radius: 0.25rem;

        .card-img-top {
          padding: $px30 0 $px5 0;

          .ico {
            display: inline-block;
            height: $px55;
            width: $px55;
            background-size: 100% 100%;
          }
        }

        .card-title {
          margin-bottom: $px11;
        }

        .card-body {
          text-align: left;

          .card-text {
            color: #666;
          }

          .btn {
            padding: $px13 $px25;
            height: $px52;
            box-shadow: none;
          }
        }

        .txt {
          padding-top: $px8;
        }
      }

      &.login .card:hover {
        border-color: $colorPoint;
      }

      &.join .card:hover {
        border-color: $colorSecondary;
      }
    }
  }

  @media(max-width: 991px) {
    > .container > .row > div.login {
      margin-bottom: $px15;
    }
  }

  @media(max-width: 767px) {
    padding: $px15 0;
  }
}
</style>