<template>
  <main class="main">
    <div class="view">
      <div class="container">
        <div class="card">
          <div class="card-header" v-if="menu">
            <i class="ni ml-2 mr-2" :class="menu.icon"></i>
            <b>{{ menu.titles[0] }}</b>
            <template v-if="menu.titles.length > 1">
              <i class="fa fa-angle-right ml-2 mr-2"></i>
              <b>{{ menu.titles[1] }}</b>
              <template v-if="menu.titles.length > 2">
                <i class="fa fa-angle-right ml-2 mr-2"></i>
                <b>{{ menu.titles[2] }}</b>
              </template>
            </template>
          </div>
          <div class="card-body">
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageManageMain";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    menu: Object,
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({});

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.main {
  margin-top: $px-65;

  > .view {
    > .container > .card {
      padding-top: $px10;
      padding-bottom: $px10;
      margin-bottom: $px20;

      .card-header {
        border-bottom: 0;
        padding-bottom: 0;

        > i, b {
          vertical-align: middle;
        }
      }

      > .card-body::v-deep {
        table.table {
          border-bottom: $px1 solid #e9ecef;
        }
      }
    }
  }
}
</style>