<template>
  <aside class="aside navbar navbar-light bg-white" :data-toggle="layout.toggle ? 'Y' : 'N'">
    <div class="header">
      <a href="/">
        <span class="img" style="background-image: url(/assets/img/common.logo.svg)"></span>
      </a>
    </div>
    <nav class="nav">
      <ul class="navbar-nav">
        <li class="nav-item" v-for="(m1, idx) in menus" :key="idx" v-show="m1.visible">
          <router-link :to="m1.path" class="nav-link font-base" @click.native="layout.toggle = false">
            <i class="ni" :class="[m1.color, m1.icon]"></i>
            <span class="nav-link-text" :class="{'color-black bold': m1.active}">{{ m1.title }}</span>
          </router-link>
          <ul class="nav nav-sm flex-column" v-if="m1.children.length">
            <li class="nav-item" v-for="(m2, idx2) in m1.children" :key="idx2" v-show="m2.visible">
              <router-link :to="m2.name === 'story' ? '' : m2.path" class="nav-link font-sm" @click.native="layout.toggle = false">
                <span :class="{'color-black bold': m2.active}">{{ m2.title }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageManageHome";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    layout: Object,
    toggle: Function,
    menus: Array
  },
  setup() {
    const component = new Component(() => {
      setTimeout(() => {
        state.loaded = true;
      }, 400);
    });

    const state = reactive({
      loaded: false
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.aside {
  display: block;
  transition: none;
  max-width: none;
  width: $px250;
  height: 100%;
  padding: $px18 $px25;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  .header {
    text-align: center;

    > a > .img {
      width: $px187;
      height: $px33;
    }
  }

  > .nav {
    padding-top: $px35;
    display: block;

    > ul {
      > li {
        .nav-link {
          font-weight: normal;
          padding-top: $px5;
          padding-bottom: $px5;
        }

        > .nav-link {
          color: #3c3c3c;

          > i {
            width: $px26;
            max-width: none;
          }
        }

        > ul {
          padding-top: $px3;
          padding-left: $px27;
        }

        &:not(:last-child) {
          margin-bottom: $px20;
        }
      }
    }
  }

  @media(max-width: 991px) {
    left: $px-250;
    transition: left 0.25s;
    overflow: auto;

    &[data-toggle=Y] {
      left: 0;
    }
  }
}
</style>