import lib from "@/scripts/lib";
import axios from "axios";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

let trackable = false;
let utmTag, chainCode;

const post = (params) => {
  try {
    if (!trackable) {
      return;
    }

    let urlQuery = params?.urlQuery || location.search;
    const queryParams = lib.getQueryParameters(urlQuery);

    if (queryParams["modals"]) {
      const modals = store.getters.modals().map(m => m.name);
      queryParams["modals"] = JSON.stringify(modals);
    }

    for (let i in queryParams) {
      queryParams[i] = decodeURIComponent(queryParams[i]);
    }

    urlQuery = lib.getQueryString(queryParams);

    axios.post(`/${definitions.apiVersion}/api/tracks`, {
      name: params.name,
      category: params.category,
      topic: params.topic,
      title: params?.title,
      memo: params?.memo,
      type: params?.type || "page",
      urlPath: params?.urlPath || location.pathname,
      urlQuery: urlQuery,
      httpMethod: params?.httpMethod || "GET",
      utmSource: utmTag?.source,
      utmMedium: utmTag?.medium,
      utmCampaign: utmTag?.campaign,
      utmContent: utmTag?.content,
      utmTerm: utmTag?.term,
      memberSeq: store.state.account.memberSeq || null,
      chainCode: chainCode,
      device: lib.getDevice(),
      os: lib.getOsName(),
      browser: lib.getBrowser(),
      userAgent: window.navigator.userAgent,
    }).then();
  } catch (e) {
    console.error(e);
  }
};

const initialize = () => {
  try {
    const queryParams = lib.getQueryParameters(location.search);

    if (queryParams["utm"]) {
      if (lib.getDecodedObject(queryParams["utm"])) {
        const utm = queryParams["utm"];
        const ranNum = lib.getRandomNum(1000, 9999);
        const chainNum = window.Number(ranNum + "" + lib.getDateFormat(new Date(), "yyMMddHHmmss"));
        const chainCode = chainNum.toString(16);

        // 브라우저에서 쿠키를 허용하지 않았다면 트래킹이 되지 않음.
        lib.cookie.set("utmTag", utm);
        lib.cookie.set("chainCode", chainCode);
      }

      delete queryParams["utm"];
      location.replace(location.pathname + lib.getQueryString(queryParams));
    } else {
      const utCookie = lib.cookie.get("utmTag");
      const utmObj = lib.getDecodedObject(utCookie);

      if (!utCookie || !utmObj) {
        return;
      }

      utmTag = utmObj;
      trackable = true;
      chainCode = lib.cookie.get("chainCode");
    }
  } catch (e) {
    console.error(e);
  }
};

export default {
  initialize, post
};