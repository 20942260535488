<template>
  <div id="app" :style="{ fontFamily: $definitions.font }">
    <Core v-if="$store.state.account.checked" :key="$store.state.keys.refresh"/>
    <Confirm v-if="$store.state.confirm.message"/>
    <Loading v-if="$store.state.loading"/>
    <StickyMessage v-if="$store.state.sticky.message"/>
    <SwingMessage v-if="$store.state.swing.message"/>
  </div>
</template>

<script>
import {defineComponent, onMounted} from "@vue/composition-api";
import SwingMessage from "@/components/SwingMessage";
import Loading from "@/components/Loading";
import Confirm from "@/components/Confirm";
import Core from "@/components/Core";
import store from "@/scripts/store";
import StickyMessage from "@/components/StickyMessage";
import translator from "./scripts/translator";

export default defineComponent({
  name: "App",
  components: {Core, Confirm, Loading, SwingMessage, StickyMessage,},
  setup() {
    onMounted(() => {
      translator.init(document.getElementById("app"));
    });

    store.commit("checkAccount");
  }
});
</script>