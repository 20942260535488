<template>
  <div class="reservation-list" :class="{skeleton: !state.loaded}">
    <div class="container">
      <div class="header">
        <div class="title font-lg">
          <b>오픈예정</b>
        </div>
        <div class="desc">
          <span>곧 오픈할 프로젝트입니다.</span>
          <div>
            <span>펀딩이 시작되면 알려드립니다.</span>
          </div>
        </div>
      </div>
      <div class="cards">
        <template v-if="state.loaded">
          <ul class="tight clearfix" v-if="state.projects && state.projects.length">
            <li v-for="p in state.projects" :key="'r' + p.reservationSeq">
              <ReservationCard
                  page="list"
                  :applyFlag="p.applyFlag.toString()"
                  :applyCnt="Number(p.applyCnt)"
                  :clickable="Boolean(p.detail)"
                  :item="p.item"
                  :link="`/reservation/${p.reservationSeq}`"
                  :memberName="p.corporateName || p.memberName"
                  :memberSeq="p.memberSeq"
                  :mainImagePosition="p.mainImagePosition"
                  :reservationCate="p.reservationCate"
                  :projectName="p.projectName"
                  :projectType="p.projectType"
                  :projectSeq="p.projectSeq.toString()"
                  :price="p.price"
                  :reservationSeq="Number(p.reservationSeq)"
                  :simpleText="p.simpleText"
                  :thumbFilePath="p.thumbnailPath"
              />
            </li>
          </ul>
          <div class="pt-3" v-else>
            <NoData/>
          </div>
        </template>
        <ul class="tight clearfix" v-else>
          <li v-for="i in 3" :key="i">
            <ReservationCard :skeleton="true"
                             memberName="Wait a moment"
                             reservationCate="reward"
                             projectName="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                             simpleText="Wait a moment"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import Card from "@/components/Card";
import NoData from "@/components/NoData";
import ReservationCard from "@/components/ReservationCard";
import track from "@/scripts/track";
import store from "@/scripts/store";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageReservationList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ReservationCard, NoData, Card},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "오픈예정");
      state.loaded = false;

      for (let i in router.app.$route.query) {
        i && (state.args[i] = router.app.$route.query[i]);
      }

      http.get("/api/project/reservations", state.args).then(({data}) => {
        state.loaded = true;
        state.projects = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;

        track.post({
          name: "reservationProjectList",
          category: "오픈예정",
          topic: "목록 조회"
        });
      });
    });

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 15,
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
      projects: []
    });

    const onChange = (reservationSeq) => {
      for (let i in state.projects) {
        if (state.projects[i].reservationSeq === reservationSeq) {
          if (state.projects[i].applyFlag === "1") {
            state.projects[i].applyCnt--;
            state.projects[i].applyFlag = "0";
          } else {
            state.projects[i].applyCnt++;
            state.projects[i].applyFlag = "1";
          }
        }
      }
    };

    return {component, state, onChange};
  }
});
</script>

<style lang="scss" scoped>
.reservation-list {
  > .container {
    padding-top: $px50;
    padding-bottom: $px50;

    .header {
      .title {
        padding-bottom: $px6;

        b {
          font-size: $px24;
        }
      }
    }

    .cards {
      ul {
        margin: $px-15 $px-15 0;

        li {
          float: left;
          width: calc(100% / 3 - 0.1px);
          padding: $px50 $px15 0 $px15;
        }
      }
    }
  }

  &.skeleton {
    > .container .header {
      .title b, .desc span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    > .container {
      padding-top: $px25;
      padding-bottom: $px50;

      .header {
        .title b {
          font-size: $px22;
        }

        .desc {
          font-size: $px14;
        }
      }

      .cards ul li {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
}
</style>