<template>
  <div class="confirm">
    <div class="wrapper">
      <div class="inner">
        <div class="message font-sm">
          <span>{{ $store.state.confirm.message }}</span>
          <span class="sub-message" v-if="$store.state.confirm.subMessage">{{ $store.state.confirm.subMessage }}</span>
        </div>
        <div class="actions clearfix">
          <button class="btn allow font-sm" :class="[$store.state.layout === 'admin' ? 'btn-purple' : 'btn-point']" @click="allow()" ref="allowBtnRef">{{ $store.state.confirm.allowTxt }}</button>
          <button class="btn btn-secondary disallow font-sm" @click="disallow()">{{ $store.state.confirm.disallowTxt }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "@vue/composition-api";
import env from "@/scripts/env";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalConfirm";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const allowBtnRef = ref();

    const close = () => {
      store.commit("confirm", {
        message: "",
        subMessage: "",
        allow: null,
        disallow: null,
      });
    };

    const allow = () => {
      typeof store.state.confirm.allow === "function" && store.state.confirm.allow();
      close();
    };

    const disallow = () => {
      typeof store.state.confirm.disallow === "function" && store.state.confirm.disallow();
      close();
    };

    onMounted(() => {
      env.device === "desktop" && allowBtnRef.value.focus();

      store.commit("addListener", [component.name, "onKeydown", (e) => {
        (e.key === "Escape" || e.key === "Esc") && !store.state.loading && disallow();
      }]);
    });

    return {component, allowBtnRef, allow, disallow};
  },
});
</script>

<style lang="scss" scoped>
.confirm {
  background: rgba(0, 0, 0, 0.75);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1400;

  > .wrapper {
    display: table;
    width: 100%;
    max-width: $px490;
    margin: 0 auto;
    height: 100%;

    > .inner {
      display: table-cell;
      vertical-align: middle;
      padding: 0 $px25;

      .message {
        background: #fff;
        padding: $px50 $px15;
        text-align: center;
        white-space: pre-line;
        word-break: keep-all;
      }

      .sub-message {
        color: #aaa;
        display: block;
        font-size: $px12;
        max-width: $px300;
        margin: 0 auto;
      }

      .actions {
        border-top: $px1 solid $colorBackground;
        text-align: right;

        .btn {
          width: 50%;
          height: $px60;
          padding: 0 $px20;
          float: left;
          border-radius: 0;
          position: relative;
          margin-right: 0;
          font-weight: normal;

          &:focus {
            z-index: 1;
          }

          &.disallow {
            background: $colorSecondary;
            border-color: $colorSecondary;
            color: #fff;

            &:hover {
              background: $colorSecondaryActive;
              border-color: $colorSecondaryActive;
            }
          }
        }

        .anchors {
          padding-top: $px20;
          text-align: center;

          span {
            color: #ddd;
            margin: 0 $px15;
          }
        }
      }
    }
  }
}
</style>