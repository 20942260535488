<template>
  <div class="board-list" :class="{skeleton: !state.loaded }">
    <template v-if="state.document.list.length">
      <ul class="tight">
        <li class="font-sm" :class="{notice: d.noticeFlag === 'Y'}" v-for="(d, idx) in state.document.list" :key="idx">
          <router-link :to="getLink(d)" class="wrapper">
            <span class="wrapper">
              <span class="flag badge badge-danger" v-if="d.noticeFlag === 'Y'">공지</span>
              <span class="flag cate badge" :class="getCateClass(d.cate)" v-if="d.cate">{{ d.cate }}</span>
              <span class="title">{{ d.title }}</span>
              <span class="hit badge badge-secondary" v-if="d.hit !== undefined">{{ d.hit > 999 ? "999+" : d.hit }} 조회</span>
              <span class="date" v-if="d.createDate">{{ d.createDate.substr(0, 10) }}</span>
            </span>
          </router-link>
        </li>
      </ul>
      <Pagination :info="state.document.pagination" :change="move" v-if="state.document.pagination"/>
    </template>
    <div v-else>
      <NoData/>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import Pagination from "@/components/Pagination";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "componentBoard";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, Pagination, Wait},
  mixins: [mixin],
  props: {
    category: String
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 10,
        firstIndex: 1,
        recordCountPerPage: 5,
        searchKeyword: "",
      },
      document: {
        list: [],
        pagination: null
      },
    });

    const getCateClass = (cate) => {
      return ["badge-primary", "badge-point", "badge-info", "badge-danger"][["후원", "투자", "인터뷰", "행사"].indexOf(cate)];
    };

    const getLink = (document) => {
      if (props.category === "notice" && !document?.noticeSeq) {
        return "";
      } else if (props.category === "fundingtip" && !document?.fundingNewsSeq) {
        return "";
      }

      return `/community/${props.category}/${props.category === "notice" ? document.noticeSeq : document.fundingNewsSeq}`;
    };

    const move = (num) => {
      if (state.args.pageIndex === num) {
        return;
      }

      const path = `/community/${props.category}`;
      const arr = [];

      num > 1 && arr.push(`pageIndex=${num}`);
      state.args.searchKeyword && arr.push(`searchKeyword=${state.args.searchKeyword}`);

      router.push({path: path + (arr.length ? "?" : "") + arr.join("&")});
    };

    const load = () => {
      let url = "";

      if (props.category === "notice") {
        url = "/api/notices";
      } else if (props.category === "fundingtip") {
        url = "/api/funding-news";
      } else {
        return store.commit("setSwingMessage", definitions.messages.errorCommon);
      }

      for (let i = 0; i < 10; i += 1) {
        state.document.list.push({
          title: "Please wait a moment",
          hit: "000",
          createDate: "0000-00-00",
          noticeFlag: "N",
        });
      }

      state.args.pageIndex = window.Number(router.app.$route.query.pageIndex || 1);
      state.args.searchKeyword = router.app.$route.query.searchKeyword || "";

      state.loaded = false;
      http.get(url, state.args).then(({data}) => {
        state.loaded = true;
        state.document.list = data.body.list;
        state.document.pagination = data.body.paginationInfo;
      });
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      window.scrollTo(0, 0);
      load();
    });

    return {component, state, getLink, getCateClass, move};
  }
});
</script>

<style lang="scss" scoped>
.board-list {
  ul > li {
    border: $px1 solid #eee;
    background: $colorBackground;

    > a {
      display: block;
      padding: $px20 $px15;

      > .wrapper {
        display: block;
        position: relative;

        > span {
          display: inline-block;
          vertical-align: middle;

          &.flag {
            margin-right: $px8;

            &.cate {
              min-width: $px40;
            }
          }

          &.title {
            font-weight: 500;
            margin-right: $px8;
          }

          &.date {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      &:hover {
        background: #eee;
      }
    }

    &:not(:last-child) {
      margin-bottom: $px15;
    }
  }

  .page {
    margin-top: $px35;
  }

  &.skeleton {
    ul > li > a > .wrapper > span {
      @include skeleton;
    }
  }

  @media(max-width: 991px) {
    ul > li > a {
      min-height: $px89;
    }

    ul > li {
      > a > .wrapper {
        padding-top: $px23;

        > span {
          &.flag {
            position: absolute;
            top: 0;
            left: 0;
          }

          &.hit {
            position: absolute;
            top: 0;
            left: 0;
          }

          &.date {
            position: static;
            margin-top: $px4;
            display: block;
          }
        }
      }

      &.notice {
        > a > .wrapper > span.hit {
          left: $px35;
        }
      }
    }
  }
}
</style>