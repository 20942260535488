<template>
  <div class="swing-message shadow" :data-show="$store.state.swing.show ? 'Y' : 'N'" :data-fade="$store.state.swing.fade ? 'Y' : 'N'" @click="$store.commit('closeSwingMessage')" :style="state.style" ref="swingMessageRef">
    <div class="message">{{ $store.state.swing.message }}</div>
    <i class="fa fa-info" aria-hidden="true"></i>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "componentSwingMessage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      style: {
        left: "0",
        marginTop: "0",
        // transform: "none",
      }
    });

    const swingMessageRef = ref();

    const draw = () => {
      state.style.left = "0";
      state.style.marginTop = "0";
      // state.style.transform = "none";

      nextTick(() => {
        const bodyCenter = document.body.offsetWidth / 2;
        const elemWidth = swingMessageRef.value.offsetWidth;
        const elemHeight = swingMessageRef.value.offsetHeight;
        const elemCenter = elemWidth / 2;

        state.style.left = (bodyCenter - elemCenter) + "px";
        state.style.marginTop = (elemHeight * -1) * (env.device === "mobile" ? 0.5 : 1) + "px";
        // state.style.transform = "none";
      });
    };

    watch(() => store.state.swing.message, () => {
      draw();
    });

    onMounted(() => {
      draw();
    });

    return {component, state, swingMessageRef};
  }
});
</script>

<style lang="scss" scoped>
.swing-message {
  position: fixed;
  width: fit-content;
  max-width: 767px;
  cursor: pointer;
  top: 100%;
  background: #fff;
  border: $px1 solid #eee;
  color: #000;
  border-radius: $px4;
  padding: $px20 $px25 $px20 $px48;
  transition: top 0.37s, opacity 0.37s, margin-top 0.37s;
  opacity: 0;
  z-index: 9999;

  .message {
    white-space: pre-line;
    font-size: $px15;
  }

  i {
    position: absolute;
    left: $px19;
    top: 50%;
    transform: translateY(-50%);
    padding: $px5;
    border-radius: 50%;
    background: $colorDark;
    color: #fff;
    font-size: $px10;
    width: $px20;
    text-align: center;
  }

  &[data-show=Y] {
    top: calc(100% - 100px);
    opacity: 1;
  }

  &[data-show=N] {
    margin-top: 0 !important;
  }

  &[data-fade=Y] {
    top: calc(100% - 65px);
    opacity: 0;
  }

  @media (max-width: 991px) {
    max-width: calc(100% - 30px);
    top: 50%;

    .message {
      font-size: $px14;
    }

    &[data-show=Y] {
      top: 50%;
      opacity: 1;
    }

    &[data-fade=Y] {
      top: 50%;
    }
  }
}
</style>