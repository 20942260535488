<template>
  <div class="no-data">
    <div class="font-sm">{{ text || "데이터가 없습니다." }}</div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentNoData";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    text: "",
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  padding: $px13;

  p {
    margin-bottom: $px8;
  }
}
</style>