<template>
  <div class="join-step1">
    <div class="kakao blank">
      <button class="btn no-shadow" @click="submitBySns('kakao')">
        <span class="ico"></span>
        <span>카카오로 빠르게 회원가입</span>
      </button>
    </div>
    <span class="section font-sm">다른 방법으로 회원가입 하기</span>
    <div class="sns">
      <div class="icons no-scrollbar">
        <button class="btn no-shadow" :class="s.name" v-for="(s, idx) in computedSnsButtons" :key="idx" :title="`${s.title} 계정으로 가입`" @click="submitBySns(s.name)"
                :style="{ backgroundImage: `url(${s.img})` }"></button>
      </div>
    </div>
    <div class="induce font-xs">
      <span class="desc">이미 오마이컴퍼니 회원이시라면</span>
      <Anchor class="pointer bold" href="/login">로그인</Anchor>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import env from "@/scripts/env";
import router from "@/scripts/router";
import account from "@/scripts/account";
import lib from "@/scripts/lib";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageJoinStep1";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    go: Function
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      destroyRedirectCallback: false
    });

    const computedSnsButtons = computed(() => {
      const result = [];

      definitions.sns.buttons.forEach(b => {
        switch (b.name) {
          case "google":
            break;

          case "apple":
            env.omcApp && result.push(b);
            break;

          case "kakao":
            router.app.$route.query.loginType === "email" && result.push(b);
            break;

          default:
            result.push(b);
            break;
        }
      });

      if (router.app.$route.query.loginType !== "email") {
        result.push({
          name: "email",
          title: "이메일",
          img: "/assets/ico/page.login.email.svg"
        });
      }

      return result;
    });

    const submitBySns = (snsType) => {
      state.destroyRedirectCallback = false;

      if (snsType === "email") {
        return props.go(2);
      }

      account.loginAtSnsPage(snsType, "login");
    };

    const emailJoin = () => {
      const query = lib.getRenewed(router.app.$route.query);

      query.loginType = "email";
      router.push({query});

      focus();
    };

    return {state, component, computedSnsButtons, submitBySns, emailJoin};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.account.scss";

.join-step1 {
}
</style>