<template>
  <main class="main">
    <div class="view">
      <div class="container">
        <div class="card">
          <div class="card-header">
            <template v-if="Array.isArray(breadcrumbs) && breadcrumbs.length">
              <router-link :to="breadcrumbs[breadcrumbs.length - 1].path">
                <i class="ni mr-2" :class="breadcrumbs[breadcrumbs.length - 1].ico"></i>
                <b>{{ breadcrumbs[breadcrumbs.length - 1].title }}</b>
              </router-link>
              <template v-if="breadcrumbs[breadcrumbs.length - 2]">
                <router-link :to="breadcrumbs[breadcrumbs.length - 2].path">
                  <i class="fa fa-angle-right ml-2 mr-2"></i>
                  <b>{{ breadcrumbs[breadcrumbs.length - 2].title }}</b>
                </router-link>
                <router-link :to="breadcrumbs[breadcrumbs.length - 3].path" v-if="breadcrumbs[breadcrumbs.length - 3]">
                  <i class="fa fa-angle-right ml-2 mr-2"></i>
                  <b>{{ breadcrumbs[breadcrumbs.length - 3].title }}</b>
                </router-link>
              </template>
              <img src="/assets/ico/common.popup.svg" title="팝업으로 열기" @click="popup()" v-if="!state.popup"/>
            </template>
            <a v-else>
              <b>제목 없음</b>
            </a>
          </div>
          <div class="card-body">
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageManageMain";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    breadcrumbs: Array,
  },
  setup(props) {
    const component = new Component(() => {
      state.popup = !!window.opener;
    });

    const state = reactive({
      popup: false
    });

    const popup = () => {
      const path = props.breadcrumbs[0].path;
      window.open(path, "popup", `width=${window.outerWidth},height=${window.outerHeight}`);
    };

    return {component, state, popup};
  },
});
</script>

<style lang="scss" scoped>
.main {
  margin-top: $px-70;

  > .view {
    > .container > .card {
      padding: $px15 $px35;
      margin-bottom: $px55;
      min-height: 700px;

      .card-header {
        border-bottom: 0;
        padding: $px15 0 0 0;
        font-size: 0;

        > a {
          i, b {
            font-size: $px14;
            vertical-align: middle;
          }
        }

        img {
          cursor: pointer;
          margin-left: $px8;
          vertical-align: middle;
          width: $px18;
          height: $px18;
        }
      }

      > .card-body {
        padding: $px25 0 0 0;

        &::v-deep {
          table.table {
            border-bottom: $px1 solid #e9ecef;
          }
        }
      }
    }
  }
}
</style>