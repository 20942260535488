import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueCompositionAPI from "@vue/composition-api";
import VueGtm from "@gtm-support/vue2-gtm";
import store from "@/scripts/store";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import definitions from "@/scripts/definitions";
import env from "@/scripts/env";
import track from "@/scripts/track";
import "@/styles/_common.scss";

// window.onpopstate = () => {
//   console.log("back or forward");
// };
//
// window.history.pushState = () => {
//   console.log("click move");
// };

Vue.use(VueRouter);
Vue.use(VueCompositionAPI);

Vue.prototype.$lib = lib;
Vue.prototype.$definitions = definitions;
Vue.prototype.$env = env;

Vue.config.productionTip = false;

track.initialize();

// if (env.browser !== "IE") {
//   store.state.observers.modal = new ResizeObserver(lib.setBodyScroll);
// }

// 전역 객체 프로퍼티 세팅(window.global.properties)
((w) => {
  if (!w.global?.properties) {
    console.error("전역 프로퍼티 값이 없습니다.");
    return;
  }

  const noCdn = env.location !== "prd" && env.development;

  definitions.imp = w.global.properties.imp;
  definitions.sns.properties = w.global.properties.sns;
  definitions.urls.imgCdn = noCdn ? "" : w.global.properties.cdn.imageUrl;
  definitions.urls.imgCdnMarkSuffix = noCdn ? "" : w.global.properties.cdn.imageUrlMarkSuffix;
  definitions.urls.account = w.global.properties.account.url;
  definitions.codes = w.global.codes;
  definitions.keywords = w.global.keywords;
  definitions.limits = w.global.properties.limits;

  // GTM 관련 추가 : https://www.notion.so/ohmycompanycom/GTM-With-Vue-SPA-e28d2556a2a042f5a5aa579998b5a664
  Vue.use(VueGtm, {
    id: window.global.properties.gtm.id, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x'
    // },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
})(window);

const vue = new Vue({
  render: h => h(App),
  store, router,
});

router.onReady(() => {
  vue.$mount("#app");
});