<template>
  <div class="board-detail" :class="{skeleton: !state.loaded }">
    <div class="header">
      <div class="wrapper">
        <span class="date font-sm">{{ state.document.createDate }}</span>
        <div class="title font-md">
          <span>{{ state.document.title }}</span>
        </div>
      </div>
    </div>
    <div class="article">
      <div class="content clearfix edit-style" v-html="state.document.content" :id="`${component.name}Content`"></div>
      <div class="files font-sm" v-if="state.document.fileSaveName">
        <label>[첨부 파일]</label>
        <ul class="tight">
          <li>
            <a :href="`${state.document.filePath}${state.document.fileSaveName}`" :download="`${state.document.fileOrigName}`">{{ state.document.fileOrigName }}</a>
          </li>
          <li v-if="state.document.fileOrigName1">
            <a :href="`${state.document.filePath1}${state.document.fileSaveName1}`" :download="`${state.document.fileOrigName1}`">{{ state.document.fileOrigName1 }}</a>
          </li>
          <li v-if="state.document.fileOrigName2">
            <a :href="`${state.document.filePath2}${state.document.fileSaveName2}`" :download="`${state.document.fileOrigName2}`">{{ state.document.fileOrigName2 }}</a>
          </li>
          <li v-if="state.document.fileOrigName3">
            <a :href="`${state.document.filePath3}${state.document.fileSaveName3}`" :download="`${state.document.fileOrigName3}`">{{ state.document.fileOrigName3 }}</a>
          </li>
          <li v-if="state.document.fileOrigName4">
            <a :href="`${state.document.filePath4}${state.document.fileSaveName4}`" :download="`${state.document.fileOrigName4}`">{{ state.document.fileOrigName4 }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="comment">
        <ul class="tight font-sm">
          <li v-for="(c, idx) in state.comments" :key="idx">
            <div class="wrapper">
              <MemberPicture :memberSeq="c.createId"/>
              <span class="name">{{ c.memberName }}</span>
              <span class="date">{{ c.createDate }}</span>
              <div class="comment">
                <span>{{ c.comment }}</span>
              </div>
              <div class="acts font-sm" v-if="c.createId === $store.state.account.memberSeq">
                <button class="btn" title="수정" @click="editComment(c)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button class="btn" title="삭제" @click="removeComment(c)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
        <textarea class="form-control border-focus-point font-sm" :placeholder="$store.state.account.loggedIn ? '댓글 내용을 입력해주세요.' : '댓글을 등록하시려면 클릭하여 로그인해주세요.'" ref="textareaRef"
                  @focus="$store.dispatch('openLoginModalIfNotLoggedIn')"></textarea>
      </div>
      <div class="buttons">
        <button class="btn btn-point float-right font-sm" @click="submit()">댓글 등록</button>
        <router-link class="btn btn-bordered-secondary font-sm" :to="getLink()">글 목록</router-link>
      </div>
      <div class="documents" v-if="category === 'notice'">
        <table class="table">
          <tbody>
          <tr v-if="state.documentPre && state.documentPre.noticeSeq">
            <th>이전</th>
            <td>
              <router-link :to="state.documentPre.link">{{ state.documentPre.title }}</router-link>
            </td>
          </tr>
          <tr v-if="state.documentNext && state.documentNext.noticeSeq">
            <th>다음</th>
            <td>
              <router-link :to="state.documentNext.link">{{ state.documentNext.title }}</router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, nextTick, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import Pagination from "@/components/Pagination";
import NoData from "@/components/NoData";
import router from "@/scripts/router";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";
import MemberPicture from "@/components/MemberPicture";

function Component(initialize) {
  this.name = "componentBoardDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture, NoData, Pagination, Wait},
  mixins: [mixin],
  props: {
    category: String
  },
  setup(props) {
    const component = new Component(() => {
      documentSeq = router.app.$route.params.seq;
      requestUrl = props.category === "notice" ? `/api/notices/${documentSeq}` : `/api/funding-news/${documentSeq}`;
      state.list.pageIndex = window.Number(router.app.$route.query.pageIndex) || 1;
      state.list.searchKeyword = router.app.$route.query.searchKeyword || "";
      load();
    });

    const state = reactive({
      loaded: false,
      list: {
        pageIndex: 0,
        searchKeyword: "",
      },
      comments: [],
      document: {
        title: "Wait a moment",
        createDate: "0000-00-00",
        content: "",
      },
      documentPre: {
        link: "",
      },
      documentNext: {},
    });

    const textareaRef = ref();
    let documentSeq, requestUrl;

    const submit = () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      const args = {
        comment: textareaRef.value.value?.trim(),
        createId: store.state.account.memberSeq
      };

      if (!args.comment) {
        textareaRef.value.focus();
        return store.commit("setSwingMessage", "댓글 내용을 입력해주세요.");
      } else if (lib.getBytes(args.comment) > 500) {
        textareaRef.value.focus();
        return store.commit("setSwingMessage", "내용의 길이가 너무 깁니다. 500Byte 이하로 작성해주세요.");
      }

      http.post(`${requestUrl}/comments`, args).then(() => {
        textareaRef.value.value = "";
        store.commit("setSwingMessage", "댓글을 등록하였습니다.");
        load();
      });
    };

    const getLink = (document) => {
      return document ? `/community/${props.category}/${document.noticeSeq}` : `/community/${props.category}`;
    };

    const onCommentEdited = ({commentSeq, content}) => {
      const args = {
        comment: content,
        updateId: store.state.account.memberSeq
      };

      http.put(`${requestUrl}/comments/${commentSeq}`, args).then(() => {
        store.commit("setSwingMessage", "댓글을 수정하였습니다.");
        store.commit("closeModal", {name: "Comment"});
        load();
      });
    };

    const editComment = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: props.category === "notice" ? comment.noticeCommentSeq : comment.fundingNewsCommentSeq,
          updateId: comment.memberSeq,
          content: comment.comment,
          maxByte: 500,
        },
        callback: `${component.name}.onCommentEdited`
      });
    };

    const removeComment = (comment) => {
      store.commit("confirm", {
        message: "선택하신 댓글을 삭제하시겠습니까?",
        allow() {
          http.delete(`${requestUrl}/comments/${props.category === "notice" ? comment.noticeCommentSeq : comment.fundingNewsCommentSeq}`).then(load).catch(httpError());
        }
      });
    };

    const load = () => {
      state.loaded = false;
      http.get(requestUrl).then(({data}) => {
        state.loaded = true;
        state.document = data.body.document;
        store.dispatch("setDocumentTitle", state.document.title);
        state.document.content && nextTick(() => {
          store.commit("setImagePopupListener", {
            $parents: document.querySelectorAll(`#${component.name}Content`),
            url: `/community/notice/${state.document.noticeSeq}`,
            title: state.document.title
          });
        });

        state.comments = data.body.commentList;
        state.documentPre = data.body.documentPre;
        state.documentNext = data.body.documentNext;

        if (state.documentPre) {
          state.documentPre.link = getLink(state.documentPre);
        }

        if (state.documentNext) {
          state.documentNext.link = getLink(state.documentPre);
        }

        nextTick(() => {
          const images = document.querySelectorAll("img[usemap]");

          for (let i = 0; i < images.length; i += 1) {
            lib.setImageMap(images[i]);
          }
        });
      });
    };

    onUnmounted(() => {
      store.commit("setImagePopupListener", {
        $parents: document.querySelectorAll(`#${component.name}Content`),
        destroy: true,
      });
    });

    return {component, state, textareaRef, submit, getLink, onCommentEdited, editComment, removeComment};
  }
});
</script>

<style lang="scss" scoped>
.board-detail {
  .header {
    padding: 10px 0 30px 0;
    border-bottom: 1px solid #eee;

    > .wrapper {
      position: relative;

      > span {
        &.date {
          position: absolute;
          top: $px2;
          right: 0;
        }
      }
    }
  }

  .article {
    margin-top: $px35;

    > .content {
      max-width: 812px;
      margin: 0 auto;
      word-break: break-all;
      font-size: $px18;
      color: #000;
    }

    > .files {
      padding-top: $px35;
      text-align: right;

      > label {
        font-weight: 500;
      }
    }
  }

  .footer {
    margin-top: $px50;

    .comment {
      word-break: break-all;

      > ul {
        padding-bottom: $px10;

        > li {
          padding: $px25 $px20;
          background: $colorBackground;
          border: $px1 solid #eee;
          margin-bottom: $px15;
          border-radius: $px4;

          > .wrapper {
            position: relative;
            padding-left: $px44;

            > .img {
              position: absolute;
              top: 0;
              left: 0;
            }

            > .name {
              margin-right: $px5;
              font-weight: 500;
            }

            > .date {
              color: #3c3c3c;
            }

            > .comment {
              padding-top: $px5;
            }

            > .acts {
              position: absolute;
              top: 0;
              right: 0;
              margin-top: $px-10;

              > .btn {
                margin-left: $px5;
              }
            }
          }
        }
      }

      textarea {
        min-height: $px105;
      }
    }

    .buttons {
      padding-top: $px25;

      .btn {
        padding: $px13 $px25;
      }
    }

    .documents {
      padding-top: $px25;
      margin-top: $px45;
      border-top: $px1 solid #eee;

      table {
        table-layout: fixed;

        th, td {
          border: 0
        }

        th {
          width: $px60;
          padding-left: 0;
        }

        td {
          padding-left: 0;
          padding-right: 0;

          a {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.skeleton {
    .header {
      > .wrapper span {
        @include skeleton;
      }
    }

    .article > .content {
      min-height: $px300;
      @include skeleton;
    }

    .footer .comment > ul > li > .wrapper {
      > .name, > .date, > .comment > span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .header {
      > .wrapper {
        > span.date {
          position: static;
        }

        > .title {
          padding-top: $px3;
        }
      }
    }

    .footer .buttons .btn {
      padding: $px5 $px10;
    }
  }
}
</style>