<template>
  <div class="channel">
    <div class="container">
      <div class="wrapper shadow">
        <div class="header" :class="{skeleton: !state.maker.loaded}">
          <div class="profile">
            <div class="picture">
              <MemberPicture :memberSeq="state.maker.info.memberSeq" size="120"></MemberPicture>
            </div>
            <div class="title clearfix">
              <div class="left-side">
                <span class="name">{{ state.maker.info.memberName }}</span>
                <span class="email" v-if="state.maker.info.email && state.maker.info.projects > 0">{{ state.maker.info.email }}</span>
                <div class="icons" v-if="state.maker.info.projects > 0">
                  <template v-for="(a, idx) in ['homepage', 'email', 'facebook', 'insta', 'blog']">
                    <a :key="idx"
                       :href="a === 'email' ? state.maker.info[a] : state.maker.info[a].startsWith('https://') ? state.maker.info[a] : `http://${state.maker.info[a].replace('http://', '')}`"
                       class="btn ico btn-sm btn-default" :disabled="!state.maker.loaded" :title="setTitle(a)" v-if="state.maker.info[a]" @click="copyEmail(a, $event)" target="_blank" rel="noopener noreferrer">
                      <span :style="{backgroundImage: `url('/assets/ico/page.project.detail.profile.sns.${a === 'insta' ? 'instagram' : a}.svg')`}"></span>
                    </a>
                  </template>
                </div>
                <div class="intro" ref="introRef" :class="{'view': state.viewMore}">
                  <span :class="{'text-muted': !state.maker.info.introduction}">{{ state.maker.info.introduction ? state.maker.info.introduction : "소개 내용이 없습니다." }}</span>
                </div>
                <span class="pointer view-more" @click="toggleView()" v-if="state.overflow">{{ state.viewMore ? "간략히" : "더보기" }}</span>
              </div>
              <div class="right-side float-right" v-if="$route.params.makerSeq !== $store.state.account.memberSeq">
                <div class="act">
                  <button class="btn btn-sm" :class="$store.state.follows.memberSequences.find(m => m.makerSeq === $route.params.makerSeq) ? 'following' : 'follow'" :disabled="!state.maker.loaded || !$store.state.follows.loaded"
                          @click="toggleFollow(state.maker.info.memberSeq)">
                    <span :class="{'text-muted': $store.state.follows.memberSequences.find(m => m.makerSeq === $route.params.makerSeq)}">
                        {{ $store.state.follows.memberSequences.find(m => m.makerSeq === $route.params.makerSeq) ? "팔로잉" : "+ 팔로우" }}
                      </span>
                    <span class="follower-number" v-if="state.maker.info.followers">
                        {{ state.maker.info.followers }}
                    </span>
                  </button>
                </div>
                <div class="act" v-if="state.maker.info.projects > 0">
                  <button class="btn btn-sm btn-default" @click="openMessage()" :disabled="!$store.state.follows.loaded || !state.maker.loaded">
                    <span>문의하기</span>
                  </button>
                </div>
              </div>
              <div class="right-side float-right" v-else>
                <Anchor :href="`/mypage/account/channel`">
                  <div class="act">
                    <button class="btn btn-sm btn-default">채널 관리</button>
                  </div>
                </Anchor>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs thin-scrollbar" ref="tabRef" :class="{skeleton: !state.maker.loaded}">
          <ul class="tight">
            <template v-for="(t, idx) in computedTabMenus">
              <li :key="idx" @click="setTabs(t.name)" :class="{active: $route.query.tabs === t.name}"
                  v-if="!state.maker.loaded || t.visible">
                <a>
                  <span class="text">{{ t.title }}</span>
                  <span class="badge">{{ state.maker.info[t.name] > 99 ? "99+" : (state.maker.info[t.name] ? state.maker.info[t.name] : 0) }}</span>
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="content">
          <DetailChannelProject v-if="$route.query.tabs === 'projects'"/>
          <DetailChannelReview v-if="$route.query.tabs === 'reviews'" :memberName="state.maker.info.memberName"/>
          <DetailChannelSupport v-if="$route.query.tabs === 'supports'"/>
          <DetailChannelNews v-if="$route.query.tabs === 'news'"/>
          <DetailChannelCheer :computedThumbnailUrl="computedThumbnailUrl" v-if="$route.query.tabs === 'cheers' && state.maker.info.projects > 0"/>
          <DetailChannelFollower v-if="$route.query.tabs === 'followers'"/>
          <DetailChannelFollowing v-if="$route.query.tabs === 'followings'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import redirectCallback from "@/scripts/redirectCallback";
import DetailChannelProject from "@/pages/channel/DetailChannelProject";
import DetailChannelSupport from "@/pages/channel/DetailChannelSupport";
import DetailChannelReview from "@/pages/channel/DetailChannelReview";
import DetailChannelNews from "@/pages/channel/DetailChannelNews";
import DetailChannelCheer from "@/pages/channel/DetailChannelCheer";
import DetailChannelFollower from "@/pages/channel/DetailChannelFollower";
import DetailChannelFollowing from "@/pages/channel/DetailChannelFollowing";

function Component(initialize) {
  this.name = "pageBase";
  this.initialize = initialize;
}

export default defineComponent({
  components: {
    DetailChannelProject,
    DetailChannelSupport,
    DetailChannelReview,
    DetailChannelNews,
    DetailChannelCheer,
    DetailChannelFollower,
    DetailChannelFollowing,
    NoData,
    Anchor,
    MemberPicture
  },
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.tabs = router.app.$route.query.tabs;
      load();
    });

    const state = reactive({
      tabs: "",
      more: false,
      viewMore: false,
      overflow: false,
      maker: {
        loaded: false,
        info: null,
      },
    });

    const tabRef = ref();
    const introRef = ref();

    const computedTabMenus = computed(() => {
      const tabMenus = [{
        name: "projects",
        title: "진행 프로젝트",
        visible: true,
      }, {
        name: "reviews",
        title: "프로젝트 후기",
        visible: state.maker.info.projects > 0,
      }, {
        name: "supports",
        title: "펀딩 프로젝트",
        visible: state.maker.info.supportOpenFlag === "Y",
      }, {
        name: "news",
        title: "최근 소식",
        visible: state.maker.info.projects > 0,
      }, {
        name: "cheers",
        title: "응원하기",
        visible: state.maker.info.projects > 0,
      }, {
        name: "followers",
        title: "팔로워",
        visible: true,
      }, {
        name: "followings",
        title: "팔로잉",
        visible: true,
      }];

      return tabMenus;
    });

    const copyEmail = (mail, e) => {
      if (mail !== "email") {
        return;
      }
      e.preventDefault();
      lib.copyToClipboard(state.maker.info.email.trim());
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const load = () => {
      if (!router.app.$route.query.tabs) {
        const query = lib.getRenewed(router.app.$route.query);
        state.tabs = "projects";
        query.tabs = state.tabs;
        pushToRouter(query, true);
      }

      store.commit("setFollows");
      getMakerInfo();
    };

    const getMakerInfo = () => {
      state.maker.info = {
        memberName: "Wait for moment",
        email: "Wait for moment",
        homepage: "Wait for moment",
        insta: "Wait for moment",
        facebook: "Wait for moment",
        blog: "Wait for moment",
        news: 0,
        cheers: 0,
        followers: 0,
        projects: 0
      };
      state.maker.loaded = false;

      http.get(`/api/channel/${router.app.$route.params.makerSeq}`).then(({data}) => {
        state.maker.loaded = true;
        state.maker.info = data.body;

        nextTick(() => {
          const maxHeight = introRef.value.getBoundingClientRect().height;
          const orgHeight = introRef.value.querySelector("span").getBoundingClientRect().height;

          if (maxHeight < orgHeight) {
            state.overflow = true;
          } else {
            state.overflow = false;
          }
        });

        if (!state.maker.info.introduction) {
          state.overflow = false;
        }
      });
    };

    const toggleFollow = (seq) => {
      const args = {makerSeq: seq, refresh: true};

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleMemberFollow", args);
      }

      redirectCallback.run("toggleMemberFollow", args);
    };

    const toggleView = () => {
      state.viewMore ? state.viewMore = false : state.viewMore = true;
    };

    const scrollToTop = () => {
      const rect = tabRef.value.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollToTop = rect.top + scrollTop - 120 - lib.getHeaderHeight();
      window.scrollTo({top: scrollToTop, left: 0, behavior: "smooth"});
    };

    const setTabs = (name) => {
      const query = lib.getRenewed(router.app.$route.query);

      state.tabs = name;
      query.tabs = state.tabs;

      pushToRouter(query);

      scrollToTop();
    };

    const setTitle = (name) => {
      switch (name) {
        case "homepage" : {
          return "홈페이지";
        }
        case "email" : {
          return "이메일 주소 복사";
        }
        case "facebook" : {
          return "페이스북";
        }
        case "insta" : {
          return "인스타그램";
        }
        case "blog" : {
          return "블로그";
        }
      }
    };

    const computedThumbnailUrl = computed(() => {
      return (thumbFileUrl) => {
        return store.getters.thumbnailUrl(thumbFileUrl);
      };
    });

    const pushToRouter = (query, replace) => {
      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      replace ? router.replace({query}) : router.push({query});
    };

    const openMessage = () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: state.maker.info.memberSeq,
            name: state.maker.info.memberName
          }]
        }
      });
    };

    return {component, state, computedTabMenus, setTabs, openMessage, toggleFollow, toggleView, setTitle, copyEmail, getMakerInfo, computedThumbnailUrl, tabRef, introRef};
  }
})
;
</script>

<style lang="scss" scoped>
.channel {
  padding-top: $px100;
  padding-bottom: $px50;

  .no-data {
    padding: $px70 0;
  }

  .container {
    > .wrapper {
      background: #fff;
      padding: 0 $px75 $px60 $px75;

      .header {
        position: relative;

        .profile {
          .picture {
            text-align: center;
            transform: translateY(-50%);
            position: absolute;
            top: 0;
            left: $px-20;

            .img {
              background-size: auto 100%;
              background-position: center;
              border-radius: 50%;
              width: $px120;
              height: $px120;
              border: $px1 solid #eee;
            }
          }

          .title {
            padding-top: $px80;

            .left-side {
              display: inline-block;

              > span {
                vertical-align: unset;
              }

              .name {
                font-size: $px28;
                font-weight: 600;
                line-height: 1;
              }

              .email {
                font-size: $px16;
                margin-left: $px8;
                font-weight: 300;
              }

              .icons {
                display: inline-block;
                padding-left: $px8;

                > .btn {
                  border-color: $colorBorder;
                  position: relative;
                  padding: $px15;
                  vertical-align: bottom;
                  border-radius: 50%;

                  > span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-size: $px15 $px15;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                    display: block;
                    width: 100%;
                    height: 100%;
                  }

                  &:not(:first-child) {
                    margin-left: $px8;
                  }
                }
              }

              .intro {
                margin-top: $px20;
                max-width: $px625;
                max-height: $px67;
                font-size: $px14;
                white-space: pre-line;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;

                &.view {
                  max-height: none;
                  display: block;
                  -webkit-line-clamp: none;
                  text-overflow: clip;
                }
              }

              .view-more {
                font-size: $px14;
                color: $colorSecondary;
                margin-top: $px10;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .right-side {
              .act {
                .btn {
                  width: $px230;
                  padding: $px10 0;

                  &.follow {
                    background-color: $colorPurple;
                    color: #fff;
                    margin-bottom: $px8;

                    &:hover {
                      background-color: $colorPurpleActive;
                    }
                  }

                  &.following {
                    background-color: #efefef;
                    color: $colorSecondary;
                    margin-bottom: $px8;

                    &:hover {
                      background-color: $colorBackground;
                    }
                  }

                  .follower-number {
                    font-size: $px12;
                  }
                }
              }
            }
          }
        }

        &.skeleton {
          .profile {
            .picture, .title .left-side, .right-side, .bottom {
              .icons > .btn > span, > span, .intro, .intro > span {
                @include skeleton;
              }

              .act button.btn {
                &.follow, &.following, &.message {
                  @include skeleton;

                  > span {
                    @include skeleton;
                  }
                }
              }
            }
          }
        }
      }

      .tabs {
        padding-top: $px40;
        white-space: nowrap;
        overflow: auto;

        &::-webkit-scrollbar {
          height: $px3;
        }


        ul li {
          display: inline-block;
          padding-right: $px30;

          a {
            font-size: $px15;
            cursor: pointer;
            display: inline-block;
            padding-bottom: $px10;
            border-bottom: $px1 solid transparent;

            > span {
              vertical-align: middle;
            }
          }

          .badge {
            background: #f7f7f7;
            border: $px1 solid #eee;
            margin-left: $px8;
            font-size: $px10;
            padding: $px4 $px6;
          }

          &.active a {
            color: $colorPurple;
            border-bottom-color: $colorPurple;

            .text {
              font-weight: 500;
            }

            .badge {
              background-color: $colorPurple;
              border-color: $colorPurple;
              color: #fff;
            }
          }
        }

        &.skeleton {
          ul li {
            a > span {
              @include skeleton;
            }
          }
        }
      }

      .content {
        font-size: $px14;

        ::v-deep .no-data {
          padding: $px70 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: $px75;

    .container {
      > .wrapper {
        padding: 0 $px25 $px60 $px25;

        .header {
          .profile .picture {
            left: $px-10;

            span {
              &.img {
                width: $px100 !important;
                height: $px100 !important;
              }
            }
          }

          .profile {
            .title {
              line-height: 1;
              padding-top: $px60;

              .left-side {
                > span, > div {
                  display: block;
                }

                .email {
                  margin-left: 0;
                  padding-top: $px20;
                }

                .icons {
                  padding: $px20 0 0 0;

                  > .btn {
                    margin: 0;

                    &:not(:first-child) {
                      margin-left: $px8;
                    }
                  }
                }

                .intro {
                  margin-top: $px40;
                  max-height: $px70;
                  line-height: initial;
                }
              }

              .right-side {
                width: 100%;
                padding-top: $px40;

                .act .btn {
                  margin-left: 0;
                  width: 100%;
                }
              }
            }
          }
        }

        .tabs {
          ul li {
            padding-right: $px20;

            a {
              font-size: $px14;
            }
          }
        }
      }
    }
  }
}
</style>