import http from "@/scripts/http";
import store from "@/scripts/store";
import track from "@/scripts/track";
import router from "@/scripts/router";
import definitions from "@/scripts/definitions";
import lib from "@/scripts/lib";
import redirectCallback from "@/scripts/redirectCallback";
import env from "@/scripts/env";
import caches from "@/scripts/caches";

const postLoginTrack = (url, page) => {
  track.post({
    name: "memberLogin",
    category: "회원",
    topic: "로그인 완료",
    urlPath: url,
    type: "api",
    httpMethod: "POST",
    memo: page
  });
};

export default {
  loginAtSnsPage(snsType, action) {
    const redirectUrl = router.app.$route.query.redirectUrl || "";

    switch (snsType) {
      case "naver": {
        store.dispatch("appendScript", {
          src: "//static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js",
          onEveryLoad: () => {
            window.naverLogin = new window.naver.LoginWithNaverId({
              clientId: definitions.sns.properties.naverAppId,
              callbackUrl: `${window.location.origin}/callback/${action}/naver?redirectUrl=${redirectUrl}`,
            });

            window.naverLogin.init();
            location.href = window.naverLogin.generateAuthorizeUrl();
          }
        }).then();
        return;
      }

      case "kakao": {
        if (env.omcApp) {
          const callbackUrl = `${window.location.origin}/callback/${action}/kakao`;
          const state = JSON.stringify({redirectUrl});
          location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${definitions.sns.properties.kakaoAppKeyJavaScript}&redirect_uri=${callbackUrl}&response_type=code&state=${state}`;
        } else {
          store.dispatch("appendScript", {
            src: "//developers.kakao.com/sdk/js/kakao.min.js",
            onFirstLoad() {
              window.Kakao.init(definitions.sns.properties.kakaoAppKeyJavaScript);
            },
            onEveryLoad() {
              window.Kakao.Auth.authorize({
                redirectUri: `${window.location.origin}/callback/${action}/kakao`,
                state: JSON.stringify({redirectUrl})
              });
            }
          }).then();
        }
        return;
      }

      case "facebook": {
        const callbackUrl = `${window.location.origin}/callback/${action}/facebook`;
        const state = JSON.stringify({redirectUrl});
        window.location.href = `https://www.facebook.com/v3.3/dialog/oauth?client_id=${definitions.sns.properties.facebookAppId}&redirect_uri=${callbackUrl}&state=${state}&resource_type=token&scope=email`;
        return;
      }

      case "google": {
        if (lib.isKakaoInAppBrowser()) {
          return store.commit("setSwingMessage", "구글 정책으로 카카오톡 브라우저에서는 이 기능을 지원하지 않습니다. 다른 브라우저를 이용해주세요.");
        }

        const callbackUrl = `${window.location.origin}/callback/${action}/google`;
        const state = JSON.stringify({redirectUrl});
        window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${definitions.sns.properties.googleClientId}&redirect_uri=${callbackUrl}&state=${state}&response_type=token&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
        return;
      }

      case "apple": {
        const callbackUrl = `${window.location.origin}/callback/${action}/apple`;
        const config = {
          client_id: definitions.sns.properties.appleClientId, // This is the service ID we created.
          redirect_uri: callbackUrl, // As registered along with our service ID
          response_type: "code id_token",
          state: JSON.stringify({page: action, redirectUrl}),
          scope: "name email",
          response_mode: "form_post",
        };

        const queryString = Object.keys(config).map(k => `${k}=${window.encodeURIComponent(config[k])}`).join("&");
        window.location.href = `https://appleid.apple.com/auth/authorize?${queryString}`;
        return;
      }
    }
  },
  loginByEmail(args, onSuccess, onError) {
    const url = "/api/member/login/email";

    http.post(url, args).then(({data}) => {
      if (data?.body?.code === "LOGIN_SUCCESS") {
        typeof onSuccess === "function" && onSuccess();
        caches.resetHttps();

        store.commit("checkAccount", () => {
          store.dispatch("redirect", () => {
            redirectCallback.run();
            postLoginTrack(url, "email");
          }).then();
        });
      } else if (typeof onError === "function") {
        onError(data);
      }
    });
  },
  loginBySns(args, action, redirectUrl) {
    const url = "/api/member/login/sns";

    const onSuccess = (message) => {
      message && store.commit("setSwingMessage", message);
      store.commit("checkAccount", () => {
        router.replace(redirectUrl || "/").then(() => {
          redirectCallback.run();
        });
      });

      postLoginTrack(url, "sns");
    };

    http.post(url, {...args, action}).then(({data}) => {
      switch (data?.body.code) {
        case "ALREADY_JOINED":
          return onSuccess(data.message);

        case "LOGIN_SUCCESS":
          return onSuccess();

        case "VERIFIED_BY_SNS":
        case "NOT_FOUND_SNS_ACCOUNT_NEED_JOIN": {
          let mobile = data.body.params.mobile;

          if (mobile) {
            const numbers = mobile.split(" ");

            if (numbers.length && numbers[0].startsWith("+")) {
              numbers[0] = "0";
            }

            mobile = numbers.join("");
            mobile = mobile.replaceAll("-", "");
            mobile = lib.getPhoneNumberFormat(mobile);
          }

          store.commit("setJoinForm", {
            memberId: data.body.params.memberId,
            memberName: data.body.params.memberName,
            memberType: "P",
            mobile: mobile,
            joinType: args.snsType,
            snsId: data.body.params.snsId,
            snsType: args.snsType,
          });

          const path = "/join";
          const query = {step: 3};

          if (redirectUrl) {
            query.redirectUrl = redirectUrl;
          }

          return router.replace({path, query}).then(() => {
            store.commit("setSwingMessage", data.message);
          });
        }

        default:
          store.dispatch("goLoginPage", "login", redirectUrl).then();
          return store.commit("setSwingMessage", data.message);
      }
    }).catch(() => {
      store.dispatch("goLoginPage", "login", redirectUrl).then();
    });
  },
};