<template>
  <div class="spinner">
    <span :class="[$store.state.layout === 'admin' ? 'border-top-purple' : 'border-top-point']"></span>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentSpinner";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.spinner {
  text-align: center;
  position: relative;
  min-height: $px120;
  max-height: 100%;

  span {
    display: inline-block;
    border: $px3 solid #ddd;
    height: $px30;
    width: $px30;
    border-radius: 50%;
    animation: spin 0.25s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $px-15;
    margin-left: $px-15;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>