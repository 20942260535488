import store from "@/scripts/store";
import definitions from "@/scripts/definitions";
import caches from "@/scripts/caches";

const privates = {
  version: definitions.apiVersion,
  submitCnt: 0,
  timer: 0,
};

const increaseSubmitCnt = (value) => {
  privates.submitCnt += value;
};

const interceptor = {
  request: {
    onRequest(config) {
      if (config.method === "get") {
        config.headers["Pragma"] = "no-cache";
      } else if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      }

      if (config.url?.startsWith("/api")) {
        config.url = `/${privates.version}${config.url}`;
      }

      if (config.method !== "get" || config.headers["omc-loading"]) {
        store.commit("setLoading", true);
      }

      increaseSubmitCnt(1);
      return config;
    },
    onRejected(err) {
      return Promise.resolve(err);
    },
  },
  response: {
    onFulfilled(res) {
      // 테스트 하고 푸시하기
      const cacheMilliseconds = Number.parseInt(res.config.headers["omc-cache-time"] || "0");

      cacheMilliseconds && caches.push({
        req: {
          url: res.config.url,
          params: res.config.params
        },
        res: {
          data: res.data,
          status: res.status,
          statusText: res.statusText,
          headers: res.headers,
          config: res.config,
          request: res.request,
        },
        expTime: new Date().getTime() + cacheMilliseconds,
      });

      if (res.config.method !== "get" || res.config.headers["omc-loading"]) {
        store.commit("setLoading", false);
      }

      const filterIssue = res.headers
          && res.headers[Object.keys(res.headers).find(key => key.toLowerCase() === "omc-filter-issue")];

      switch (filterIssue) {
        case "displace":
          store.commit("setStickyMessage", {
            message: "보안상의 이유로 입력하신 내용 일부를 삭제하였습니다.\n자세한 내용은 플랫폼개발팀(070-5097-2459)에 문의해주세요.",
            type: "warning"
          });
          break;
      }

      (res.data.body === undefined || res.data.code === undefined || res.data.message === undefined)
      && res.config.url.startsWith(`/${privates.version}/api`)
      && console.warn(`요청하신 API의 응답 값이 표준화되어 있지 않습니다(URL: ${res.config.url}).`);

      increaseSubmitCnt(-1);

      clearTimeout(privates.timer);

      privates.timer = setTimeout(() => {
        privates.submitCnt === 0 && typeof store.state.customListeners.axiosResponseOnSuccess === "function"
        && store.state.customListeners.axiosResponseOnSuccess();
      }, 1000);

      return res;
    },
    onRejected(err) {
      store.state.loading && store.commit("setLoading", false);

      const tell = (message) => {
        !err?.config?.headers["omc-prevent-message"] && store.commit("setSwingMessage", message);
      };

      if (err?.response) {
        const message = err?.response?.data?.message
        && err.response.data.message.length < 100
        && err.response.data.message.toLowerCase().trim() !== "null"
            ? err.response.data.message : definitions.messages.errorCommon;

        switch (err.response.status) {
          case 400: {
            let invalidMessage = "";

            // 400 인 경우 중 Field Invalid Exception 경우
            const invalidErrors = err?.response?.data?.errors;
            if (invalidErrors && Array.isArray(invalidErrors) && invalidErrors.length) {
              const values = Object.values(invalidErrors[0]);
              invalidMessage = Array.isArray(values) && values.length ? values[0] : "";
            }

            tell(invalidMessage || message);
            break;
          }

          case 401:
            switch (err.response.data?.code) {
              case "LOGIN_REQUIRED":
                store.commit("setAccount");
                store.dispatch("goLoginPage").then();
                break;

              case "PASSPORT_REQUIRED":
                store.dispatch("goPassportPage", {redirectUrl: "/form/applications"}).then();
                break;

              default:
                tell(message);
                break;
            }
            break;

          default:
            tell(message);
            break;
        }
      } else {
        tell(definitions.messages.errorNetwork);
      }

      increaseSubmitCnt(-1);
      return Promise.reject(err);
    },
  },
};

export default interceptor;