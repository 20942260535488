<template>
  <div class="detail-following">
    <div class="wrapper row" v-if="!state.following.loaded || (state.following.loaded && state.following.list.length > 0)">
      <div class="item col-lg-6" v-for="(f, idx) in state.following.list" :key="idx" :id="`${component.name}Item`">
        <FollowCard
            :skeleton="f.skeleton || !state.following.loaded"
            :place="'channel'"
            :memberSeq="f.makerSeq"
            :memberName="f.memberName"
            :supportCount="f.supportCount"
            :openedCount="f.openedCount"
        />
      </div>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import FollowCard from "@/components/FollowCard";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageChannelDetailFollowing";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, FollowCard, Anchor, MemberPicture},
  mixins: [mixin],
  props: {
    computedThumbnailUrl: Function,
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      following: {
        loaded: false,
        more: false,
        paginationInfo: null,
        list: [],
      },
      args: {
        pageIndex: 1,
        pageUnit: 10,
        pageSize: 5,
      },
      temp: ""
    });

    const load = (more) => {
      if (!more) {
        state.args.pageIndex = 1;
        state.following.loaded = false;
      } else {
        state.args.pageIndex += 1;
        state.following.more = true;
      }

      for (let i = 0; i < 4; i += 1) {
        state.following.list.push({
          projectName: "Wait",
          title: "Please Wait a moment",
          createDate: "0000-00-00",
          memberName: "Please Wait a moment",
          content: "Please Wait a moment",
          skeleton: true,
        });
      }

      http.get(`/api/channel/${router.app.$route.params.makerSeq}/followings`, state.args).then(({data}) => {
        state.following.paginationInfo = data.body.paginationInfo;
        state.following.loaded = true;

        if (more) {
          for (let i in data.body.list) {
            state.following.list = state.following.list.filter(i => !i.skeleton);
            state.following.list.push(data.body.list[i]);
          }
          return state.following.more = false;
        }

        state.following.list = data.body.list;

      });
    };

    const onScroll = () => {
      if (!state.following.loaded || state.args.pageIndex >= state.following.paginationInfo.lastPageNo || state.following.more) {
        return;
      }

      const firstItemHeight = document.getElementById(`${component.name}Item`).offsetHeight;

      firstItemHeight * 2 >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.detail-following {
  margin: $px20 0;
}
</style>