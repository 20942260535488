<template>
  <div class="my-page-index">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="wrapper">
        <div class="lnb">
          <div class="wrapper no-scrollbar">
            <div class="part mine">
              <div class="subject font-xs">
                <span>마이페이지</span>
              </div>
              <ul class="tight">
                <li :class="{active: m.name === state.menu.name}" v-for="(m, idx) in state.menus.mines" :key="idx" v-show="m.valid">
                  <router-link :to="m.paths[0]">
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="part mine">
              <div class="subject font-xs">
                <span>결제</span>
              </div>
              <ul class="tight">
                <li class="pointer" :class="{active: m.name === state.menu.name}" v-for="(m, idx) in state.menus.pays" :key="idx" v-show="m.valid">
                  <router-link :to="m.paths[0]">
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="part quick">
              <div class="subject font-xs">
                <span>기타</span>
              </div>
              <ul class="tight no-scrollbar">
                <li class="pointer" :class="{active: m.name === state.menu.name}" v-for="(m, idx) in state.menus.etc" :key="idx" v-show="m.valid">
                  <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer" v-if="m.name === 'Guide'">
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </a>
                  <a v-else-if="m.name === 'Logout'" @click="$store.dispatch('logoutAccount', {home: true})">
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </a>
                  <router-link :to="m.paths[0]" v-else>
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="content shadow">
          <div class="head">
            <div class="header">
              <div class="title">
                <span v-if="state.menu.name === 'Dashboard'">마이페이지</span>
                <span v-else>{{ state.menu.title }}</span>
              </div>
              <div class="desc font-sm" v-if="state.menu.name === 'Dashboard'">
                <template v-if="state.menu.desc">
                  <span class="mr-1">{{ state.menu.desc }}</span>
                  <router-link to="/mypage/investor" class="color-anchor">투자자 인증 받기</router-link>
                </template>
                <span v-else>&nbsp;</span>
              </div>
            </div>
            <div class="profile">
              <div class="picture">
                <MyPicture/>
                <router-link :class="($route.params.param1 !== 'account' || $route.params.param2) ? 'pointer' : 'cursor-init'" :disabled="$route.params.param1 === 'account' && !$route.params.param2"
                             :to="`/channel/${$store.state.account.memberSeq}?tabs=projects`">
                  <span v-if="$route.params.param1 !== 'account' || $route.params.param2">내채널 &nbsp;<i class="fa fa-angle-right"/></span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="body">
            <Component :is="computedComponent" :menu="state.menu" v-if="$store.state.investor.checked"/>
          </div>
        </div>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineAsyncComponent, defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import MyPicture from "@/components/MyPicture";
import NoLogin from "@/components/NoLogin";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageMyPage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, NoLogin, MyPicture},
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "마이페이지");

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      if (router.app.$route.path === "/mypage") {
        state.menu = state.menus.mines[0];
      } else {
        for (let i in state.menus) {
          for (let j in state.menus[i]) {
            if (["Investor"].includes(state.menus[i][j].name)) {
              if (state.menus[i][j].paths.some(p => router.app.$route.path === p)) {
                state.menu = state.menus[i][j];
              }
            } else if (state.menus[i][j].paths.some(p => router.app.$route.path.startsWith(p))) {
              state.menu = state.menus[i][j];
            }
          }
        }
      }

      if (!state.menu.name) {
        store.commit("setSwingMessage", definitions.messages.errorCommon);
        return router.replace({path: "/"});
      }

      store.commit("checkInvestor", () => {
        state.menus.pays.find(p => p.name === "InvestorAccountInfo").valid = store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE";
      });
    });

    const state = reactive({
      menu: {
        name: null,
        ico: null,
        title: null,
        desc: null,
        paths: [],
      },
      menus: {
        mines: [{
          name: "Dashboard",
          ico: "page.mypage.menu.dashboard.svg",
          title: "대시보드",
          desc: "",
          paths: ["/mypage"],
          valid: true
        }, {
          name: "ArchiveApplication",
          ico: "page.mypage.menu.news.svg",
          title: "나의 신청서",
          desc: "",
          paths: ["/archive/applications"],
          valid: store.state.account.memberType === "C"
        }, {
          name: "Participant",
          ico: "page.mypage.menu.participant.svg",
          title: "펀딩 프로젝트",
          desc: "",
          paths: ["/mypage/participant/reward", "/mypage/participant/invest", "/mypage/participant/mock"],
          valid: true
        }, {
          name: "Open",
          ico: "page.mypage.menu.open.svg",
          title: "진행 프로젝트",
          desc: "",
          paths: ["/mypage/open/reward", "/mypage/open/invest", "/mypage/open/mock"],
          valid: true
        }, {
          name: "Interest",
          ico: "page.mypage.menu.interest.svg",
          title: "관심 프로젝트",
          desc: "",
          paths: ["/mypage/interest", "/mypage/interest/reward", "/mypage/interest/invest"],
          valid: true
        }, {
          name: "Account",
          ico: "page.mypage.menu.account.svg",
          title: "계정 정보",
          desc: "",
          paths: ["/mypage/account", "/mypage/account/password", "/mypage/account/company", "/mypage/account/etc"],
          valid: true
        }, {
          name: "News",
          ico: "page.mypage.menu.news.svg",
          title: "최근 소식",
          desc: "",
          paths: ["/mypage/news"],
          valid: true
        }, {
          name: "Message",
          ico: "page.mypage.menu.message.svg",
          title: "메시지",
          desc: "",
          paths: ["/mypage/message/receive", "/mypage/message/send", "/mypage/message/keep"],
          valid: true
        }, {
          name: "Follow",
          ico: "page.mypage.menu.follow.svg",
          title: "팔로우",
          desc: "",
          paths: ["/mypage/follow/followings", "/mypage/follow/followers"],
          valid: true
        }, {
          name: "Review",
          ico: "page.mypage.menu.review.svg",
          title: "프로젝트 후기",
          desc: "",
          paths: ["/mypage/review/unreviewed", "/mypage/review/written"],
          valid: true
        }, {
          name: "Inquiry",
          ico: "page.mypage.menu.inquiry.svg",
          title: "문의하기",
          desc: "",
          paths: ["/mypage/inquiry"],
          valid: true
        }],
        pays: [{
          name: "Payment",
          ico: "page.mypage.menu.payment.svg",
          title: "지불 정보",
          desc: "",
          paths: ["/mypage/payment"],
          valid: true
        }, {
          name: "Point",
          ico: "page.mypage.menu.point.svg",
          title: "포인트",
          desc: "",
          paths: ["/mypage/point"],
          valid: true
        }, {
          name: "Coupon",
          ico: "page.mypage.menu.coupon.svg",
          title: "쿠폰",
          desc: "",
          paths: ["/mypage/coupon"],
          valid: true
        }, {
          name: "Investor",
          ico: "page.mypage.menu.investor.svg",
          title: "투자자 인증",
          desc: "",
          paths: ["/mypage/investor"],
          valid: true
        }, {
          name: "InvestorAccountInfo",
          ico: "page.mypage.menu.investor.account.info.svg",
          title: "투자 계좌",
          desc: "",
          paths: ["/mypage/investor-account-info"],
          valid: store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE"
        }],
        etc: [{
          name: "Guide",
          ico: "page.mypage.menu.guide.svg",
          title: "이용가이드",
          desc: "",
          paths: ["/guide/main"],
          valid: true
        }, {
          name: "Logout",
          ico: "page.mypage.menu.logout.svg",
          title: "로그아웃",
          desc: "",
          paths: [],
          valid: true
        }]
      },
    });

    const computedComponent = computed(() => {
      let menuName = state.menu.name;

      if (menuName) {
        if (menuName === "Investor") {
          if (store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE") {
            menuName = "InvestorStatus";
          } else {
            menuName = "InvestorApply";
          }
        }

        return defineAsyncComponent(() => import(`@/pages/my-page/${menuName}`));
      }

      return defineAsyncComponent(() => import(`@/components/Wait`));
    });

    return {component, state, computedComponent};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-index {
  background: $colorBackground;

  > .container {
    padding-top: $px33;
    padding-bottom: $px33;

    > .wrapper {
      position: relative;
      display: flex;

      .lnb {
        flex-shrink: 0;
        padding-right: $px55;

        > .wrapper {
          //background: #49545f;

          .part {
            margin-bottom: $px25;
            padding: 0 $px3;

            .subject {
              //color: rgba(255, 255, 255, 0.61);
              margin-bottom: $px6;
              padding: $px5 $px15 $px5 0;
            }

            ul {
              li {
                margin: $px3 0;
                background-repeat: no-repeat;
                border: $px1 solid transparent;
                font-weight: 300;

                > a {
                  display: block;
                  padding: $px10 $px5 $px10 $px25;
                  text-decoration: none;
                  font-size: $px14;
                  position: relative;

                  span {
                    &.img {
                      position: absolute;
                      width: $px17;
                      height: $px17;
                      top: $px13;
                      left: 0;
                      filter: grayscale(1);
                      transition: 0.4s filter;
                    }
                  }
                }

                &.active, &:hover {
                  > a {
                    color: $colorPoint;
                  }

                  .img {
                    filter: none;
                  }
                }

                &.active {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .content {
        background: #fff;
        border-radius: $px6;
        flex-grow: 1;

        .head {
          padding: $px40 $px200 0 $px50;
          position: relative;

          .header {
            padding-bottom: $px15;

            .title {
              font-size: $px22;
              font-weight: 500;
            }

            .desc {
              margin-top: $px15;
              color: #767676;
            }
          }

          .profile {
            text-align: center;
            position: absolute;
            top: $px25;
            right: $px50;

            .picture {
              display: inline-block;

              > a {
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                > span {
                  display: inline-block;
                  font-size: $px13;
                  margin-top: $px13;
                  line-height: 1;
                }

                &:hover > span {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .body {
          padding: $px50 $px50 $px50 $px50;
        }
      }
    }
  }

  @media(min-width: 992px) {
    .container > .wrapper {
      .lnb {
        height: 100%;

        > .wrapper {
          height: 100%;
        }
      }

      .content {
        min-height: 900px;
      }
    }
  }

  @media(max-width: 991px) {
    .container {
      padding-top: $px5;
      padding-bottom: $px15;

      > .wrapper {
        flex-direction: column;

        .lnb {
          background: $colorBackground;
          padding-right: 0;
          position: sticky;
          top: 0;
          left: 0;
          width: 100%;
          float: none;
          z-index: 1;

          > .wrapper {
            border-radius: 0;
            white-space: nowrap;
            overflow: auto;
            font-size: 0;
            padding: 0;
            margin-right: $px-15;

            .part {
              display: inline-block;
              padding: 0;
              margin-bottom: 0;

              .subject {
                display: none;
              }

              ul {
                white-space: nowrap;
                overflow: auto;

                li {
                  display: inline-block;
                  margin: 0;
                  background-position: 50% $px15;
                  border-radius: 0;
                  text-align: center;

                  > a {
                    padding: $px38 $px5 $px10 $px5;
                    min-width: $px64;
                    font-size: 0.75rem;

                    span.img {
                      left: 50%;
                      transform: translateX(-50%);
                    }
                  }
                }
              }

              &:first-child ul {
                margin-left: $px-5;
              }

              &:last-child ul > li:last-child {
                margin-right: $px10;
              }
            }
          }
        }

        .content {
          float: none;
          width: 100%;
          margin-top: $px6;
          margin-bottom: $px15;
          padding-bottom: $px25;
          border: $px1 solid #eee;
          border-radius: $px4;
          min-height: 595px;

          .head {
            padding: $px15 $px15 0 $px15;

            .header {
              padding: $px5;

              .title {
                font-size: $px18;
              }
            }

            .profile {
              display: none;
            }
          }

          .body {
            padding: $px15 $px15 0 $px15;
          }
        }
      }
    }
  }

  &.skeleton {
    > .container > .wrapper .lnb > .wrapper .part {
      .subject {
        @include skeleton;
      }

      ul li > a > span {
        @include skeleton;
      }
    }
  }
}
</style>