<template>
  <div class="container">
    <Search/>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Search from "@/modals/Search";

function Component(initialize) {
  this.name = "pageSearch";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Search},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
</style>