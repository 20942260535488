<template>
  <div class="join account">
    <div class="container">
      <div class="title section">
        <b>빠르게 회원가입하여</b>
        <b>오마이컴퍼니 서비스를 이용하세요.</b>
      </div>
      <div class="content">
        <div class="core">
          <div class="wrapper">
            <JoinStep1 :go="go" v-if="state.step.num === 1"/>
            <JoinStep2 :go="go" :temp="state.temp" v-else-if="state.step.num === 2"/>
            <JoinStep3 v-else-if="state.step.num === 3"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onUnmounted, reactive, watch} from "@vue/composition-api";
import JoinStep1 from "./JoinStep1";
import JoinStep2 from "./JoinStep2";
import JoinStep3 from "./JoinStep3";
import mixin from "@/scripts/mixin";
import track from "@/scripts/track";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";

function Component(initialize) {
  this.name = "pageJoin";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {JoinStep1, JoinStep2, JoinStep3},
  setup() {
    const component = new Component(() => {
      const step = window.Number(router.app.$route.query.step);
      !store.state.joinForm.joinType && store.commit("setJoinForm", {joinType: "homepage"});
      !store.state.joinForm.emailRecv && store.commit("setJoinForm", {emailRecv: "N"});
      !store.state.joinForm.smsRecv && store.commit("setJoinForm", {smsRecv: "N"});

      if (step) {
        // step 값이 3일 때는 인증 된 이메일이나 sns 계정이 필요함.
        if (step === 3
            && !store.state.joinForm.memberId
            && !store.state.joinForm.snsId
            && !store.state.joinForm.snsType) {
          go(1);
        } else {
          move(step);
        }
      }

      redirectCallback.destroy();

      track.post({
        name: "memberJoin",
        category: "회원",
        topic: "회원가입 페이지",
        type: "page",
      });
    });

    const state = reactive({
      temp: {
        insertMailSeq: "",
        authCode: "",
      },
      step: {
        num: 1,
        maxNum: 0,
        list: [
          {title: "유형 선택", ico: "page.join.select.svg"},
          {title: "이메일 인증", ico: "page.join.cert.svg"},
          {title: "정보 입력", ico: "page.join.input.svg"},
        ]
      }
    });

    const go = (num) => {
      const query = lib.getRenewed(router.app.$route.query);

      if (num === 1 && query.step) {
        delete query.step;
      } else if (window.Number(query.step) !== num) {
        query.step = num;
      } else {
        return;
      }

      router.push({query});
    };

    const move = (num) => {
      state.step.num = num;

      if (state.step.maxNum < state.step.num) {
        state.step.maxNum = state.step.num;
      }
    };

    watch(() => router.app.$route.query.step, (step) => {
      move(window.Number(step) || 1);
    });

    onUnmounted(() => {
      store.commit("setJoinForm");
    });

    return {component, state, go, move};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.account.scss";

.join {
}
</style>