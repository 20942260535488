<template>
  <component :is="!href || href.includes('://') ? 'a' : 'router-link'"
             :href="href ? href : null" :to="href && href.includes('://') ? '' : href"
             @click="typeof click === 'function' && click()">
    <slot/>
  </component>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentAnchor";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    href: String,
    click: Function
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped></style>