<template>
  <div class="core">
    <div class="layouts">
      <Callback v-if="$store.state.layout === 'callback'"/>
      <Manage v-else-if="$store.state.layout === 'manage'"/>
      <Admin v-else-if="$store.state.layout === 'admin'"/>
      <Form v-else-if="$store.state.layout === 'form'"/>
      <Archive v-else-if="$store.state.layout === 'archive'"/>
      <Empty v-else-if="$store.state.layout === 'empty'"/>
      <Ground v-else-if="$store.state.layout === 'ground'"/>
    </div>
    <Modals v-if="$route.query.modals"/>
  </div>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, watch} from "@vue/composition-api";
import Ground from "@/layouts/ground/Ground";
import Manage from "@/layouts/manage/Manage";
import Callback from "@/layouts/callback/Callback";
import Modals from "@/components/Modals";
import store from "@/scripts/store";
import router from "@/scripts/router";
import storage from "@/scripts/storage";
import mixin from "@/scripts/mixin";
import Admin from "@/layouts/admin/Admin";
import Form from "@/layouts/form/Form";
import Archive from "@/layouts/archive/Form";
import Empty from "@/layouts/empty/Empty";

function Component(initialize) {
  this.name = "componentCore";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Empty, Form, Archive, Admin, Modals, Ground, Manage, Callback},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("setLayout");
    });

    const timer = {scroll: null};

    const onClick = (e) => {
      const listeners = store.state.listeners.onClick;
      for (let i in listeners) {
        typeof listeners[i].func === "function" && listeners[i].func(e);
      }
    };

    const onKeydown = (e) => {
      const listeners = store.state.listeners.onKeydown;
      for (let i in listeners) {
        typeof listeners[i].func === "function" && listeners[i].func(e);
      }
    };

    const onResize = () => {
      const listeners = store.state.listeners.onResize;
      for (let i in listeners) {
        typeof listeners[i].func === "function" && listeners[i].func();
      }
    };

    const onScroll = () => {
      timer.scroll && clearTimeout(timer.scroll);
      timer.scroll = setTimeout(() => {
        const listeners = store.state.listeners.onScroll;
        for (let i in listeners) {
          typeof listeners[i].func === "function" && listeners[i].func();
        }
      });
    };

    const onWheel = () => {
      clearInterval(timer.scroll);
    };

    const appleIdSignInOnSuccess = (e) => {
      typeof store.state.customListeners.appleIdSignInOnSuccess === "function" && store.state.customListeners.appleIdSignInOnSuccess(e);
    };

    const appleIdSignInOnFailure = (e) => {
      typeof store.state.customListeners.appleIdSignInOnFailure === "function" && store.state.customListeners.appleIdSignInOnFailure(e);
    };

    if (router.app.$route.query.layout === "hidden") {
      storage.set("session", "layout", "hidden");
    }

    // 웹 브라우저의 URL 경로가 바뀔 때마다 실행
    watch(() => router.app.$route.path, (next, prev) => {
      store.dispatch("setDocumentTitle");
      store.commit("checkAccount");
      store.commit("setPrevPath", prev);
      store.commit("setLayout");
    });

    onMounted(() => {
      store.commit("addListener", [component.name, "onClick", (e) => {
        Array.from(document.getElementsByClassName("help")).forEach((elem) => {
          elem.removeAttribute("style");
          elem.innerHTML = "";
        });

        if (e.target?.classList?.contains("help")) {
          e.target.style.position = "relative";
          e.target.innerHTML = `<span style="cursor:default;position:absolute;top:14px;left:17px;display:inline-block;width:210px;background:#fff;padding:10px 10px 7px 10px;border:1px solid #eee;font-size:12px;line-height:1.6;white-space:pre-line;word-break:keep-all;z-index:10;">${e.target.dataset?.text}</span>`;
        }
      }]);

      document.addEventListener("click", onClick);
      document.addEventListener("keydown", onKeydown);
      document.addEventListener("AppleIDSignInOnSuccess", appleIdSignInOnSuccess);
      document.addEventListener("AppleIDSignInOnFailure", appleIdSignInOnFailure);

      window.addEventListener("resize", onResize);
      window.addEventListener("scroll", onScroll);
      window.addEventListener("wheel", onWheel);
    });

    onUnmounted(() => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("keydown", onKeydown);
      document.removeEventListener("AppleIDSignInOnSuccess", appleIdSignInOnSuccess);
      document.removeEventListener("AppleIDSignInOnFailure", appleIdSignInOnFailure);

      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("wheel", onWheel);
    });

    return {component};
  }
});
</script>