<template>
  <ProjectList projectType="search"/>
</template>

<script>
import {defineComponent, watch} from "@vue/composition-api";
import ProjectList from "@/pages/project/List.vue";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageMainSearchResult";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {ProjectList},
  setup() {
    const component = new Component(() => {
      const setDocumentTitle = () => {
        const keyword = router.app.$route.query.searchKeyword;
        store.dispatch("setDocumentTitle", `${keyword ? keyword + " " : ""}검색 결과`);
        return false;
      };

      setDocumentTitle() || watch(() => router.app.$route.query.searchKeyword, setDocumentTitle);
    });

    return {component};
  },
})
;
</script>