<template>
  <div class="planning" ref="planningRef" :class="{skeleton : !state.loaded }" :data-count="state.items.length">
    <div class="swiper-container" ref="swiperRef">
      <div class="swiper-wrapper no-scrollbar" v-if="state.loaded">
        <div class="swiper-slide" v-for="(item, idx) in state.items" :key="idx">
          <router-link :to="`/planning/${item.planningSeq}/reward`" class="pointer">
            <span class="thumbnail">
              <span :style="{ 'background-image': `url(${item.topImage})` }"></span>
            </span>
            <span class="cont">
              <span class="tags ellipsis">{{ item.tag }}</span>
              <span class="title ellipsis">{{ item.planningTitle }}</span>
              <span class="date ellipsis font-xs">{{ item.date }}</span>
            </span>
          </router-link>
        </div>
      </div>
      <div class="swiper-wrapper no-scrollbar" v-else>
        <a class="swiper-slide" v-for="i in 4" :key="i"></a>
      </div>
    </div>
    <div class="control">
      <div class="prev">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="next">
        <i class="fa fa-angle-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref,} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageMainHomePlanning";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    loaded: Boolean,
    runWhenEmpty: Function,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({
      items: [],
      loaded: false
    });

    const planningRef = ref();
    const swiperRef = ref();

    onMounted(() => {
      let swiper;

      const instantiate = () => {
        swiper = new window.Swiper(swiperRef.value, {
          slidesPerView: window.innerWidth > 991 ? 2 : 1,
          spaceBetween: 20,
          navigation: {
            nextEl: planningRef.value.querySelector(".control .next"),
            prevEl: planningRef.value.querySelector(".control .prev"),
          },
        });
      };

      state.loaded = false;
      emit("update:loaded", state.loaded);

      http.get("/api/main/plannings", undefined, {cache: true}).then(({data}) => {
        state.loaded = true;
        emit("update:loaded", state.loaded);

        if (data.body.length) {
          for (let d of data.body) {
            d.topImage = definitions.urls.imgCdn + d.topImagePath + d.topImageName;

            if (d.startDate) {
              d.date = lib.getDateFormat(d.startDate);
            }

            if (d.endDate) {
              d.date += " ~ " + lib.getDateFormat(d.endDate);
            }

            state.items.push(d);
          }

          window.innerWidth > 991 && nextTick(instantiate);
        } else {
          typeof props.runWhenEmpty === "function" && props.runWhenEmpty();
        }
      }).catch(httpError());

      store.commit("addListener", [component.name, "onResize", () => {
        if (window.innerWidth > 991) {
          swiper?.destroy();
          instantiate();
        } else {
          swiper?.destroy();
        }
      }]);
    });

    return {component, state, planningRef, swiperRef};
  },
})
;
</script>

<style lang="scss" scoped>
.planning {
  width: 100%;
  position: relative;

  > .header {
    color: #fff;
    padding: 0 $px15 $px25 $px15;
    text-align: center;

    .title {
      font-size: $px30;
      font-weight: 500;
    }

    .desc {
      font-size: $px20;
      padding: $px15 0;
    }
  }

  .swiper-container {
    padding-right: $px15;
    margin-right: $px-15;

    .swiper-wrapper {
      padding-bottom: $px15;

      .swiper-slide {
        background: #fff;
        display: block;
        padding: $px18 $px15 $px18 $px202;
        width: calc(100% / 2 - 50px);
        min-height: $px106;
        margin-right: $px100;
        border: $px1 solid #eee;
        overflow: hidden;

        > a {
          text-decoration: none;
          display: block;

          .thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: $px184;
            overflow: hidden;

            > span {
              display: inline-block;
              height: 100%;
              transition: transform 0.18s;
              background-position: 50%;
              background-size: auto 100%;
              width: 100%;
            }
          }

          .cont {
            span {
              display: block;
            }

            .tags {
              font-size: $px14;
            }

            .title {
              font-weight: 500;
              margin: $px3 0 $px5 0;
            }

            .date {
              color: #767676;
            }
          }
        }

        &:hover {
          .thumbnail {
            > span {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  .control {
    position: absolute;
    right: 0;
    top: $px-42;

    > div {
      background-repeat: no-repeat;
      background-size: $px10 $px17;
      display: inline-block;
      padding: 0 $px10;
      opacity: 0.8;

      i {
        font-size: $px30;
        cursor: pointer;
      }

      &.swiper-button-disabled {
        opacity: 0.35;
        cursor: default;
      }

      &:hover:not(.swiper-button-disabled) {
        opacity: 1;
      }
    }
  }

  &.skeleton {
    .swiper-container .swiper-wrapper .swiper-slide {
      @include skeleton;
      border-color: transparent;
    }

    .control > div {
      &.prev, &.next {
        opacity: 0.35;
      }
    }
  }

  @media (min-width: 992px) {
    &[data-count="1"], &[data-count="2"] {
      .control {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    .swiper-container {
      padding-right: 0;

      .swiper-wrapper {
        overflow: auto;

        .swiper-slide {
          width: 90%;
          padding: $px8 $px12 $px8 $px142;
          margin-right: $px15;
          min-height: auto;

          a {
            .thumbnail {
              width: $px134;
            }

            .cont {
              .tags {
                font-size: $px10;
              }

              .title {
                font-size: $px14;
                margin-bottom: $px3;
              }
            }
          }
        }
      }
    }

    .control {
      display: none;
    }
  }
}
</style>