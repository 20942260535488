<template>
  <div class="guide common">
    <div class="container">
      <h1 class="page-top">
        <div class="title">가이드</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <div class="box-guide" id="comCro">
              <h2 class="tit_box">크라우드펀딩이란?</h2>
              <p class="txt_desc">
                자금을 필요로 하는 개인이나 기업, 단체를 위해 불특정
                다수(Crowd)가 온라인을 통해 자금을 모으는 활동(Funding)을
                일컫습니다.<br/>
                누구나 아이디어를 내어 크라우드펀딩을 진행해볼 수 있고,
                아이디어에 공감하는 후원자들을 통해 아이디어를 발전시킬 자금도
                모을 수 있답니다.
              </p>
              <span class="wrap_img"
              ><img
                  src="/assets/img/page.guide.common.jpg"
                  alt="1.프로젝트 진행자 프로젝트 게시.2크라우드펀딩 플랫폼 프로젝트 홍보, 기업정보 소개. 3다수의 후원자(투자자) 펀딩참여. 4프로젝트 진행자 투자자에게 보상.(보상형:제품 및 서비스/증권형:이자,배당금,지분)"
                  class="img_rwd"
              /></span>
            </div>
            <div class="box-guide" id="comJoi">
              <h2 class="tit_box">회원가입</h2>
              <ol class="list_guide">
                <li>
                  <h3 class="tit_list">1) 회원가입</h3>
                  <p class="txt_guide">
                    사이트 우측 상단 '회원가입' 클릭<br/><br/>일반회원/개인사업자:
                    개인 및 사업자등록번호가 있는 사업자<br/>법인회원:
                    사업자등록번호와 법인등록번호가 있는 사업자
                  </p>
                </li>
                <li>
                  <h3 class="tit_list">2) 이메일 인증</h3>
                  <p class="txt_guide">
                    사용하고 있는 이메일 주소를 입력한 뒤 '인증메일' 전송을
                    클릭. 입력하신 이메일 주소에서 인증 번호 6자리를 확인 후
                    www.ohmycompany.com 인증대기 화면에서 인증 번호를 입력 후
                    '인증하기' 버튼을 누르세요. <br/>*SNS 회원가입 및 로그인 시
                    별도의 이메일 인증단계를 거치지 않습니다.
                  </p>
                </li>
                <li>
                  <h3 class="tit_list">3) 기본정보입력</h3>
                  <p class="txt_guide">
                    회원가입에 필요한 이름, 비밀번호, 휴대폰번호를 입력하세요.
                    사업자일 경우, 사업자등록번호 혹은 법인등록번호를 모두
                    입력하세요.
                  </p>
                </li>
                <li>
                  <h3 class="tit_list">4) 가입완료 및 로그인</h3>
                  <p class="txt_guide">
                    회원가입한 계정으로 로그인 후 오마이컴퍼니 후원형 서비스를
                    이용하실 수 있습니다. 증권형 서비스를 이용하시려면 투자자
                    인증을 진행하세요.
                  </p>
                </li>
                <li>
                  <h3 class="tit_list">5) 휴면계정 해제</h3>
                  <p class="txt_guide">
                    최근 로그인 시점 기준으로 1년 이상 오마이컴퍼니 서비스를
                    이용하지 않은 회원님은 휴면계정으로 처리됩니다. 해제를
                    원하시는 경우 오마이컴퍼니에 로그인해주시면
                    '자동해제'됩니다.
                  </p>
                </li>
              </ol>
            </div>
            <div class="box-guide faq_cont" id="comFaq">
              <h2 class="tit_box">FAQ</h2>
              <ul class="list_accor">
                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 크라우드펀딩 진행자,
                    후원자(투자자)는 무엇인가요?</em
                  >
                  <div class="cont_accor">
                    <p>
                      -크라우드펀딩 진행자(진행자)는 구체화된 아이디어를
                      프로젝트를 만들어 소개합니다. 아이디어가 실현될 수 있도록
                      적극적인 프로젝트 홍보 활동과 더불어 후원자들과 지속적인
                      관계 맺을 수 있습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      -프로젝트 후원자는 다양한 프로젝트의 혁신적인 제품과
                      서비스를 미리 접하고 아이디어가 실현되는 과정을 직접
                      경험할 수 있습니다.
                    </p>
                  </div>
                </li>

                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 후원형과 증권형
                    크라우드펀딩은 어떤 점이 다른 건가요?</em
                  >
                  <div class="cont_accor">
                    <p>
                      -후원형은 개인, 기업 누구나 할 수 있고 리워드는 제품과
                      서비스로 제공합니다. 증권형은 업력7년 미만의 비상장
                      주식회사의 경우 프로젝트 개설이 가능하며 채권, 주식 등으로
                      발행하게 됩니다.
                    </p>
                  </div>
                </li>

                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 후원형의 진행방식은
                    어떤 것이 있고, 어떤 차이가 있나요?
                  </em>
                  <div class="cont_accor">
                    <p>
                      <strong
                      >후원형 크라우드펀딩은 무조건리워드(Keep it all),
                        성공해야 리워드 (all or nothig)방식으로 나뉠 수
                        있습니다.</strong
                      >
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      -무조건리워드(keep it all) 방식은 목표금액에 도달하지
                      못해도 모인 금액만큼 돈을 받고 후원자는 예정된 리워드를
                      받을 수 있습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      -반면, 성공해야 리워드(All or nothing)방식은 목표금액이
                      100% 달성되어야만 프로젝트 참여 결제, 리워드가 제공됩니다.
                      (성공해야 리워드 결제 방식은 카드결제만 가능) 프로젝트
                      실패 시 결제 자동 취소, 리워드 제공 불가합니다.
                    </p>
                  </div>
                </li>

                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 마이페이지에서 무엇을
                    확인할 수 있나요?</em
                  >
                  <div class="cont_accor">
                    <p>
                      <strong>1) 계정정보</strong> 나의 개인정보(프로필, 주소,
                      연락처 등)를 확인, 변경이 가능합니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>2) 지불정보</strong> 후원형 프로젝트 참여 결제를
                      위한 카드번호 등록, 수정, 삭제가 가능합니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>3)투자자인증</strong> 증권형 프로젝트 투자를
                      위해서는 비대면실명인증이 필요합니다. 투자자인증 및 투자자
                      유형변경의 서비스를 이용 가능합니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>4) 참여 프로젝트</strong> 후원형, 증권형, 모의투자
                      등 나의 프로젝트 참여/투자 현황 확인과 결제취소가
                      가능합니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>5)개설 프로젝트</strong> 프로젝트 신청 및 개설한
                      프로젝트의 확인 및 수정과 진행자 전용 페이지로 이동이
                      가능합니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>6)관심 프로젝트</strong> 프로젝트에 후원하기 전
                      관심 있는 프로젝트 선택하여 모아 볼 수 있습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>7)포인트 사용</strong> 오마이컴퍼니에서 발급받은
                      포인트 번호를 등록해 후원형 프로젝트 결제 시 현금처럼
                      사용할 수 있습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>8)관리자에게 문의하기</strong> 오마이컴퍼니 사이트
                      이용, 결제, 프로젝트 개설 및 참여에 대한 문의를 관리자에게
                      보낼 수 있습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>9)Activity&nbsp;</strong>오마이컴퍼니에서 활동했던
                      기록을 모아 수치로 확인 가능합니다.
                    </p>
                  </div>
                </li>

                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 회원을 탈퇴하고
                    싶어요.</em
                  >
                  <div class="cont_accor">
                    <p>
                      -마이페이지 ▶ 계정 정보 ▶ 회원탈퇴에서 아이디와 비밀번호
                      입력 후 탈퇴 가능합니다. 단, 참여한 프로젝트가 종료
                      전이라면 탈퇴하실 수 없습니다.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      -네이버와 페이스북, 카카오톡 계정으로 회원가입이 되어 있는
                      경우, '관리자에게 문의하기'에서 탈퇴 신청이 가능합니다.
                    </p>
                  </div>
                </li>

                <li>
                  <!-- 활성화 클래스 : down  -->
                  <em class="tit_accor"
                  ><span class="txt_cate">[공통]</span> 기타 문의</em
                  >
                  <div class="cont_accor">
                    <p>
                      기타 문의사항이 있으시면 메일) omc@ohmycompany.com / 전화)
                      02-388-2556 로 문의해주세요.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageGuideCommon";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Lnb,
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      page: "guest",
    });

    const open = (page) => {
      state.page = page;
    };

    return {component, state, open};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.guide";
</style>