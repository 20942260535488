import mobileDetector from "ismobilejs";
import {Base64} from "js-base64";

const lib = {
  isBase64String(str) {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str);
  },
  isValidMobileNum(val) {
    if (val) {
      const value = val.toString();
      const minLen = value.startsWith("010") ? 11 : 10;

      if (value.length >= minLen && value.length <= 11) {
        // const pattern = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        const pattern = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
        return pattern.test(val);
      }
    }

    return false;
  },
  isValidPhoneNum(val) {
    // const pattern = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/;
    const pattern = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
    return pattern.test(val);
  },
  isValidUrl(val) {
    const pattern = new RegExp("^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$", "i");

    return !!pattern.test(val);
  },
  isValidEmail(val) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(String(val).toLowerCase());
  },
  isNumeric(val) {
    if (typeof val === "number") {
      return true;
    } else {
      return !isNaN(val) && !isNaN(parseFloat(val));
    }
  },
  isPreviewPage() {
    return window.location.pathname.includes("/preview");
  },
  isAlphaNumeric(val) {
    const pattern = /^[a-z0-9]+$/i;
    return pattern.test(val);
  },
  isMobile() {
    return mobileDetector().any || this.isAndroidMobile() || this.isOmcApp();
  },
  isOmcApp() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("ohmycompanyapp") > -1;
  },
  isNaverApp() {
    return this.isMobile() && /naver/.test(navigator.userAgent?.toLowerCase());
  },
  isKakaoInAppBrowser() {
    return this.isMobile() && /kakaotalk/.test(navigator.userAgent?.toLowerCase());
  },
  isInstagramInAppBrowser() {
    return this.isMobile() && /instagram/.test(navigator.userAgent?.toLowerCase());
  },
  isFacebookInAppBrowser() {
    return this.isMobile()
        && (/FBAN/.test(navigator.userAgent?.toLowerCase()) || /FBAV/.test(navigator.userAgent?.toLowerCase()));
  },
  isAppleMobile() {
    return mobileDetector().apple.device;
  },
  isAndroidMobile() {
    return mobileDetector().android.device || /armeabi-v7a/.test(navigator.userAgent) || /arm64-v8a/.test(
        navigator.userAgent);
  },
  isSamsungBrowser() {
    return this.isMobile() && /samsung/.test(navigator.userAgent?.toLowerCase());
  },
  isClimbable() {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    return scrollTop > window.innerHeight / 4 && !this.isNaverApp() && !this.isSamsungBrowser();
  },
  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  },
  isUrl(str) {
    if (!str) {
      return false;
    }

    return ["https://", "http://"].some(u => str.startsWith(u));
  },
  isEndsWithConsonant(word) {
    if (word) {
      const lastWord = word.charCodeAt(word.length - 1);
      return !!((lastWord - 44032) % 28);
    }

    return false;
  },
  getFirstNumberFromString(str) {
    return window.Number(str.match(/\d+/)[0]);
  },
  hasEmoji(str) {
    try {
      const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
      return regex.test(str);
    } catch (e) {
      return false;
    }
  },
  getRandomNum(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  getBase64Encoded(str) {
    if (!str) {
      return null;
    }

    return Base64.encode(str);
  },
  getBase64UrlSafe(str) {
    if (!str) {
      return null;
    }

    return this.getBase64Encoded(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  },
  getBase64UriComponentEncoded(str) {
    if (!str) {
      return null;
    }

    return window.encodeURIComponent(this.getBase64Encoded(str));
  },
  getBase64Decoded(str) {
    if (this.isBase64String(str)) {
      return Base64.decode(str);
    }

    return null;
  },
  getEncodedString(obj) {
    return encodeURIComponent(this.getBase64Encoded(JSON.stringify(obj)));
  },
  getDecodedObject(str) {
    if (str) {
      try {
        return JSON.parse(this.getBase64Decoded(decodeURIComponent(str)));
      } catch (e) {
        console.warn(e);
      }
    }

    return null;
  },
  getZeroAddedNum(num) {
    return num >= 0 && num < 10 ? "0" + num : num.toString();
  },
  getQueryParameters(queryString) {
    const obj = {};
    const queArr = queryString.split("?");

    if (queArr.length > 1) {
      const ampArr = queArr[1].split("&");
      for (let i in ampArr) {
        const eqlArr = ampArr[i].split("=");
        obj[eqlArr[0]] = eqlArr.length > 1 ? eqlArr[1] : "";
      }
    }

    return obj;
  },
  getQueryString(obj) {
    const resultArr = [];

    if (Object.keys(obj).length) {
      const arr = [];
      resultArr.push("?");

      for (let i in obj) {
        arr.push(`${i}=${obj[i] || ""}`);
      }

      resultArr.push(arr.join("&"));
    }

    return resultArr.join("");
  },
  getSafeJsString(val) {
    if (!val) {
      return val;
    }

    return val.replaceAll("\"", "\\\"").replaceAll("\r", "\\r").replaceAll("\n", "\\n").trim();
  },
  getCamelCase(str) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""));
  },
  getPascalCase(str) {
    const camel = this.getCamelCase(str);
    return camel[0].toUpperCase() + camel.substring(1);
  },
  getRenewed(val) {
    if (val) {
      return JSON.parse(JSON.stringify(val));
    }

    return val;
  },
  getStringFromObj(obj) {
    const result = [];

    if (Array.isArray(obj)) {
      for (let i in obj) {
        result.push(obj[i]);
      }
    } else {
      for (let i in obj) {
        result.push(`${i}: ${obj[i]}`);
      }
    }

    return result.join(", ");
  },
  getMbToByte(mb) {
    if (!mb || !this.isNumeric(mb)) {
      return 0;
    }

    return mb * 1024 * 1024;
  },
  getFileExtension: (fileName) => {
    if (fileName) {
      const fileNameArr = fileName.trim().split(".");
      return fileNameArr[fileNameArr.length - 1]?.toLowerCase();
    }

    return "";
  },
  getParentNodes: (elem) => {
    const result = [];
    const maxCnt = 1000;

    for (let i = 0; i < maxCnt; i += 1) {
      if (elem.parentNode) {
        elem = elem.parentNode;
        result.push(elem);
        continue;
      }

      break;
    }

    return result;
  },
  getDate(val) {
    if (val.length >= 19) {
      const str = val.substr(0, 19);
      const dateArr = str.split(" ");

      if (dateArr.length === 2) {
        const dates = dateArr[0].split("-");
        const times = dateArr[1].split(":");

        if (dates.length === 3 && times.length === 3) {
          return new Date(
              window.Number(dates[0]), window.Number(dates[1]) - 1, window.Number(dates[2]),
              window.Number(times[0]), window.Number(times[1]), window.Number(times[2])
          );
        }
      }
    } else if (val.length >= 10) {
      const dates = val.substr(0, 10).split("-");

      if (dates.length >= 3) {
        return new Date(Number(dates[0]), Number(dates[1]) - 1, Number(dates[2]));
      }
    }

    return val;
  },
  getBlobFromBase64(value) {
    const splitDataURI = value.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  },
  getDevice() {
    return this.isMobile() ? "mobile" : "desktop";
  },
  getBrowser() {
    const navAgt = navigator.userAgent;

    if (this.isSamsungBrowser()) {
      return "samsung";
    } else if (this.isNaverApp()) {
      return "naver";
    } else if (this.isKakaoInAppBrowser()) {
      return "kakao";
    } else if (this.isInstagramInAppBrowser()) {
      return "instagram";
    } else if (this.isFacebookInAppBrowser()) {
      return "facebook";
    } else if (navAgt.indexOf("Opera") !== -1) {
      return "opera";
    } else if (window.document.documentMode) {
      return "ie";
    } else if (navAgt.indexOf("Chrome") !== -1) {
      return "chrome";
    } else if (navAgt.indexOf("Safari") !== -1) {
      return "safari";
    } else if (navAgt.indexOf("Firefox") !== -1) {
      return "firefox";
    }

    return "unknown";
  },
  getOsName() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;

    if (userAgent.indexOf("Windows NT") > -1 || ["Win32", "Win64", "Windows", "WinCE"].indexOf(platform) > -1) {
      return "windows";
    } else if (["Macintosh", "MacIntel", "MacPPC", "Mac68K"].indexOf(platform) > -1) {
      return "mac";
    } else if (this.isAppleMobile()) {
      return "ios";
    } else if (this.isAndroidMobile()) {
      return "android";
    } else if (/Linux/.test(userAgent)) {
      return "linux";
    }

    return "unknown";
  },
  getSelfAndParents(elem, arr = []) {
    if (elem && elem instanceof HTMLElement) {
      arr.push(elem);
      this.getSelfAndParents(elem.parentNode, arr);
    }

    return arr;
  },
  getElementById(elems, id) {
    if (Array.isArray(elems)) {
      for (let i in elems) {
        if (elems[i].id === id) {
          return elems[i];
        }
      }
    }
  },
  getDateFormat(val, format) {
    if (val) {
      if (typeof val === "string" && val.trim()) {
        if (!val.includes("-")) {
          switch (val.length) {
            case 8:
              switch (format) {
                case "yyyyMMdd":
                  return [val.substring(0, 4), val.substring(4, 6), val.substring(6, 8)].join("");

                case "yyyy-MM-dd":
                  return [val.substring(0, 4), val.substring(4, 6), val.substring(6, 8)].join("-");

                case "yyyy년 MM월 dd일":
                  return `${val.substring(0, 4)}년 ${val.substring(4, 6)}월 ${val.substring(6, 8)}일`;

                case "yy.MM.dd":
                default:
                  return [val.substring(2, 4), val.substring(4, 6), val.substring(6, 8)].join(".");
              }
          }
        } else if (val.length >= 10) {
          switch (format) {
            case "yyyy-MM-dd":
              return [val.substring(0, 4), val.substring(5, 7), val.substring(8, 10)].join("-");
          }
        }
      } else if (typeof val === "number") {
        return this.getDateFormat(new Date(val));
      } else if (typeof val === "object" && typeof val.getMonth === "function") {
        const year = val.getFullYear();
        const month = val.getMonth() + 1;
        const date = val.getDate();

        switch (format) {
          case "yyyyMMdd": {
            return [
              year,
              this.getZeroAddedNum(month),
              this.getZeroAddedNum(date)
            ].join("");
          }

          case "yyyy-MM-dd": {
            return [
              year,
              this.getZeroAddedNum(month),
              this.getZeroAddedNum(date)
            ].join("-");
          }

          case "yyyy년 MM월 dd일":
            return `${year}년 ${month}월 ${date}일`;

          case "yyMMddHHmmss": {
            const year = val.getFullYear().toString().substring(2, 4);
            const hours = val.getHours();
            const minutes = val.getMinutes();
            const seconds = val.getSeconds();

            return [
              this.getZeroAddedNum(year),
              this.getZeroAddedNum(month),
              this.getZeroAddedNum(date),
              this.getZeroAddedNum(hours),
              this.getZeroAddedNum(minutes),
              this.getZeroAddedNum(seconds)
            ].join("");
          }

          case "yyyyMMddHHmmss": {
            const hours = val.getHours();
            const minutes = val.getMinutes();
            const seconds = val.getSeconds();

            return [
              val.getFullYear(),
              this.getZeroAddedNum(month),
              this.getZeroAddedNum(date),
              this.getZeroAddedNum(hours),
              this.getZeroAddedNum(minutes),
              this.getZeroAddedNum(seconds)
            ].join("");
          }

          case "yyyy-MM-dd HH:mm:ss": {
            const hours = val.getHours();
            const minutes = val.getMinutes();
            const seconds = val.getSeconds();

            return [
              [val.getFullYear(),
                this.getZeroAddedNum(month),
                this.getZeroAddedNum(date)
              ].join("-"),
              " ",
              [this.getZeroAddedNum(hours),
                this.getZeroAddedNum(minutes),
                this.getZeroAddedNum(seconds)
              ].join(":")
            ].join("");
          }
        }

        return val.toISOString().split("T")[0];
      }
    }

    return val;
  },
  getBytes(txt) {
    let byteLength = 0;

    for (let i = 0; i < txt.length; i++) {
      const oneChar = escape(txt.charAt(i));

      if (oneChar.length == 1) {
        byteLength++;
      } else if (oneChar.indexOf("%u") != -1) {
        byteLength += 2;
      } else if (oneChar.indexOf("%") != -1) {
        byteLength += oneChar.length / 3;
      }
    }

    return byteLength;
  },
  getNumberWithPadding(val) {
    return (val < 10 ? "0" : "") + val;
  },
  getNumbered(val, positive) {
    if (val === "") {
      return 0;
    }

    const num = window.Number(val);

    if (window.isNaN(num)) {
      return 0;
    }

    // 양수 조건
    return positive ? num || 0 : num;
  },
  getTimeFormat(seconds) {
    if (!seconds) {
      return seconds;
    }

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    if (seconds < minute) {
      return seconds + "초";
    } else if (seconds < hour) {
      const m = Math.floor(seconds / minute);
      const s = seconds % minute;
      return s ? [m, "분", " ", s, "초"].join("") : m + "분";
    } else if (seconds < day) {
      const h = Math.floor(seconds / hour);
      const m = Math.floor(seconds % hour / minute);
      return m ? [h, "시간", " ", m, "분"].join("") : h + "시간";
    }

    const d = Math.floor(seconds / day);
    const h = Math.floor(seconds % day / hour);
    return h ? [d, "일", " ", h, "시간"].join("") : d + "일";
  },
  getNumberFormat(val) {
    if (!val) {
      return val;
    }

    return Math.floor(val).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  },
  getUrlToAnchorReplaced(val) {
    if (!val) {
      return val;
    }

    const regex = /((http|https):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/gi;
    return val.replace(regex, `<a href="$1" target="_blank" rel="noopener">$1</a>`);
  },
  getPhoneNumberFormat(val, dash) {
    if (!val) {
      return val;
    }

    val = val.replace(/\D/g, ""); // 숫자가 아니면 삭제

    if (dash) {
      if (val.length === 8) {
        val = val.substring(0, 4) + "-" + val.substring(4);
      } else {
        val = val.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
      }
    } else {
      val = val.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1$2$3");
    }

    return val;
  },
  getSplit(val, chars) {
    if (val) {
      if (Array.isArray(chars) && chars.length) {
        let str = "";

        for (let c of chars) {
          str = val.split(c).join(chars[0]);
        }

        return str.split(chars[0]);
      }

      return [val];
    }

    return [];
  },
  getHeaderHeight(includeMobile) {
    if (!includeMobile && window.innerWidth <= 991) {
      return 0;
    }

    return document.querySelector("header")?.offsetHeight || 0;
  },
  getComputedStyle($elem, key) {
    const style = window.getComputedStyle($elem);

    if (style) {
      return style[key];
    }

    return null;
  },
  scrollSmoothly(elem, newScrollTop) {
    if (typeof elem.scroll !== "function") {
      elem.scrollTop = newScrollTop;
      return;
    }

    const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const gap = Math.abs(newScrollTop - curScrollTop);
    const cycleValue = gap / 10;
    let scrollTop = curScrollTop;

    const move = () => {
      if (newScrollTop > curScrollTop) {
        scrollTop = scrollTop > newScrollTop ? newScrollTop : scrollTop + cycleValue;

        if (scrollTop > newScrollTop) {
          scrollTop = newScrollTop;
        }
      } else {
        scrollTop = scrollTop < newScrollTop ? newScrollTop : scrollTop - cycleValue;

        if (scrollTop < 0) {
          scrollTop = 0;
        }
      }

      elem.scroll(0, scrollTop);

      if (scrollTop === newScrollTop) {
        return cancelAnimationFrame(move);
      }

      requestAnimationFrame(move);
    };

    requestAnimationFrame(move);
  },
  getHumanReadableDate(date) {
    if (!date || date.startsWith("0000")) {
      return date;
    }

    const createDate = lib.getDate(date);
    const minutes = Math.ceil((((new Date() - createDate) / 1000) / 60));

    if (minutes < 3) {
      return "방금 전";
    } else if (minutes < 60) {
      return minutes + "분 전";
    } else if (minutes < 60 * 24) {
      return Math.ceil(minutes / 60) + "시간 전";
    } else if (minutes < 60 * 24 * 7) {
      return Math.ceil((minutes / 60) / 24) + "일 전";
    } else if (minutes < 60 * 24 * 28) {
      return Math.ceil(((minutes / 60) / 24) / 7) + "주 전";
    }

    return date;
  },
  shuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
  },
  setImageMap($img) {
    if (!$img) {
      return;
    }

    $img.onload = () => {
      const mapName = $img.getAttribute("usemap")?.replace("#", "");

      if (!mapName) {
        return;
      }

      const $map = document.querySelector(`map[name=${mapName}]`);
      const $area = $map?.querySelectorAll("area");

      if (!$map || !$area.length) {
        return;
      }

      const coords = [];

      for (let i = 0; i < $area.length; i += 1) {
        coords.push($area[i].getAttribute("coords")?.split(",").map(c => window.Number(c.trim())));
      }

      const sizes = {
        naturalWidth: $img.naturalWidth,
        renderWidth: $img.width,
      };

      const ratio = sizes.renderWidth / sizes.naturalWidth;

      if (ratio === 1) {
        return;
      }

      for (let i in coords) {
        if (coords[i].length !== 4) {
          return;
        }
        for (let j in coords[i]) {
          coords[i][j] = Math.round(coords[i][j] * ratio);
        }
      }

      for (let i = 0; i < $area.length; i += 1) {
        $area[i].setAttribute("coords", coords[i].join(","));
      }
    };
  },
  setBodyScroll() {
    const $modals = document.querySelectorAll("#app .modals .modal");
    document.body.style.removeProperty("overflow");

    if ($modals.length) {
      const lastModal = $modals[$modals.length - 1];
      const hasScroll1 = lastModal.scrollHeight > lastModal.clientHeight;
      const hasScroll2 = document.body.offsetHeight > window.innerHeight;

      if (hasScroll1 && hasScroll2) {
        document.body.style.overflow = "hidden";
      }
    }
  },
  copyToClipboard(value) {
    if (!value) {
      console.error("Empty value.");
      return;
    }

    const elem = document.createElement("input");
    elem.type = "text";
    elem.value = value;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    elem.remove();
  },
  cookie: {
    get(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == " ") {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }

      return null;
    },
    set(name, value, seconds) {
      let expires = "";

      if (seconds) {
        const date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        expires = "; expires=" + date.toUTCString();
      }

      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    remove(name) {
      document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  },
  loadImage(src, callback) {
    let newImg = new Image();

    newImg.onload = function () {
      callback(newImg.width, newImg.height);
      newImg = null;
    };

    newImg.src = src;
  },
  replaceAll(str, word1, word2) {
    if (str) {
      str = str.split(word1).join(word2);
    }

    return str;
  }
};

export default lib;