<template>
  <div class="detail-project">
    <ul class="no-scrollbar tight clearfix row" v-if="!state.project.loaded || (state.project.loaded && state.project.list.length > 0)">
      <li v-for="(p, idx) in state.project.list" :key="idx" class="col-12 col-lg-4 item" :id="`${component.name}Item`">
        <Card
            :amount="p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt"
            :builderName="p.makerName || p.builderName"
            :builderSeq="p.makerSeq"
            :builderImageUrl="p.makerImageUrl"
            :count="p.investCount"
            :dday="Number(p.dday)"
            :expenseAmt="p.expenseAmt"
            :fundingType="p.fundingType"
            :interest="true"
            :link="p.link"
            :progressOrder="Number(p.progressOrder)"
            :projectName="p.projectName"
            :projectType="p.projectType"
            :projectSeq="p.projectSeq"
            :percent="p.percent"
            :skeleton="p.skeleton ? p.skeleton : !state.project.loaded"
            :successFailName="p.successFailName"
            :simpleText="p.simpleText"
            :thumbFilePath="p.thumbFileUrl"
            :mainImagePosition="p.mainImagePosition"
        />
      </li>
    </ul>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Card from "@/components/Card";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageChannelDetailProject";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, Card, Anchor, MemberPicture},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      project: {
        loaded: false,
        more: false,
        paginationInfo: null,
        list: [],
      },
      args: {
        pageIndex: 1,
        pageUnit: 9,
        pageSize: 5,
      },
      temp: ""
    });

    const load = (more) => {
      if (!more) {
        state.args.pageIndex = 1;
        state.project.loaded = false;
      } else {
        state.args.pageIndex += 1;
        state.project.more = true;
      }

      for (let i = 0; i < 3; i += 1) {
        state.project.list.push({
          projectName: "Wait",
          builderName: "Wait a moment",
          projectCate: "Wait",
          simpleText: "Please wait a moment",
          fundingType: "A",
          skeleton: true,
          progressOrder: 1,
          count: 100,
          amount: 100,
          percent: 10,
        });
      }

      http.get(`/api/channel/${router.app.$route.params.makerSeq}/projects`, state.args).then(({data}) => {
        state.project.paginationInfo = data.body.paginationInfo;
        state.project.loaded = true;

        if (more) {
          for (let i in data.body.list) {
            state.project.list = state.project.list.filter(i => !i.skeleton);
            state.project.list.push(data.body.list[i]);
          }
          return state.project.more = false;
        }

        state.project.list = data.body.list;
      });
    };

    const onScroll = () => {
      if (!state.project.loaded || state.args.pageIndex >= state.project.paginationInfo.lastPageNo || state.project.more) {
        return;
      }

      const firstItemHeight = document.getElementById(`${component.name}Item`).offsetHeight;

      firstItemHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.detail-project {
  margin: $px20 0;

  ul {
    margin: 0 $px-10;

    > .item {
      padding: 0 $px10 $px20 $px10;
    }
  }
}
</style>