import axios from "axios";
import caches from "@/scripts/caches";
import definitions from "@/scripts/definitions";
import interceptor from "@/scripts/interceptor";

const getUrl = (url) => url.startsWith("/api") ? `/${definitions.apiVersion}${url}` : url;

const httpAxios = axios.create();
httpAxios.interceptors.request.use(interceptor.request.onRequest, interceptor.request.onRejected);
httpAxios.interceptors.response.use(interceptor.response.onFulfilled, interceptor.response.onRejected);

const http = {
  get(url, args, options) {
    const urlPrefixed = getUrl(url);
    const config = {
      headers: {}
    };

    if (args) {
      config.params = args;
    }

    if (options?.cache) {
      const httpCache = caches.getHttp(urlPrefixed, args);

      if (httpCache) {
        return new Promise((resolve => {
          resolve(httpCache.res);
        }));
      }

      config.headers["omc-cache-time"] = definitions.caches.time.http;
    }

    if (options?.message === false) {
      config.headers["omc-prevent-message"] = true;
    }

    if (options?.loading) {
      config.headers["omc-loading"] = true;
    }

    if (options?.authorization) {
      config.headers["Authorization"] = options.authorization;
    }

    if (options?.responseType) {
      config.responseType = options.responseType;
    }

    return httpAxios.get(urlPrefixed, config);
  },
  post(url, args, options) {
    const urlPrefixed = getUrl(url);
    const config = {
      headers: {}
    };

    if (options?.headers) {
      config.headers = options.headers;
    } else if (options?.message === false) {
      config.headers["omc-prevent-message"] = true;
    }

    return httpAxios.post(urlPrefixed, args, config);
  },
  put(url, args, options) {
    const urlPrefixed = getUrl(url);
    const config = {
      headers: {}
    };

    if (options?.message === false) {
      config.headers["omc-prevent-message"] = true;
    }

    return httpAxios.put(urlPrefixed, args, config);
  },
  patch(url, args, options) {
    const urlPrefixed = getUrl(url);
    const config = {
      headers: {}
    };

    if (options?.message === false) {
      config.headers["omc-prevent-message"] = true;
    }

    return httpAxios.patch(urlPrefixed, args, config);
  },
  delete(url, args, options) {
    const urlPrefixed = getUrl(url);
    const config = {
      headers: {}
    };

    if (args) {
      config.params = args;
    }

    if (options?.message === false) {
      config.headers["omc-prevent-message"] = true;
    }

    return httpAxios.delete(urlPrefixed, config);
  },
};

export default http;