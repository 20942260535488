<template>
  <div class="slides swiper" :id="`${component.name}Slides`">
    <div class="top">
      <div class="title ellipsis" v-if="$route.query.title">
        <a class="pointer back" title="닫기" @click="close()">
          <span class="img" style="background-image: url(/assets/ico/common.back.white.svg)"></span>
        </a>
        <a :href="$route.query.url" title="해당 프로젝트로 이동">{{ decodeURIComponent($route.query.title) }}</a>
      </div>
      <div class="pagination" v-if="state.curIndex && state.images.length">
        <span>{{ state.curIndex }}</span>
        <span>&nbsp;/&nbsp;</span>
        <span>{{ state.images.length }}</span>
      </div>
    </div>
    <div class="swiper-wrapper" :id="`${component.name}SlideWrapper`">
      <div class="swiper-slide" v-for="(i, idx) in state.images" :key="idx">
        <div class="wrapper">
          <img :src="i" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pagesEtcSlides";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      changeViewport();

      for (let i in router.app.$route.query) {
        if (i.includes("image")) {
          state.images.push(router.app.$route.query[i]);
        }
      }
    });

    const state = reactive({
      loaded: false,
      swiper: null,
      images: [],
      curIndex: null,
    });

    const close = () => {
      window.close();
    };

    const changeViewport = () => {
      const $viewport = document.querySelector("meta[name=viewport]");
      $viewport?.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes");
    };

    const instantiate = () => {
      state.swiper = new window.Swiper(`#${component.name}Slides`, {
        initialSlide: window.Number(router.app.$route.query.idx),
        on: {
          init: () => {
            state.curIndex = window.Number(router.app.$route.query.idx) + 1;
          },
          slideChange: () => {
            state.curIndex = state.swiper?.realIndex + 1;
          }
        }
      });
    };

    onMounted(() => {
      state.images.length && nextTick(() => {
        instantiate();
      });
    });

    return {component, state, close,};
  }
});
</script>

<style lang="scss" scoped>
.slides::v-deep {
  background: #333;
  display: flex;
  flex-direction: column;
  font-size: $px14;
  overflow: hidden;
  height: 100vh;

  .top {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $px16 $px15;
    width: 100%;
    white-space: nowrap;


    .title {
      position: relative;
      font-weight: 300;

      a {
        text-decoration: none;
        padding: $px15;
        margin: $px-15;

        &.back {
          > .img {
            width: $px13;
            height: $px13;
            margin-right: $px10;
          }
        }
      }
    }

    .pagination {
      padding-left: $px9;
      font-size: $px12;
    }
  }

  .swiper-wrapper {
    align-items: center;

    .swiper-slide {
      height: auto;

      > .wrapper {
        max-height: calc(100vh - $px41);
        overflow: auto;

        img {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
</style>