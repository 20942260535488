<template>
  <div class="guide">
    <div class="container">
      <h1 class="page-top">
        <div class="title">가이드</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <div class="faq-panel">
              <h3 class="tit-faq">TOP5 질문</h3>
              <div class="faq-guest" :class="{ on: state.page === 'guest' }">
                <h4 class="tit-target">
                  <a class="pointer font-xs no-underline" @click="open('guest')">펀딩 후원자</a>
                </h4>
                <div>
                  <ul class="list-top5 font-sm">
                    <li>
                      <span class="support">후원형</span>
                      <a href="/guide/guest/reward/12#gstRewFaq12" class="link-cont">리워드는 언제 배송되나요?</a>
                    </li>
                    <li>
                      <span class="support">후원형</span>
                      <a href="/guide/guest/reward/13#gstRewFaq13" class="link-cont">리워드를 변경하고 싶어요.</a>
                    </li>
                    <li>
                      <span class="support">후원형</span>
                      <a href="/guide/guest/reward/14#gstRewFaq14" class="link-cont">참여 및 결제한 영수증이 필요합니다.</a>
                    </li>
                    <li>
                      <span class="stock">증권형</span>
                      <a href="/guide/guest/invest/46#gstInvFaq46" class="link-cont">투자기간이 끝난 후에는 어떻게 진행되나요?</a>
                    </li>
                    <li>
                      <span class="stock">증권형</span>
                      <a href="/guide/guest/invest/44#gstInvFaq44" class="link-cont">증권이 제대로 들어왔는지는 어떻게 확인할 수 있나요?</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="faq-host" :class="{ on: state.page === 'host' }">
                <h4 class="tit-target">
                  <a class="pointer font-xs no-underline" @click="open('host')">펀딩 진행자</a>
                </h4>
                <div>
                  <ul class="list-top5 font-sm">
                    <li>
                      <span class="support">후원형</span>
                      <a href="/guide/maker/reward/19#mkrRewFaq19" class="link-cont">약속된 기간 내 리워드 배송을 하지 못한다면 어떻게 해야 하나요?</a>
                    </li>
                    <li>
                      <span class="support">후원형</span>
                      <a href="/guide/maker/reward/20#mkrRewFaq20" class="link-cont">정산받는 금액의 회계처리는 어떻게 해야 하나요?</a>
                    </li>
                    <li>
                      <span class="stock">증권형</span>
                      <a href="/guide/maker/invest/59#mkrInvFaq59" class="link-cont">온라인 IR 자료는 어떻게 작성해야 하나요?</a>
                    </li>
                    <li>
                      <span class="stock">증권형</span>
                      <a href="/guide/maker/invest/60#mkrInvFaq60" class="link-cont">펀딩 진행중에 온라인 IR을 수정할 수 있나요?</a>
                    </li>
                    <li>
                      <span class="stock">증권형</span>
                      <a href="/guide/maker/invest/61#mkrInvFaq61" class="link-cont">펀딩 종료된 후 절차는 어떻게 되나요?</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ul class="guide-links tight">
              <li>
                <strong class="tit-common">공통<br/>이용가이드</strong>
                <a href="/guide/common#comCro" class="btn btn-secondary link-cont font-xs no-underline">자세히 보기</a>
              </li>
              <li>
                <strong class="tit-guest">펀딩 후원자<br/>이용가이드</strong>
                <a href="/guide/guest/reward#gstRewPay" class="btn btn-secondary link-support font-xs no-underline">후원형</a>
                <a href="/guide/guest/invest#gstInvCer" class="btn btn-secondary link-stock font-xs no-underline">증권형</a>
              </li>
              <li>
                <strong class="tit-host">펀딩 진행자<br/>이용가이드</strong>
                <a href="/guide/maker/reward#mkrRewApp" class="btn btn-secondary link-support font-xs no-underline">후원형</a>
                <a href="/guide/maker/invest#mkrInvApp" class="btn btn-secondary link-stock font-xs no-underline">증권형</a>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageGuideMain";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Lnb,
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      page: "guest",
    });

    const open = (page) => {
      state.page = page;
    };

    return {component, state, open};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.guide";

.faq {
  &-panel {
    position: relative;

    %label {
      display: inline-block;
      width: $px64;
      height: $px30;
      margin-right: $px8;
      font-size: $px14;
      line-height: $px30;
      color: #fff;
      text-align: center;
      vertical-align: middle;
    }

    .support {
      @extend %label;
      background-color: $colorPoint;
    }

    .stock {
      @extend %label;
      background-color: $colorPoint;
    }

    .tit-faq {
      font-weight: 400;
      font-size: $px19;
      margin: 0;
      color: #000;
      height: $px39;
    }

    .tit-target {
      position: absolute;
      top: 0;
      width: $px115;
      border: $px1 solid #ccc;
      font-weight: 400;
      font-size: $px14;
      color: #767676;
      text-align: center;

      a {
        line-height: $px39;
        height: $px39;
        display: inline-block;
        width: 100%;
        background-color: #eee;
      }
    }

    .list-top5 {
      display: none;
      border-top: $px1 solid #ddd;
      border-bottom: $px1 solid #ddd;
      background-color: #fff;

      li {
        padding: $px12 0;
        border-top: $px1 solid #eee;

        &:first-child {
          border-top: 0 none;
        }
      }

      .link-cont {
        display: inline-block;
        color: #767676;
        vertical-align: middle;
      }
    }

    .on {
      .tit-target {
        z-index: 1;
        border-color: #ddd;
        border-bottom: 0 none;

        a {
          background-color: #fff;
        }

        &:after {
          position: absolute;
          bottom: $px2;
          left: 50%;
          width: $px38;
          height: $px2;
          margin-left: $px-19;
          background-color: $colorPoint;
          content: "";
        }
      }

      .list-top5 {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  %box-faq {
    border-top: $px1 solid #ddd;
    border-bottom: $px1 solid #ddd;
    background-color: #fff;
  }

  &-guest {
    .tit-target {
      right: $px114;
    }
  }

  &-host {
    .tit-target {
      right: 0;
    }
  }
}

.guide-links {
  overflow: hidden;
  margin-top: $px70;

  li {
    float: left;
    width: calc(100% / 3 - 0.1px);
    text-align: center;
  }

  strong {
    display: block;
    padding-bottom: $px210;
    font-weight: 400;
    font-size: $px20;
    line-height: 1.2;
  }

  a {
    display: inline-block;
    padding: $px12 $px20;
    color: #fff;
    margin: 0 $px3;
  }

  .tit {
    &-common {
      background: url("/assets/img/page.guide.guest1.png") no-repeat 50% $px65;
    }

    &-guest {
      background: url("/assets/img/page.guide.guest2.png") no-repeat 50% $px65;
    }

    &-host {
      background: url("/assets/img/page.guide.guest3.png") no-repeat 50% $px65;
    }
  }
}

@media(max-width: 991px) {
  .guide-links {
    margin-top: $px50;

    li {
      width: 100%;
      margin-bottom: $px35;
    }
  }
}
</style>