<template>
  <div class="wait" :style="{width : width+'px'}">
    <div class="card">
      <div class="rect">
        <span class="img" style="background-image: url(/assets/ico/component.wait.cat.svg)"></span>
        <div class="font-lg">Wait a minute!</div>
      </div>
      <div class="card-body">
        <p class="card-text text-center">이 페이지는 <b>준비 중</b>입니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentWait";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {width: Number},
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.wait {
  max-width: $px500;
  margin: 0 auto;

  .card {
    border: $px1 solid #eee;
    box-shadow: none;

    .rect {
      border-bottom: $px1 solid #eee;
      width: 100%;
      height: $px350;
      padding: $px100 0;
      text-align: center;
      color: silver;

      .img {
        margin-bottom: $px15;
        height: $px100;
        width: $px100;
      }

      div {
        font-style: italic;
        font-weight: 500;
      }
    }
  }
}
</style>