<template>
  <div class="detail-review">
    <div class="row justify-content-between" v-if="!state.review.loaded || (state.review.loaded && state.review.list.length > 0)" :class="{view: state.view}">
      <div class="col-lg-4 left">
        <div class="stats">
          <ReviewTagBar
              :classType="'channel'"
              :url="`/api/project/maker/${$route.params.makerSeq}/review/tags`"
              :overflow="true"
          />
        </div>
      </div>
      <div class="col-lg-8">
        <ReviewCard
            v-for="(r,idx) in state.review.list" :key="idx"
            :skeleton="r.skeleton || !state.review.loaded"
            :builderSeq="$route.params.makerSeq"
            :content="r.content"
            :createDate="r.createDate"
            :delFlag="r.delFlag"
            :hideInvestorNameYn="r.hideInvestorNameYn"
            :reviewId="r.id"
            :replies="r.replies"
            :memberSeq="r.memberSeq"
            :memberName="r.memberName"
            :photos="r.photos"
            :selectedTags="r.selectedTags"
            :updateDate="r.updateDate"
            :projectName="r.projectName"
            :projectSeq="r.projectSeq"
            :id="`${component.name}Item`"
            :blindFlag="r.blindFlag"
        />
      </div>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import router from "@/scripts/router";
import ReviewCard from "@/components/ReviewCard";
import NoData from "@/components/NoData";
import ReviewTagBar from "@/components/ReviewTagBar";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageChannelDetailReview";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ReviewTagBar, NoData, ReviewCard},
  mixins: [mixin],
  props: {
    memberName: String,
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      review: {
        loaded: false,
        more: false,
        pageable: {
          page: 0,
          size: 0
        },
        total: 0,
        list: [],
      },
      args: {
        page: 0,
        size: 10,
        sort: "createDate,desc"
      },
    });

    const load = (more) => {
      for (let i = 0; i < 10; i += 1) {
        state.review.list.push({
          skeleton: true,
          content: "Wait a moment",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          selectedTags: "wait,wait,wait,wait,wait",
        });
      }

      if (!more) {
        state.review.loaded = false;
        state.args.page = 0;
      } else {
        state.review.more = true;
        state.args.page += 1;
      }

      http.get(`/api/project/maker/${router.app.$route.params.makerSeq}/reviews`, state.args).then(({data}) => {
        if (!more) {
          state.review.loaded = true;
          state.review.list = data.body.content;
          state.review.pageable = data.body.pageable;
          state.review.total = data.body.total;
        } else {
          state.review.more = false;
          state.review.list = state.review.list.filter(i => !i.skeleton);

          for (let i in data.body.content) {
            state.review.list.push(data.body.content[i]);
          }
        }
      });
    };

    const onScroll = () => {
      if (!state.review.loaded || state.args.page >= (Math.ceil(state.review.total / state.review.pageable.size) - 1) || state.review.more) {
        return;
      }

      const firstItemHeight = document.getElementById(`${component.name}Item`)?.offsetHeight;

      firstItemHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.detail-review {
  margin-top: $px30;

  > .row {
    .left {
      padding-right: $px50;
    }
  }

  @media (max-width: 991px) {
    > .row {
      .left {
        padding-right: $px15;
        margin-bottom: $px30;
      }
    }
  }
}
</style>