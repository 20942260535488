<template>
  <header :class="[...$store.state.classes.header, state.toggle ? 'opened' : '']">
    <div :id="`${component.name}Banners`" class="banners no-translate" :style="{ height: state.banner.height + 'px', marginTop: state.banner.marginTop + 'px' }" v-if="state.banner.list.length">
      <div class="handle" :class="{transition: state.banner.handle.transition }" :style="{ marginTop: state.banner.handle.marginTop + 'px' }"></div>
      <Anchor :href="b.bannerUrl" class="banner" v-for="(b, idx) in state.banner.list" :key="idx" :style="getBannerStyle(idx)">
        <img :src="b.imagePath + b.imageSaveName" v-if="b.imagePath"/>
        <span class="text">{{ b.title }}</span>
      </Anchor>
      <span class="remove" @click.prevent="remove()" :style="{ color: getBannerStyle(0).color }">&times;</span>
    </div>
    <div class="curtain"></div>
    <div class="wrapper">
      <div class="container">
        <div class="clearfix">
          <div class="brand">
            <router-link to="/" @click.native="toggle(false)">
              <img src="/assets/img/common.logo.svg" alt="로고"/>
            </router-link>
          </div>
          <!-- desktop start -->
          <nav class="nav font-md">
            <ul class="tight">
              <li>
                <router-link :class="{ 'color-point': $route.path.startsWith('/reward') }" to="/reward/list">후원하기</router-link>
              </li>
              <li>
                <router-link :class="{ 'color-point': $route.path.startsWith('/invest') }" to="/invest/list">투자하기</router-link>
              </li>
              <li>
                <router-link :class="{ 'color-point': $route.path.startsWith('/reservation') }" to="/reservation/list">오픈예정</router-link>
              </li>
              <li>
                <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">
                  <span>가이드</span>
                </a>
              </li>
            </ul>
          </nav>
          <div class="side-desktop">
            <router-link to="/apply/intro" class="btn btn-point font-sm">펀딩 신청하기</router-link>
            <div class="acts">
              <a class="admin pointer" href="/new-admin" target="_blank" alt="관리자 페이지" title="관리자 페이지" v-if=" ['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth)">
                <i class="fa fa-cog"></i>
              </a>
              <span class="search pointer" @click="$store.commit('openModal', {name: 'Search'})" alt="프로젝트 검색" title="프로젝트 검색">
                <span class="img" style="background-image: url(/assets/ico/component.header.search.svg)"></span>
              </span>
              <router-link to="/mypage" class="picture pointer no-underline text font-base" title="마이페이지" v-if="$store.state.account.loggedIn">
                <span class="img profile" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>
                <span class="bubble">My</span>
              </router-link>
              <a @click="$store.dispatch('goLoginPage')" class="login pointer font-sm" v-else-if="$store.state.account.checked">로그인</a>
            </div>
          </div>
          <!-- desktop end -->
          <!-- mobile start -->
          <div class="side-mobile">
            <div class="left clearfix">
              <template v-if="$store.state.account.loggedIn">
                <div class="my">
                  <router-link to="/mypage" class="mypage picture pointer no-underline text font-base" title="마이페이지" @click.native="toggle(false)" v-if="$store.state.account.loggedIn">
                    <span class="img profile" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>
                  </router-link>
                </div>
                <div class="account clearfix">
                  <router-link to="/mypage" class="pointer no-underline clearfix" @click.native="toggle(false)">
                    <b class="color-secondary">마이페이지</b>
                    <div>{{ $store.state.account.memberName }}</div>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <div class="account">
                  <a class="pointer" @click="login()">로그인</a>
                  <a class="pointer" @click="join()">회원가입</a>
                </div>
              </template>
            </div>
            <div class="right">
              <div class="acts">
                <span class="search pointer" @click="$store.commit('openModal', {name: 'Search'})" alt="프로젝트 검색" title="프로젝트 검색">
                  <span class="img" style="background-image: url(/assets/ico/component.header.search.svg)"></span>
                </span>
                <!--                <router-link to="/mypage" class="picture pointer no-underline text font-base" title="마이페이지" @click.native="toggle(false)" v-if="$store.state.account.loggedIn">-->
                <!--                  <span class="img profile" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>-->
                <!--                  <div class="bubble">My</div>-->
                <!--                </router-link>-->
                <!--                <a class="login pointer" @click="$store.commit('openModal', {name: 'Login'})" alt="로그인" title="로그인" v-else>-->
                <!--                  <span class="img mr-0" style="background-image: url(/assets/ico/component.header.login.svg)"></span>-->
                <!--                </a>-->
              </div>
              <div class="toggle" @click="toggle()">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <nav class="nav-mobile" v-show="state.toggle">
            <div class="menus">
              <ul class="tight">
                <li :class="{ 'color-point': $route.path.startsWith('/reward') }">
                  <router-link to="/reward/list" @click.native="toggle(false)">후원하기</router-link>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/invest') }">
                  <router-link to="/invest/list" @click.native="toggle(false)">투자하기</router-link>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/reservation') }">
                  <router-link to="/reservation/list" @click.native="toggle(false)">오픈예정</router-link>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/partnership') }">
                  <router-link to="/partnership/partnerlist" @click.native="toggle(false)">파트너십</router-link>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/apply') }">
                  <router-link to="/apply/intro" @click.native="toggle(false)">프로젝트 신청</router-link>
                </li>
              </ul>
            </div>
            <div class="tags">
              <router-link to="/partnership/partnerlist" class="subject font-xs color-anchor" @click.native="toggle(false)">오마이컴퍼니와 파트너가 함께해요!</router-link>
              <ul class="tight no-scrollbar">
                <li v-for="(c, idx) in state.contests" :key="idx">
                  <router-link :to="getPartnershipLink(c)" @click.native="toggle(false)">{{ c.contestName }}</router-link>
                </li>
              </ul>
            </div>
            <div class="menus">
              <ul class="tight font-sm">
                <li :class="{ 'color-point': $route.path.startsWith('/community/notice') }">
                  <router-link to="/community/notice" @click.native="toggle(false)">공지사항</router-link>
                </li>
                <li>
                  <a :href="$definitions.urls.companyIntro" target="_blank" rel="noopener noreferrer">회사 소개</a>
                </li>
                <li>
                  <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">가이드</a>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/policy/terms') }">
                  <router-link to="/policy/terms" @click.native="toggle(false)">이용약관</router-link>
                </li>
                <li :class="{ 'color-point': $route.path.startsWith('/policy/rewardPrivacy') }">
                  <router-link to="/policy/rewardPrivacy" @click.native="toggle(false)">개인정보보호정책</router-link>
                </li>
              </ul>
            </div>
          </nav>
          <div class="nav-opened">
            <ul class="tight">
              <li :class="{ 'active': $route.path === '/' }">
                <router-link to="/">
                  <span>홈</span>
                </router-link>
              </li>
              <li :class="{ 'active': $route.path.startsWith('/reward') }">
                <router-link to="/reward/list">
                  <span>후원하기</span>
                </router-link>
              </li>
              <li :class="{ 'active': $route.path.startsWith('/invest') }">
                <router-link to="/invest/list">
                  <span>투자하기</span>
                </router-link>
              </li>
              <li :class="{ 'active': $route.path.startsWith('/reservation') }">
                <router-link to="/reservation/list">
                  <span>오픈예정</span>
                </router-link>
              </li>
            </ul>
            <span class="stick" :style="state.stickStyle"></span>
          </div>
          <!-- mobile end -->
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import router from "@/scripts/router";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentHeader";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      http.get("/api/main/banners/top", undefined, {cache: true}).then(({data}) => {
        if (!data?.body?.length) {
          state.banner.height = 0;
          return;
        }

        state.banner.list = data.body;

        nextTick(() => {
          setTimeout(() => {
            state.banner.marginTop = 0;
          }, 250);
        });

        if (state.banner.list.length > 1) {
          const move = (idx, transition) => {
            state.banner.handle.transition = transition;
            state.banner.handle.marginTop = idx * state.banner.height * -1;
          };

          // 배너 롤링 반복
          state.banner.interval = setInterval(() => {
            move(1, true);

            nextTick(() => {
              setTimeout(() => {
                state.banner.list.push(state.banner.list.splice(0, 1)[0]);
                move(0, false);
              }, state.banner.handle.transitionTime);
            });
          }, state.banner.intervalTime);
        }
      });

      lib.isMobile() && http.get("/api/main/contests").then(({data}) => {
        state.contests = data.body;
      });
    });

    const state = reactive({
      path: "",
      toggle: false,
      contests: [],
      banner: {
        list: [],
        interval: 0,
        height: 50,
        marginTop: -50,
        intervalTime: 2500,
        handle: {
          transition: true,
          transitionTime: 500,
          marginTop: 0
        }
      },
      stickStyle: {
        display: "",
        width: "0",
        left: "0"
      }
    });

    const login = () => {
      store.dispatch("goLoginPage");
      toggle(false);
    };

    const join = () => {
      store.dispatch("goLoginPage", "join");
      toggle(false);
    };

    const toggle = (val) => {
      if (val !== undefined) {
        state.toggle = val;
      } else {
        state.toggle = !state.toggle;
      }
    };

    const search = (keyword) => {
      toggle(false);
      store.commit("openModal", {
        name: "Search",
        params: {keyword}
      });
    };

    const remove = () => {
      const $banners = document.getElementById(`${component.name}Banners`);
      clearInterval(state.banner.interval);

      if (!$banners) {
        return;
      }

      $banners.style.marginTop = (state.banner.height * -1) + "px";

      setTimeout(() => {
        state.banner.list = [];
        nextTick(() => {
          window.scrollTo(window.scrollX, window.scrollY + 1);
        });
      }, 250);
    };

    const moveStick = () => {
      const nav = document.querySelector(".nav-opened");
      const span = nav?.querySelector(".nav-opened > ul > li.active > a > span");

      if (!nav || !span) {
        state.stickStyle.display = "none";
        return;
      }

      const navRect = nav.getBoundingClientRect();
      const spanRect = span.getBoundingClientRect();

      state.stickStyle.left = (spanRect.x - navRect.x) + "px";
      state.stickStyle.width = spanRect.width + "px";
      state.stickStyle.display = "inline-block";
    };

    const getBannerStyle = (idx) => {
      const banner = state.banner.list[idx];

      return {
        background: banner.bgColor ? "#" + banner.bgColor.replace("#", "") : "",
        color: banner.titleColor ? "#" + banner.titleColor.replace("#", "") : "",
      };
    };

    const getPartnershipLink = (contest) => {
      switch (contest.contestType) {
        case "E":
        case "P":
          return contest.rewardProjectCount < 1
              ? "/partnership/" + contest.contestSeq + "/invest"
              : "/partnership/" + contest.contestSeq + "/reward";

        case "S":
          return "/partnership/" + contest.contestSeq + "/mock";
      }

      return "";
    };

    const runMoveStickTwice = () => {
      setTimeout(moveStick);
      setTimeout(moveStick, 1000);
    };

    watch(() => router.app.$route.path, () => {
      window.innerWidth <= 991 && nextTick(runMoveStickTwice);
    });

    onMounted(() => {
      runMoveStickTwice();
      clearInterval(state.banner.interval);
    });

    return {component, state, login, join, toggle, search, remove, getBannerStyle, getPartnershipLink};
  },
});
</script>

<style lang="scss" scoped>
header {
  //margin-bottom: $px-1;
  border-bottom: $px1 solid #eee;
  box-shadow: 0 0 $px10 0 rgba(4, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  > .banners {
    background: #fff;
    text-align: center;
    overflow: hidden;
    //border-bottom: $px1 solid #f7f7f7;
    position: relative;
    transition: margin-top 0.5s;
    z-index: 20;

    .handle {
      &.transition {
        transition: margin-top 0.5s;
      }
    }

    > .banner {
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 $px50;
      text-decoration: none;
      font-weight: 600;

      > img {
        height: 100%;
        margin-right: $px12;
      }

      > .text {
        font-size: $px16;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > .remove {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: $px50;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: $px1;
      background: #eee;
    }
  }

  > .curtain {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
  }

  > .wrapper {
    position: relative;
    padding: $px25 0 $px34 0;
    z-index: 10;
    background: #fff;

    .acts {
      display: inline-block;

      > span, > a {
        vertical-align: middle;

        &.search {
          .img {
            width: $px20;
            height: $px19;
          }
        }
      }

      > a {
        &.admin {
          margin-right: $px19;
          font-size: 0;

          i {
            position: relative;
            top: $px-1;
            color: #3c3c3c;
            font-size: $px20;
          }
        }
      }

      > span {
        display: inline-block;

        &.search {
          position: relative;
          top: $px2;
        }
      }

      > a {
        display: inline-block;

        &.login:hover {
          font-weight: 500;
        }
      }
    }

    .bubble {
      position: absolute;
      top: $px-9;
      font-size: $px10;
      display: inline-block;
      text-align: center;
      background: #49545f;
      color: #fff;
      padding: $px1 $px3;
    }

    .picture {
      position: relative;
      top: $px-1;
      left: $px-2;
      cursor: pointer;
      display: inline-block;
      width: $px30;
      height: $px30;

      .bubble {
        right: $px-18;
        border-radius: $px4 $px4 $px4 0;
      }
    }

    .top {
      padding: $px5 0;
      color: #fff;
      margin-bottom: $px19;

      ul {
        li {
          margin: 0 $px10;
          position: relative;

          a {
            text-decoration: none;
          }

          &:before {
            content: " ";
            position: absolute;
            left: $px-11;
            top: $px3;
            height: $px13;
            width: $px1;
            background: #fff;
          }

          &:nth-child(4):before,
          &:first-child:before {
            content: none;
          }
        }
      }
    }

    .container {
      .brand {
        float: left;

        > a {
          color: #1259a7;
          cursor: pointer;
          font-weight: 500;
          text-decoration: none;
          display: inline-block;
          margin-top: $px-1;

          img {
            width: $px187;
            height: $px33;
          }
        }
      }

      .nav {
        float: left;
        padding: 0 0 0 $px30;

        ul {
          li {
            position: relative;

            a {
              color: #464646;
              cursor: pointer;
              text-decoration: none;
              font-size: $px18;
              font-weight: 600;
              letter-spacing: 0;
            }
          }

          > li {
            &:hover {
              > ul {
                display: block;
              }
            }
          }
        }

        > ul {
          text-align: center;

          > li {
            display: inline-block;
            margin: 0 $px10;

            &:last-child {
              margin-right: 0;

              > a {
                padding-right: 0;
              }
            }

            > a {
              display: inline-block;
              padding: $px6 $px10 0 $px10;
            }
          }
        }
      }

      .side-desktop {
        height: $px30;
        float: right;
        position: relative;
        top: $px8;
        padding-right: $px138;

        .acts {
          position: relative;
          top: $px4;

          .search {
            margin-top: $px-2;
            margin-right: $px19;
          }

          .login {
            top: $px-2;
            text-decoration: none;
            position: relative;
          }
        }

        .btn {
          padding: $px12 $px15;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: $px-23;

          img {
            height: $px11;
            margin-right: $px7;
            vertical-align: middle;
            margin-top: $px1;
          }
        }
      }

      .side-mobile {
        display: none;

        > .left {
          position: absolute;
          left: $px20;
          top: $px20;
          display: none;

          > .my {
            float: left;
            margin-right: $px10;

            .picture {
              width: $px35;
              height: $px35;
            }
          }

          > .account {
            float: left;

            > a {
              float: left;

              > b {
                float: left;
                font-size: $px10;
                margin-bottom: $px1;
              }

              > div {
                clear: both;
                font-size: $px13;
              }
            }

            > a {
              font-size: $px14;
              margin-right: $px10;
            }
          }
        }

        > .right {
          position: absolute;
          top: $px19;
          right: $px15;

          .toggle {
            display: inline-block;
            height: $px20;
            width: $px24;
            padding: 0;
            cursor: pointer;
            position: relative;
            vertical-align: middle;
            margin-left: $px15;

            > div {
              height: $px1;
              width: 100%;
              background: #3c3c3c;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.25s;

              &:nth-child(2) {
                top: $px8;
              }

              &:nth-child(3) {
                top: $px16;
              }
            }
          }

          .acts {
            vertical-align: middle;

            .search {
              display: inline-block;
              top: 0;
            }

            .login {
              padding-top: $px2;

              .img {
                width: $px24;
                height: $px24;
              }
            }

            .bubble {
              right: auto;
              left: $px-15;
              top: $px-13;
              border-radius: $px4 $px4 0 $px4;
            }
          }
        }
      }

      .nav-mobile {
        background: #fff;
        position: absolute;
        display: none;
        top: $px65;
        left: 0;
        width: 100%;
        text-align: left;
        padding: $px15 $px20 $px20 $px20;
        z-index: 10;

        > .menus {
          > ul li {
            a {
              display: block;
              padding: $px5 0;
              text-decoration: none;
            }
          }

          &:not(:last-child) {
            padding-bottom: $px15;
          }
        }

        .tags {
          padding-top: $px8;
          padding-bottom: $px15;

          .subject {
            display: block;
            padding-bottom: $px13;
          }

          > ul {
            > li {
              display: inline-block;
              padding-right: $px8;
              padding-bottom: $px8;

              &:last-child {
                padding-right: $px25;
              }
            }
          }
        }
      }

      .nav-opened {
        display: none;
        padding-top: $px15;
        margin: 0 $px-10;
        color: #3c3c3c;
        font-size: $px15;
        position: relative;

        > ul > li {
          display: inline-block;

          > a {
            display: inline-block;
            padding: $px8 $px10 $px4 $px10;
            font-weight: 500;
            text-decoration: none;

            > span {
              padding-bottom: $px6;
            }
          }
        }

        > .stick {
          content: "";
          position: absolute;
          bottom: 0;
          height: $px1;
          background: #3c3c3c;
          transition: left 0.25s;
        }
      }
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 0;
    border-bottom: 0;

    > .banners {
      > .banner {
        padding-left: $px15;

        .text {
          font-size: $px14;
        }
      }
    }

    > .wrapper {
      padding: $px16 0 0 0;
      height: $px105;

      .container {
        .brand {
          float: none;

          a img {
            width: auto;
            height: $px24;
          }
        }

        .nav {
          display: none;
        }

        .side-desktop {
          display: none;
        }

        .nav-mobile {
          display: block;
        }

        .side-mobile {
          display: block;
        }

        .nav-opened {
          display: block;
        }
      }
    }

    &.mobile-no-fixed {
      position: static;
    }

    &.opened {
      > .banners {
        //display: none;
      }

      > .curtain {
        display: block;
      }

      > .wrapper {
        .container {
          .brand, .nav-opened {
            display: none;
          }

          .side-mobile {
            > .left {
              display: block;
            }

            .toggle {
              > div:nth-child(1) {
                top: $px8;
                transform: rotate(45deg);
              }

              > div:nth-child(2) {
                opacity: 0;
              }

              > div:nth-child(3) {
                top: $px8;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }
}
</style>