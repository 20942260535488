<template>
  <div class="follow-card shadow" :class="{skeleton: skeleton}">
    <div class="inner">
      <div class="info">
        <Anchor :href="`/channel/${memberSeq}?tabs=projects`">
          <MemberPicture :memberSeq="memberSeq" size="50"></MemberPicture>
        </Anchor>
        <div class="text">
          <Anchor class="member-name" :href="`/channel/${memberSeq}?tabs=projects`" :title="memberName">{{ memberName }}</Anchor>
          <span class="sub" v-if="openedCount">진행 프로젝트 {{ openedCount }}</span>
          <span class="sub">펀딩 프로젝트 {{ supportCount }}</span>
        </div>
      </div>
      <div class="followed" v-if="memberSeq !== $store.state.account.memberSeq">
        <button class="btn btn-sm" :class="$store.state.follows.memberSequences.find(m => m.makerSeq === memberSeq) ? 'following' : 'follow'" :disabled="skeleton && !$store.state.follows.loaded"
                @click="toggleFollow(memberSeq)">
                      <span :class="{'text-muted': $store.state.follows.memberSequences.find(m => m.makerSeq === memberSeq)}">
                        {{ $store.state.follows.memberSequences.find(m => m.makerSeq === memberSeq) ? (place === "channel" ? "팔로잉" : "팔로우 취소") : "팔로우" }}
                      </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";

function Component(initialize) {
  this.name = "componentFollowCard";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, MemberPicture},
  mixins: [mixin],
  props: {
    place: String,
    skeleton: Boolean,
    memberSeq: String,
    memberName: String,
    supportCount: Number,
    openedCount: Number
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({});

    const toggleFollow = (seq) => {
      const args = {makerSeq: seq};

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleMemberFollow", args);
      }

      redirectCallback.run("toggleMemberFollow", args);
    };

    return {component, state, toggleFollow};
  }
});
</script>
<style lang="scss" scoped>
.follow-card {
  margin-top: $px15;
  margin-bottom: $px15;
  font-size: $px14;

  .title {
    padding: $px30 0 0 $px30;
    margin-bottom: $px-15;
  }

  .inner {
    border-radius: $px4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $px30;
    height: $px115;

    .info {
      display: flex;
      align-items: center;
      overflow: hidden;

      .text {
        display: inline-block;
        padding: 0 $px13;
        overflow: hidden;

        > a {
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .sub {
          color: $colorSecondary;
          display: inline-block;
          font-size: $px11;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:not(:first-of-type) {
            margin-left: $px5;
          }
        }
      }
    }

    .followed {
      > button {
        font-size: $px12;
        padding: $px8 $px10;
        text-align: center;
        width: $px90;
        white-space: nowrap;

        &.follow {
          background-color: $colorPurple;
          color: #fff;

          &:hover {
            background-color: $colorPurpleActive;
          }
        }

        &.following {
          background-color: #efefef;
          color: $colorSecondary;

          &:hover {
            background-color: $colorBackground;
          }
        }
      }
    }
  }

  &.skeleton {
    .inner {
      .info {
        > a > span, .text > a, .text > span {
          @include skeleton;
        }
      }

      .followed {
        > button, > button.follow, > button.following, > button > span {
          @include skeleton;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .inner .info .text .sub {
      display: block;

      &:not(:first-of-type) {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .inner {
      font-size: $px12;

      .followed > button {
        &.follow, &.following {
          padding: $px8 $px12;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .inner {
      .info {
        span.img {
          width: $px30 !important;
          height: $px30 !important;
        }
      }
    }
  }
}
</style>