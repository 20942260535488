<template>
  <div class="catch"></div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageEtcCatch";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      window.alert("이 페이지는 존재하지 않거나 삭제되었습니다. 메인으로 이동합니다.");
      window.location.replace("/");
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.catch {
  min-height: $px500;
}
</style>