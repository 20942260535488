<template>
  <div class="partner-card" :class="{skeleton}">
    <div class="wrapper">
      <router-link :to="link" class="thumb">
        <div :style="{backgroundImage: `url('${$definitions.urls.imgCdn + thumbImagePath + thumbImageSaveName}')`}"></div>
        <span class="badge badge-point" v-if="progressOrder === 0">진행중</span>
      </router-link>
      <div class="content">
        <div class="profile">
          <span>{{ partnershipName }}</span>
        </div>
        <div class="title font-md bold">
          <router-link :to="link">{{ contestName }}</router-link>
        </div>
        <div class="desc" v-if="contestExplanationText !== undefined">
          <span>{{ contestExplanationText }}</span>
        </div>
        <dl class="clearfix" v-if="projectCount !== undefined">
          <dt>총 프로젝트</dt>
          <dd>
            <b class="color-point">{{ $lib.getNumberFormat(projectCount) }}</b>
            <span>개</span>
          </dd>
        </dl>
        <dl class="clearfix" v-if="sumAmt !== undefined">
          <dt>
            <!-- #1147 -->
            <span v-if="contestSeq === 752">총 투자 새싹</span>
            <span v-else>총 투자 금액</span>
          </dt>
          <dd>
            <b class="color-point">{{ $lib.getNumberFormat(sumAmt) }}</b>
            <span>원</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPartnerCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    link: String,
    skeleton: Boolean,
    contestSeq: Number,
    thumbImagePath: String,
    thumbImageSaveName: String,
    partnershipName: String,
    contestName: String,
    contestExplanationText: String,
    projectCount: Number,
    sumAmt: Number,
    progressOrder: Number,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.partner-card {
  padding: $px15 $px15 $px30 $px15;

  > .wrapper {
    font-size: $px14;
    background: #fff;
    border-radius: 0 0 $px4 $px4;

    .thumb {
      display: block;
      padding: 30.36% 0;
      border-radius: $px4 $px4 0 0;
      position: relative;
      overflow: hidden;

      > div {
        transition: transform 0.18s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #e9ecef;
        background-position: 50%;
        background-size: cover;
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        padding: $px10 $px10;
        font-size: $px12;
      }

      &:hover > div {
        transform: scale(1.1);
      }
    }

    .content {
      .profile {
        color: #555;
        margin-top: $px10;
        margin-bottom: $px5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: $px12;
      }

      .title {
        margin-bottom: $px10;
        height: $px54;
        overflow: hidden;

        a {
          text-decoration: none;
        }
      }

      .desc {
        font-size: $px14;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: $px20;
        color: #999;
      }

      dl {
        margin: 0;
        font-size: $px16;

        dt {
          float: left;
        }

        dd {
          float: right;
          margin: 0;
        }

        &:last-child {
          margin-top: $px10;
        }
      }
    }
  }

  &.skeleton > .wrapper {
    .thumb {
      @include skeleton;
    }

    .content {
      .profile > span,
      .title > a,
      .desc > span {
        @include skeleton;
      }

      dl {
        dt {
          @include skeleton;
        }

        dd {
          > b, > span {
            @include skeleton;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .wrapper .content .title {
      height: auto;
    }
  }
}
</style>