import lib from "@/scripts/lib";

const env = {
  device: lib.isMobile() ? "mobile" : "desktop",
  browser: lib.getBrowser(),
  development: process.env.NODE_ENV === "development",
  location: window.global?.env?.location || "local",
  superPass: window.global?.env?.superPass || false,
  profileFileDefaultPath: window.global?.env?.profileFileDefaultPath || "",
  omcApp: window.navigator.userAgent?.includes("ohmycompanyapp"),
};

export default env;