<template>
  <div class="modals" v-if="computedModals.length">
    <div class="modal" :data-name="m.name" v-for="(m, idx) in computedModals" :key="idx">
      <div class="wrapper">
        <div class="inner">
          <div class="core shadow">
            <Modal :name="m.name" :params="m.params" :key="m.name"/>
          </div>
          <div class="close-btn pointer" @click="closeModal()" title="닫기" v-if="!m.preventClose">
            <img class="thin" src="/assets/ico/modal.common.close.svg" alt="닫기"/>
            <img class="bold" src="/assets/ico/modal.common.close.bold.svg" alt="닫기"/>
          </div>
        </div>
        <div class="loading">
          <Spinner/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted} from "@vue/composition-api";
import Modal from "@/components/Modal";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import Spinner from "@/components/Spinner";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "componentModals";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Spinner, Modal},
  setup() {
    const component = new Component(() => {
    });

    const computedModals = computed(() => {
      const modals = store.getters.modals();

      if (modals.length) {
        const arr = [];
        arr.push("[QUERY]\n" + router.app.$route.query.modals);
        arr.push("");
        arr.push(`[PARSE]\n${store.getters.modals().map(m => JSON.stringify(m)).join("\n")}`);
        console.info(arr.join("\n"));
      }

      return modals;
    });

    const closeModal = () => {
      const modals = computedModals.value;
      const latestModal = modals[modals.length - 1];
      latestModal && !latestModal.preventClose && store.commit("closeModal", {name: latestModal.name});
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onKeydown", (e) => {
        (e.key === "Escape" || e.key === "Esc") && !store.state.confirm.message && !store.state.loading && closeModal();
      }]);
    });

    return {component, computedModals, closeModal};
  },
});
</script>

<style lang="scss" scoped>
.modals {
  .modal {
    background: rgba(0, 0, 0, 0.75);
    display: block;
    padding: 0 $px25;
    z-index: 1300;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: $px50 $px25;

      .inner {
        position: relative;
        max-height: 100%;

        .core {
          width: 100%;
          height: 100%;
          position: relative;
          overflow-x: hidden;
          overflow-y: auto;
        }

        .close-btn {
          position: absolute;
          border: 0;
          top: $px-40;
          right: $px-40;
          height: $px52;
          width: $px52;
          margin-right: $px-10;
          margin-top: $px-10;
          padding: $px10;
          text-align: center;
          transition: opacity 0.18s;
          z-index: 1;

          img {
            float: left;
            width: 100%;
            height: 100%;
            opacity: 0.8;

            &.bold {
              display: none;
            }
          }

          &:hover {
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    &[data-size=xs] .wrapper {
      max-width: $px420;
    }

    &[data-size=sm] .wrapper {
      max-width: $px490;
    }

    &[data-size=md] .wrapper {
      max-width: 767px;
    }

    &[data-size=xm] .wrapper {
      max-width: 910px;
    }

    &[data-size=lg] .wrapper {
      max-width: 991px;
    }

    &[data-size=xl] .wrapper {
      max-width: 1160px;
    }

    &.none-shadow {
      .wrapper .inner .core {
        box-shadow: none !important;
      }
    }

    &:last-child {
      overflow: auto;
    }

    &[data-size] .wrapper .loading {
      display: none
    }

    &:not([data-size]) .wrapper .inner {
      display: none;
    }
  }

  @media(max-width: 1199px) {
    .modal .wrapper .close-btn {
      right: 0;
      text-align: right;
    }
  }

  @media(max-width: 991px) {
    .modal .wrapper {
      padding: 0;

      .inner {
        padding-top: $px60;
        padding-bottom: $px15;

        .close-btn {
          top: $px13;
        }
      }
    }
  }

  @media(max-width: 767px) {
    .modal {
      padding: 0 $px15;

      .wrapper {
        .inner {
          padding-top: $px42;

          .close-btn {
            top: $px12;
            right: 0;
            width: $px40;
            height: $px40;

            img {
              &.thin {
                display: none;
              }

              &.bold {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &::v-deep {
    color: $colorTextDefault;

    .form-control {
      color: inherit;
    }

    //.btn {
    //  &.btn-secondary {
    //    border-color: $colorSecondary;
    //    background: $colorSecondary;
    //    color: #fff;
    //  }
    //}
  }
}
</style>