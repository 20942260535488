<template>
  <div class="sitemap">
    <div class="container">
      <h1 class="page-top">
        <div class="title">사이트맵</div>
      </h1>
      <div class="content">
        <ul class="tight clearfix">
          <li>
            <router-link to="/reward/list">후원하기</router-link>
          </li>
          <li>
            <router-link to="/invest/list">투자하기</router-link>
          </li>
          <li>
            <router-link to="/partnership/partnerlist">파트너십 펀딩대회</router-link>
          </li>
          <li>
            <router-link to="/reservation/list">오픈예정</router-link>
          </li>
          <!-- <li>
            <router-link to="/apply/rewardIntro">프로젝트 시작하기</router-link>
            <ul class="tight">
              <li><router-link to="/apply/rewardIntro">후원형</router-link></li>
              <li><router-link to="/apply/investIntro">증권형</router-link></li>
            </ul>
          </li> -->
          <li>
            <router-link to="/community/notice">커뮤니티</router-link>
            <ul class="tight">
              <li>
                <router-link to="/community/notice">공지</router-link>
              </li>
              <li>
                <router-link to="/community/fundingtip">펀딩 소식</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">이용가이드</a>
          </li>
          <li>
            <a :href="$definitions.urls.companyIntro" target="_blank" rel="noopener noreferrer">회사 소개</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageSitemapSitemap";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "사이트맵");
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.sitemap {
  padding: $px70 0;

  .content {
    border: $px1 solid #dedede;
    padding: $px70 $px80;

    > ul {
      font-size: 0;

      > li {
        display: inline-block;
        font-size: 1rem;
        vertical-align: top;
        margin-bottom: $px50;
        padding: 0 $px15;
        text-align: left;
        width: 25%;

        > a {
          display: block;
          text-decoration: none;
          font-weight: 400;
          line-height: $px42;
        }

        ul {
          margin-top: $px10;

          li {
            position: relative;
            width: 100%;

            a {
              display: block;
              font-size: $px14;
              height: $px30;
              line-height: $px30;
              padding-left: $px10;
            }

            &:before {
              background-color: #303030;
              content: "";
              height: $px2;
              left: 0;
              position: absolute;
              top: $px15;
              width: $px2;
            }
          }
        }

        &:hover {
          > a {
            color: #3a3a3a;
            font-weight: 500;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .content > ul > li {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    .content {
      padding: $px30 $px25;

      > ul > li {
        width: 100%;
        margin-bottom: $px25;
      }
    }
  }
}
</style>