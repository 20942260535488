<template>
  <div class="partnership" :class="{ skeleton : !state.loaded }">
    <div class="cards no-scrollbar">
      <div class="wrapper">
        <ul class="tight clearfix">
          <template v-if="state.loaded">
            <template v-if="state.items.length">
              <li v-for="(p, idx) in state.items" :key="idx" :class="{skeleton:p.placeholder}">
                <PartnerCard
                    :link="$store.getters.partnerCardLink(p.contestSeq, p.contestType)"
                    :contestSeq="p.contestSeq"
                    :thumbImagePath="p.thumbImagePath"
                    :thumbImageSaveName="p.thumbImageSaveName"
                    :partnershipName="p.partnershipName"
                    :contestName="p.contestName"
                    :contestExplanationText="p.contestExplanationText"
                    :projectCount="p.projectCount"
                    :sumAmt="p.sumAmt"
                    :progressOrder="p.progressOrder"
                />
              </li>
            </template>
          </template>
          <template v-else>
            <li v-for="i in 4" :key="i">
              <PartnerCard
                  link=""
                  :skeleton="true"
                  :contestSeq="0"
                  partnershipName="Wait a moment"
                  contestName="Wait a moment"
                  contestExplanationText="Wait a moment"
                  :rewardProjectCount="0"
                  :rewardProjectSumAmt="0"
              />
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive,} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import PartnerCard from "@/components/PartnerCard.vue";

function Component(initialize) {
  this.name = "pageMainHomePartnership";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PartnerCard},
  mixins: [mixin],
  props: {
    loaded: Boolean
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({
      items: [],
      loaded: false
    });

    onMounted(() => {
      state.loaded = false;
      emit("update:loaded", state.loaded);

      http.get("/api/main/partnerships", undefined, {cache: true}).then(({data}) => {
        state.loaded = true;
        state.items = data.body;
        emit("update:loaded", state.loaded);
      });
    });

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.partnership {
  .cards {
    font-size: 0;
    margin: $px-15 0;
    overflow: auto;

    > .wrapper {
      margin: 0 $px-15;

      ul li {
        display: inline-block;
        width: calc(100% / 4 - 0.1px);
        vertical-align: top;

        .partner-card {
          padding-bottom: 0;

          &::v-deep {
            > .wrapper .content .title {
              margin-bottom: 0;
              height: auto !important;

              a {
                display: inline-block;
                font-size: $px15;
                line-height: 1.4;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .cards {
      margin-right: $px-15;

      > .wrapper {
        margin: 0 $px-8;

        ul {
          white-space: nowrap;

          li {
            width: 40%;

            .partner-card {
              padding-left: $px8;
              padding-right: $px8;

              &::v-deep {
                > .wrapper {
                  .content .title {
                    a {
                      font-size: $px12;
                      white-space: break-spaces;
                    }
                  }

                  .thumb .badge {
                    padding: $px7;
                  }
                }
              }
            }

            &:last-child {
              margin-right: $px7;
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .cards > .wrapper ul li .partner-card {
      &::v-deep {
        > .wrapper .thumb .badge {
          padding: $px5;
          font-size: $px10;
        }
      }
    }
  }
}
</style>