<template>
  <div class="board">
    <div class="container">
      <div class="wrapper">
        <div class="header">
          <div class="title font-xl">
            <router-link :to="`/community/${category}`">
              <span v-if="category === 'notice'">공지사항</span>
              <span v-else>펀딩 소식</span>
            </router-link>
          </div>
          <div class="desc font-sm">
            <span v-if="category === 'notice'">오마이컴퍼니의 다양한 소식을 알려드립니다.</span>
            <span v-else>프로젝트 펀딩과 관련된 소식을 전해드립니다.</span>
          </div>
          <div class="side" :style="{ paddingRight: category === 'notice' ? '191px' : '' }">
            <div class="search input-group font-sm" v-if="category === 'notice'">
              <input type="text" class="border-focus-point font-sm form-control" placeholder="검색어를 입력해주세요." @keyup.enter="search()" v-model="state.args.searchKeyword"/>
              <div class="input-group-append" @click="search()">
                <i class="fa fa-search"></i>
              </div>
            </div>
            <ul class="nav">
              <li class="nav-item" v-for="(m, idx) in menus" :key="idx">
                <router-link :to="m.path" :class="[category === m.name ? 'active btn-bordered-point': 'btn-light']" class="btn font-sm">{{ m.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="article">
          <BoardList :category="category" v-if="state.mode === 'list'"/>
          <BoardDetail :category="category" v-else-if="state.mode === 'detail'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import Pagination from "@/components/Pagination";
import NoData from "@/components/NoData";
import BoardList from "@/components/BoardList";
import router from "@/scripts/router";
import BoardDetail from "@/components/BoardDetail";

function Component(initialize) {
  this.name = "componentBoard";
  this.initialize = initialize;
}

export default defineComponent({
  components: {BoardDetail, BoardList, NoData, Pagination, Wait},
  mixins: [mixin],
  props: {
    category: String
  },
  setup(props) {
    const component = new Component(() => {
      if (router.app.$route.query.searchKeyword) {
        state.args.searchKeyword = router.app.$route.query.searchKeyword;
      }

      if (router.app.$route.params.seq) {
        state.mode = "detail";
      }
    });

    const state = reactive({
      mode: "list",
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 10,
        firstIndex: 1,
        recordCountPerPage: 5,
        searchKeyword: "",
      },
      document: {
        list: [],
        pagination: null
      },
    });

    const menus = [
      {title: "공지사항", name: "notice", path: "/community/notice"},
      {title: "펀딩 소식", name: "fundingtip", path: "/community/fundingtip"},
    ];

    const search = () => {
      router.push({path: `/community/${props.category}?pageIndex=1&searchKeyword=${window.encodeURIComponent(state.args.searchKeyword)}`});
    };

    return {component, state, menus, search};
  }
});
</script>

<style lang="scss" scoped>
.board {
  background: $colorBackground;
  padding: $px35 0;

  > .container > .wrapper {
    background: #fff;
    padding: $px66 $px50;
    border: $px1 solid #eee;

    .header {
      position: relative;
      padding-right: $px220;
      padding-bottom: $px35;

      .title {
        font-weight: 500;
        margin-bottom: $px8;

        > a {
          text-decoration: none;
        }
      }

      .desc {
        color: #767676;
      }

      .side {
        position: absolute;
        top: $px10;
        right: 0;

        .search {
          height: $px50;
          width: $px210;

          input[type=text] {
            height: 100%;
            width: $px160;
          }

          .input-group-append {
            height: $px50;
            width: $px50;
            background: $colorBackground;
            position: relative;
            border: $px1 solid #ced4da;
            border-radius: 0 $px4 $px4 0;
            cursor: pointer;

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .nav {
          display: block;
          white-space: nowrap;
          overflow: auto;
          position: absolute;
          top: 0;
          right: 0;

          li {
            display: inline-block;

            a {
              padding: $px14 $px14;
            }

            &:first-child {
              margin-right: $px13;
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    padding: $px15 0;

    > .container > .wrapper {
      padding: $px25;

      .header {
        padding-right: 0;
        padding-bottom: $px15;

        .side {
          position: static;
          padding: $px15 0 0 0 !important;

          .search {
            width: 100%;

            input[type=text] {
              width: 1%;
            }
          }

          .nav {
            position: static;
            width: 100%;
            margin-top: $px15;

            li a {
              padding: $px8 $px14;
            }
          }
        }
      }
    }
  }
}
</style>