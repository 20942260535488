<template>
  <div class="detail-news">
    <template v-if="!state.news.loaded || (state.news.loaded && state.news.list.length > 0)">
      <div class="item" v-for="(n, idx) in state.news.list" :key="idx" :class="{skeleton: n.skeleton}" :id="`${component.name}Item`">
        <div class="top clearfix">
          <Anchor :href="`/${n.projectType}/${n.projectSeq}`" class="project text-muted float-left" @click.native.stop>
            <span>{{ n.projectName }}</span>
          </Anchor>
          <span class="date float-right text-muted">{{ n.createDate }}</span>
        </div>
        <div class="title">
          <span @click="state.news.loaded && openNews(n)">{{ n.title }}</span>
        </div>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageChannelDetailNews";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, Anchor, MemberPicture},
  mixins: [mixin],
  props: {
    computedThumbnailUrl: Function,
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      news: {
        loaded: false,
        more: false,
        paginationInfo: null,
        list: [],
      },
      args: {
        pageIndex: 1,
        pageUnit: 10,
        pageSize: 5,
      },
      temp: ""
    });

    const load = (more) => {
      if (!more) {
        state.args.pageIndex = 1;
        state.news.loaded = false;
      } else {
        state.args.pageIndex += 1;
        state.news.more = true;
      }

      for (let i = 0; i < 6; i += 1) {
        state.news.list.push({
          projectName: "Wait",
          title: "Please Wait a moment",
          createDate: "0000-00-00",
          memberName: "Please Wait a moment",
          content: "Please Wait a moment",
          skeleton: true,
        });
      }

      http.get(`/api/channel/${router.app.$route.params.makerSeq}/news`, state.args).then(({data}) => {
        state.news.paginationInfo = data.body.paginationInfo;
        state.news.loaded = true;

        if (more) {
          for (let i in data.body.list) {
            state.news.list = state.news.list.filter(i => !i.skeleton);
            state.news.list.push(data.body.list[i]);
          }
          return state.news.more = false;
        }

        state.news.list = data.body.list;
      });
    };

    const openNews = (news) => {
      store.commit("openModal", {
        name: "News",
        params: {
          projectSeq: news.projectSeq,
          projectType: news.projectType,
          newsSeq: news.newsSeq,
          page: "channel"
        }
      });
    };

    const onScroll = () => {
      if (!state.news.loaded || state.args.pageIndex >= state.news.paginationInfo.lastPageNo || state.news.more) {
        return;
      }

      const firstItemHeight = document.getElementById(`${component.name}Item`).offsetHeight + 200;

      firstItemHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, openNews};
  }
});
</script>

<style lang="scss" scoped>
.detail-news {
  margin: $px20 0;

  > .item {
    padding: $px20 0;

    .top {
      font-size: $px12;
    }

    .title {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: $px8;

      > span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &.skeleton {
      .date > span {
        @include skeleton;
      }

      .top, .top, .title {
        > span, > a {
          @include skeleton;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>