<template>
  <div class="join-step2" :type="$store.state.joinForm.memberType">
    <div class="sns blank" v-if="!$store.state.joinForm.memberId">
      <div class="font-sm section">SNS로 빠르게 이메일 인증이 가능해요</div>
      <div class="icons no-scrollbar">
        <button class="btn" :class="s.name" v-for="(s, idx) in computedSnsButtons" :key="idx" :title="`${s.title} 계정으로 가입`" @click="verifyEmailBySns(s.name)" :style="{ backgroundImage: `url(${s.img})` }"></button>
      </div>
    </div>
    <div>
      <div class="font-sm section">{{ $store.state.joinForm.memberId ? "이메일 인증이 완료되었어요." : "이메일로 인증받으실 수도 있어요." }}</div>
      <div class="form" v-if="!$store.state.joinForm.memberId">
        <div v-show="!state.memberId">
          <input type="email" class="font-sm border-focus-point form-control" placeholder="ex) omc@ohmycompany.com" ref="emailInputRef" @keyup.enter="sendCode()"/>
          <button class="font-sm btn btn-point" @click="sendCode()">인증 메일 발송</button>
        </div>
        <div v-show="state.memberId">
          <input type="text" class="font-sm border-focus-point form-control" maxlength="6" placeholder="인증 코드를 입력해주세요." ref="codeInputRef" @keyup.enter="verifyEmail()"/>
          <button class="font-sm btn btn-point" @click="verifyEmail()" :disabled="state.timeout.status === 'expired'">
            <span>{{ state.timeout.status === "expired" ? "유효 시간 만료" : "인증 확인" }}</span>
            <span class="font-xs" v-if="state.timeout.status === 'ticking'"> ({{ state.timeout.minutes }}:{{ state.timeout.seconds }})</span>
          </button>
          <button class="font-sm btn btn-light mt-2" @click="reset()">다시 입력</button>
        </div>
      </div>
      <template v-else>
        <input type="text" class="font-sm border-focus-point form-control" :value="$store.state.joinForm.memberId" readonly/>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import env from "@/scripts/env";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import account from "@/scripts/account";

function Component(initialize) {
  this.name = "pageJoinStep2";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    temp: Object,
    go: Function
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      loaded: true,
      memberId: "",
      timeout: {
        timer: 0,
        status: "",
        minutes: "",
        seconds: "",
      }
    });

    const emailInputRef = ref();
    const codeInputRef = ref();

    const computedSnsButtons = computed(() => {
      const result = [];

      definitions.sns.buttons.forEach(b => {
        switch (b.name) {
          case "google":
            !env.omcApp && result.push(b);
            break;

          case "apple":
            env.browser !== "IE" && result.push(b);
            break;

          default:
            result.push(b);
            break;
        }
      });

      return result;
    });

    const reset = () => {
      state.memberId = "";
      clearInterval(state.timeout.timer);

      nextTick(() => {
        emailInputRef.value.value = "";
        emailInputRef.value.focus();
      });
    };

    const sendCode = () => {
      const email = emailInputRef.value.value;

      if (!email) {
        store.commit("setSwingMessage", "이메일 주소를 입력해주세요.");
        emailInputRef.value.focus();
        return;
      } else if (!lib.isValidEmail(email)) {
        store.commit("setSwingMessage", "이메일 주소가 올바른지 확인해주세요.");
        emailInputRef.value.focus();
        return;
      }

      clearInterval(state.timeout.timer);

      http.post("/api/member/join/auth-mail", {email}).then(({data}) => {
        store.commit("setSwingMessage", "입력하신 주소로 이메일을 발송하였습니다. 이메일로 받으신 인증 코드 6자리를 입력해주세요.");
        state.timeout.status = "ticking";
        state.timeout.minutes = "20";
        state.timeout.seconds = "00";

        state.timeout.timer = setInterval(() => {
          let minutes = window.Number(state.timeout.minutes);
          let seconds = window.Number(state.timeout.seconds);

          if (seconds === 0) {
            state.timeout.seconds = "59";
            state.timeout.minutes = lib.getZeroAddedNum(--minutes);
          } else {
            state.timeout.seconds = lib.getZeroAddedNum(--seconds);

            if (seconds === 0 && minutes === 0) {
              state.timeout.status = "expired";
              store.commit("setSwingMessage", "유효 입력 시간이 만료되었습니다.");
              clearInterval(state.timeout.timer);
            }
          }
        }, 1000);

        state.memberId = email;
        props.temp.insertMailSeq = data.body.insertMailSeq;
        codeInputRef.value.value = "";

        nextTick(() => {
          codeInputRef.value.focus();
        });
      }).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 409:
            return store.commit("setSwingMessage", "이미 사용 중인 이메일 주소입니다. 다른 이메일 주소를 입력해주세요.");
        }
      }));
    };

    const verifyEmail = () => {
      const args = {
        email: state.memberId,
        insertMailSeq: props.temp.insertMailSeq,
        authCode: codeInputRef.value.value
      };

      if (!args.authCode || args.authCode.length !== 6) {
        codeInputRef.value.focus();
        return store.commit("setSwingMessage", "인증 코드 6자리를 입력해주세요.");
      }

      http.put("/api/member/join/verify-email", args).then(() => {
        store.commit("setSwingMessage", "이메일 인증을 완료하였습니다.");
        store.commit("setJoinForm", {memberId: state.memberId});
        props.go(3);
      }).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 400:
            return store.commit("setSwingMessage", "인증번호가 일치하지 않습니다.");

          case 401:
            return store.commit("setSwingMessage", "인증 유효 시간이 지났습니다.");
        }
      }));
    };

    const verifyEmailBySns = (snsType) => {
      account.loginAtSnsPage(snsType, "join");
    };

    onMounted(() => {
      if (!store.state.joinForm.memberId) {
        env.device === "desktop" && emailInputRef.value.focus();
      }
    });

    onUnmounted(() => {
      store.commit("setCustomListener", ["appleIdSignInOnSuccess", undefined]);
      store.commit("setCustomListener", ["appleIdSignInOnFailure", undefined]);
    });

    return {component, state, emailInputRef, codeInputRef, computedSnsButtons, reset, sendCode, verifyEmail, verifyEmailBySns};
  }
});

</script>

<style lang="scss" scoped>
@import "../../styles/page.account";

.join-step2 {
  .sns {
    .icons {
      .btn {
        border: 0;
        box-shadow: none;
        width: $px50;
        height: $px50;
        transition: opacity 0.18s;

        &.naver {
          background-size: $px40;
        }

        &.kakao {
          background-size: $px60;
        }

        &.facebook {
          background-size: $px41;
        }

        &.apple {
          background-size: $px37;
        }

        &.google {
          background-size: $px25;
        }
      }
    }
  }

  .form {
    .form-control:not([type=checkbox]) {
      height: $formHeightLg;
      margin-bottom: $px15;
    }

    .btn {
      width: 100%;
      height: $formHeightLg;
      padding: 0;
      line-height: calc(#{$formHeightLg} - 2px);
    }
  }
}
</style>