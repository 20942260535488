<template>
  <div class="passport">
    <div class="image">
      <img src="/assets/img/page.passport.lock.gif" alt="자물쇠"/>
    </div>
    <template v-if="!state.sent">
      <div class="form-group">
        <label class="color-purple" :for="`${component.name}Mobile`">
          <b>휴대전화 번호</b>
          <span> 인증</span>
        </label>
        <small class="form-text text-muted">휴대전화 번호 입력 후 인증 코드 발송을 눌러주세요.</small>
        <Phone :id="`${component.name}Mobile`" placeholder="ex) 01012345678" :value.sync="state.form.mobile" className="border-focus-purple" :enter="submit"/>
      </div>
      <div class="actions">
        <button class="btn btn-purple" @click="submit()">인증 코드 발송</button>
        <button class="btn btn-bordered-purple mt-2" @click="submit(true)" v-if="$env.superPass">관리자 슈퍼 패스</button>
      </div>
    </template>
    <template v-else>
      <div class="form-group">
        <label class="color-purple" :for="`${component.name}Code`">
          <b>인증 코드</b>
          <span> 입력</span>
        </label>
        <small class="form-text text-muted">SMS로 받으신 인증 코드 6자리를 입력해주세요.</small>
        <Number :id="`${component.name}Code`" placeholder="ex) 123456" :allowZero="true" :noComma="true" :maxlength="6" autocomplete="off" :value.sync="state.form.code" className="border-focus-purple" :enter="submit"/>
      </div>
      <div class="actions">
        <button class="btn btn-purple" @click="submit()">인증 코드 확인</button>
        <button class="btn btn-bordered-purple mt-2" @click="reset()">다시 입력하기</button>
      </div>
    </template>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import env from "@/scripts/env";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import definitions from "@/scripts/definitions";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pagePassport";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      sent: false,
      form: {
        mobile: "",
        code: ""
      }
    });

    const reset = () => {
      state.sent = false;
      state.form.mobile = "";
      state.form.code = "";
    };

    const submit = (superPass) => {
      if (!lib.isValidMobileNum(state.form.mobile)) {
        document.getElementById(`${component.name}Mobile`)?.focus();
        return store.commit("setSwingMessage", "휴대전화 번호가 비어있거나 유효하지 않습니다.");
      } else if (state.sent && (!state.form.code?.trim() || state.form.code.length !== 6)) {
        document.getElementById(`${component.name}Code`)?.focus();
        return store.commit("setSwingMessage", "인증 코드 6자리가 비어있거나 유효하지 않습니다.");
      }

      const args = lib.getRenewed(state.form);

      if (superPass) {
        args.superPass = true;
        args.code = "777777";
      }

      http.put("/api/passport/issue", args).then(({data}) => {
        switch (data.code) {
          case "200 OK":
            state.sent = true;

            nextTick(() => {
              document.getElementById(`${component.name}Code`)?.focus();
            });

            return store.commit("setSwingMessage", data.message);

          case "202 ACCEPTED":
            store.dispatch("redirect");
            return store.commit("setSwingMessage", data.message);

          case "400 BAD_REQUEST":
            document.getElementById(`${component.name}Code`)?.select();
            return store.commit("setSwingMessage", data.message);

          case "410 GONE":
            reset();

            nextTick(() => {
              document.getElementById(`${component.name}Mobile`)?.focus();
            });

            return store.commit("setSwingMessage", data.message);

          case "429 TOO_MANY_REQUESTS":
            return store.commit("setSwingMessage", data.message);

          default:
            return store.commit("setSwingMessage", definitions.messages.errorInquire);
        }
      });
    };

    onMounted(() => {
      env.device === "desktop" && document.getElementById(`${component.name}Mobile`)?.focus();
    });

    return {component, state, reset, submit};
  }
});
</script>

<style lang="scss" scoped>
.passport {
  max-width: 300px;
  margin: 0 auto;
  padding: 50px 0 65px 0;
  text-align: center;

  .image {
    height: 160px;

    img {
      height: 100%;
    }
  }

  .form-group {
    label {
      font-size: 18px;
      margin-bottom: 5px;

      b {
        font-weight: 600
      }
    }

    small {
      margin-bottom: 20px;
    }

    input {
      height: 53px;
      text-align: center;
    }
  }

  .actions {
    .btn {
      padding: 15px 35px;
      font-size: 14px;
      width: 100%;
    }
  }
}
</style>