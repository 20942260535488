<template>
  <div class="callback">
    <div>
      <b class="font-xxl">{{ state.title }}</b>
    </div>
    <p class="mb-0">
      <span>{{ state.message }}</span>
    </p>
    <div class="pt-3">
      <a class="color-anchor pointer-u font-sm" v-if="state.fail" @click="moveBack()">이전 페이지</a>
      <template v-else>
        <span v-for="i in state.dotCnt" :key="i">.</span>
      </template>
    </div>
  </div>
</template>

<script>
import {defineComponent, onUnmounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import router from "@/scripts/router";
import definitions from "@/scripts/definitions";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";
import track from "@/scripts/track";
import account from "@/scripts/account";

function Component(initialize) {
  this.name = "pageEtcCallback";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      const getPayMethodName = (method) => {
        switch (method) {
          case "card":
            return "카드";

          case "naverpay":
            return "네이버페이";

          case "virtual":
            return "가상계좌";

          case "real":
            return "실시간 이체";

          case "point":
            return "쿠폰";

          case "omcpay":
            return "포인트";

          case "op_po":
            return "포인트 + 쿠폰";

          default:
            return method;
        }
      };

      if (["changePay", "pay"].includes(action) && router.app.$route.query?.imp_success === "false") {
        const payMethod = getPayMethodName(router.app.$route.query?.payMethod);
        state.title = "결제 실패";
        state.fail = true;
        state.message = router.app.$route.query?.error_msg || definitions.messages.errorCommon;
        state.message += `(결제 수단 : ${payMethod})`;
        return store.commit("setSwingMessage", state.message);
      }

      const displayError = () => {
        state.title = "오류";
        state.message = "잘못된 접근입니다.";
        state.fail = true;
      };

      const wait = (message) => {
        state.dotCnt = 0;
        clearTimeout(state.interval);

        if (message) {
          state.dotCnt = 1;
          state.message = message;
          state.interval = setInterval(() => state.dotCnt = state.dotCnt === 10 ? 1 : state.dotCnt + 1, 250);
        }
      };

      wait("잠시만 기다려주세요.");

      switch (action) {
        case "changePay": {
          store.commit("setSwingMessage", "결제 수단 변경이 완료되었습니다.");
          return router.replace({path: redirectUrl});
        }

        case "pay": {
          const fundingType = router.app.$route.query?.fundingType;
          const projectSeq = router.app.$route.query?.projectSeq;
          const merchantUid = router.app.$route.query?.merchant_uid?.replace("_4register", "");

          const onError = err => {
            state.title = "오류가 있습니다.";
            state.fail = true;
            state.message = err?.response?.data?.message || definitions.messages.errorCommon;
            wait(false);
          };

          wait("결제 정보를 가져오고 있습니다.");
          const res1 = await http.get(`/api/reward/project/${projectSeq}/invest-tmp/${merchantUid}`).catch(httpError(onError));

          if (res1.error) {
            return;
          }

          wait("프로젝트 정보를 가져오고 있습니다.");
          const res2 = await http.get(`/api/reward/projects/${projectSeq}`).catch(httpError(onError));

          if (res2.error) {
            return;
          }

          wait("결제 정보를 전송하고 있습니다.");
          const tmpInvestor = res1?.data?.body?.tmpInvestor;
          const projectName = res2?.data?.body?.project?.projectName;

          if (!tmpInvestor || !projectName) {
            return onError();
          }

          const afterRequest = (requestUrlPath, investorSeq) => {
            router.replace({path: `/reward/${projectSeq}/order/${investorSeq}`});

            track.post({
              name: "rewardProjectPay",
              category: "후원하기",
              topic: "후원 완료",
              title: projectName,
              memo: tmpInvestor.paymethod,
              urlPath: requestUrlPath,
              type: "api",
              httpMethod: "POST",
            });
          };

          if (fundingType === "A") {
            const url = `/api/reward/project/${projectSeq}/payments/schedule/${tmpInvestor.paymethod}`;

            const args = {
              merchantUid: merchantUid,
              memberId: store.state.account.memberId,
              memberSeq: store.state.account.memberSeq,
              buyerName: tmpInvestor.receiveName,
              buyerEmail: tmpInvestor.email,
              buyerTel: tmpInvestor.investorHp,
              amount: lib.getNumbered(tmpInvestor.expenseAmt, true) - lib.getNumbered(tmpInvestor.omcpay, true) - lib.getNumbered(tmpInvestor.couponPoint, true),
              name: projectName,
              buyerAddr1: tmpInvestor.addr1,
              buyerAddr2: tmpInvestor.addr2,
              buyerPostcode: tmpInvestor.postno
            };

            http.post(url, args).then(({data}) => {
              afterRequest(url, data.body.investorSeq);
            }).catch(httpError(onError));
          } else {
            const impUid = router.app.$route.query?.imp_uid;
            const url = `/api/reward/project/${projectSeq}/payments/${tmpInvestor.paymethod}`;

            const args = {
              imp_uid: impUid,
              merchant_uid: merchantUid,
              paymethod: tmpInvestor.paymethod,
              customData: {
                memberSeq: tmpInvestor.memberSeq,
                memberId: store.state.account.memberId,
                buyerEmail: tmpInvestor.email
              }
            };

            http.post(url, args).then(({data}) => {
              if (data.body.response.pay_method === "card" && data.body.response.status !== "paid") {
                return store.commit("setSwingMessage", data.body.response.message || definitions.messages.errorCommon);
              } else if (data.body.response.pay_method === "vbank" && data.body.response.status !== "ready") {
                return store.commit("setSwingMessage", data.body.response.message || definitions.messages.errorCommon);
              }

              afterRequest(url, data.body.investorSeq);
            }).catch(httpError(onError));
          }
          return;
        }

        case "join":
        case "login": {
          const snsType = router.app.$route.params?.param2;

          const args = {
            page: action,
            snsType,
            token: "",
            callbackUrl: "",
          };

          redirectUrl = "";

          const getObjFromHash = () => {
            const obj = {};
            const hashes = router.app.$route.hash.replace("#", "").split("&");

            for (let i in hashes) {
              const keyValue = hashes[i].split("=");

              if (keyValue.length === 2) {
                obj[keyValue[0]] = keyValue[1];
              }
            }

            return obj;
          };

          switch (snsType) {
            case "naver": {
              const obj = getObjFromHash();
              args.token = obj["access_token"];

              if (!args.token) {
                return displayError();
              }

              redirectUrl = router.app.$route.query.redirectUrl;
              break;
            }

            case "kakao": {
              const state = router.app.$route.query.state?.toString();
              args.token = router.app.$route.query.code;

              if (!args.token || !lib.isJsonString(state)) {
                return displayError();
              }

              redirectUrl = window.JSON.parse(state).redirectUrl;
              break;
            }

            case "google": {
              const obj = getObjFromHash();
              const state = window.decodeURIComponent(obj["state"]);
              args.token = obj["access_token"];

              if (!args.token || !lib.isJsonString(state)) {
                return displayError();
              }

              redirectUrl = window.JSON.parse(state).redirectUrl;
              break;
            }

            case "apple": {
              redirectUrl = router.app.$route.query.redirectUrl;
              break;
            }

            case "facebook": {
              const state = router.app.$route.query.state?.toString();
              args.token = router.app.$route.query.code;

              if (!args.token || !lib.isJsonString(state)) {
                return displayError();
              }

              args.callbackUrl = `${window.location.origin}/callback/${action}/facebook`;
              redirectUrl = window.JSON.parse(state).redirectUrl;
              break;
            }

            default:
              return displayError();
          }

          account.loginBySns(args, action, redirectUrl);
          break;
        }

        default:
          displayError();
          break;
      }
    });

    const state = reactive({
      title: "처리 중입니다.",
      message: "",
      fail: false,
      dotCnt: 0,
      interval: 0,
    });

    const action = router.app.$route.params?.param1;
    let redirectUrl = router.app.$route.query?.redirectUrl?.toString();

    const moveBack = () => {
      if (action === "pay") {
        router.replace({path: `/reward/${router.app.$route.query?.projectSeq}`});
      } else {
        store.dispatch("moveBack", redirectUrl || "/");
      }
    };

    onUnmounted(() => {
      clearInterval(state.interval);
    });

    return {component, state, moveBack};
  }
});
</script>

<style lang="scss" scoped>
.callback {
  div {
    margin-bottom: $px13;
  }
}
</style>