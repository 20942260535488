<template>
  <Component :is="computedComponent" :key="computedKey"/>
</template>

<script>
import {computed, defineAsyncComponent, defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentModal";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    name: String,
    params: Object
  },
  setup(props) {
    const component = new Component(() => {
    });

    const computedKey = computed(() => {
      const arr = [props.name];
      props.params && arr.push(JSON.stringify(props.params));
      return arr.join("");
    });

    const computedComponent = computed(() => {
      if (props.name && props.name[0] === props.name[0].toLowerCase()) {
        console.error(`모달 이름(${props.name})은 소문자로 시작할 수 없습니다.`);
        return;
      }

      return defineAsyncComponent(() => import(`@/modals/${props.name}`));
    });

    return {component, computedKey, computedComponent};
  },
});
</script>