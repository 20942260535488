<template>
  <div class="reservation-card" :class="{skeleton, clickable}" :data-page="page">
    <div class="inner1">
      <div class="inner2">
        <div class="background">
          <div title="대표 리워드" class="reward" v-if="page !== 'home' && reservationCate === 'reward' && (price && item)">
            <span>{{ item }}</span>
            <span>{{ $lib.getNumberFormat(price) }}원</span>
          </div>
          <Anchor :href="`/channel/${memberSeq}?tabs=projects`" class="name" title="진행자 페이지로 이동" v-if="page !== 'home'">{{ memberName }}</Anchor>
          <Anchor :href="link" v-if="clickable || page === 'home'">
            <div class="bg" :class="page" :style="{ backgroundImage: `url(${computedThumbnailUrl})`, backgroundPosition: mainImagePosition || 'center' }"></div>
          </Anchor>
          <div v-else class="bg" :style="{ backgroundImage: `url(${computedThumbnailUrl})`, backgroundPosition: mainImagePosition || 'center' }"></div>
        </div>
        <div class="below">
          <div class="texts">
            <div class="proj">
              <div class="above">
                <div class="title">
                  <template v-if="page !== 'home'">
                    <span class="type">{{ projectType }}</span>
                    <span class="bar"></span>
                  </template>
                  <Anchor :href="link" v-if="clickable || page === 'home'">
                    <span class="project-name">{{ projectName }}</span>
                  </Anchor>
                  <span v-else class="project-name">{{ projectName }}</span>
                </div>
                <div class="desc" v-if="page !== 'home'">
                  <span>{{ simpleText }}</span>
                </div>
              </div>
              <div class="apply">
                <div class="txt ellipsis color-point">
                  <b>{{ state.applyCnt }}명</b>
                  <span>이 신청했어요</span>
                </div>
                <span class="alarm" @click="book()" :title="state.applyFlag === '1' ? '알림 신청 취소' : '알림 신청'" v-if="page === 'home'">
                    <i class="fa" :class="state.applyFlag === '1' ? 'fa-bell' : 'fa-bell-o'"></i>
                  </span>
              </div>
            </div>
          </div>
          <div class="act" v-if="page !== 'home'">
            <ReservationBtn :page="page"
                            :project="{reservationSeq, applyFlag: state.applyFlag, reservationCate}"
                            :skeleton="skeleton"
                            :change="change"
                            :clickable="clickable"
                            :share="{ link, title: projectName, imgUrl: computedThumbnailUrl }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import MemberPicture from "./MemberPicture";
import mixin from "@/scripts/mixin";
import ReservationBtn from "@/components/ReservationBtn";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";

function Component(initialize) {
  this.name = "componentReservationCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, ReservationBtn, MemberPicture},
  props: {
    applyFlag: String,
    applyCnt: Number,
    clickable: Boolean,
    item: String,
    link: String,
    memberName: String,
    mainImagePosition: String,
    memberSeq: String,
    page: String,
    projectName: String,
    price: Number,
    projectSeq: String,
    projectType: String,
    reservationCate: String,
    reservationSeq: Number,
    skeleton: Boolean,
    simpleText: String,
    thumbFilePath: String,
  },
  setup(props) {
    const component = new Component(() => {
      state.applyCnt = props.applyCnt;
      state.applyFlag = props.applyFlag;
    });

    const state = reactive({
      applyCnt: 0,
      applyFlag: "0"
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(props.thumbFilePath);
    });

    const change = (applyFlag) => {
      state.applyFlag = applyFlag;
      state.applyCnt = state.applyCnt + (applyFlag === "0" ? -1 : 1);
    };

    const book = () => {
      const args = {
        reservationSeq: props.reservationSeq,
        projectName: props.projectName,
        applyFlag: state.applyFlag,
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("reserveProjectOpenNotice", args);
      }

      redirectCallback.run("reserveProjectOpenNotice", {
        ...args,
        change: change
      });
    };

    return {component, state, computedThumbnailUrl, book, change};
  },
});
</script>

<style lang="scss" scoped>
.reservation-card {
  //border: $px1 solid #eee;
  border: none;
  border-radius: $px4;

  .inner1 {
    overflow: hidden;

    .inner2 {
      position: relative;
      //padding-bottom: $px55;

      > .background {
        display: block;
        padding-top: $ratio43Percent;
        position: relative;
        overflow: hidden;
        transition: filter 0.18s;
        border-radius: $px4 $px4 0 0;
        border-bottom: $px1 solid #eee;

        .reward {
          font-size: $px12;
          color: #fff;
          position: absolute;
          top: $px10;
          left: $px10;
          z-index: 10;

          > span {
            display: block;
          }
        }

        .name {
          bottom: $px10;
          right: $px10;
          font-size: $px12;
          color: #fff;
          text-decoration: none;
          position: absolute;
          z-index: 1;
        }

        .bg {
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-color: #eee;
          width: 100%;
          height: 100%;
          display: inline-block;
          transition: transform 0.18s;
          position: absolute;
          top: 0;
          left: 0;
          filter: brightness(0.9);
        }
      }

      > .below {
        position: relative;

        > .texts {
          position: relative;
          margin-top: $px12;

          .proj {
            .above {
              overflow: hidden;
              height: $px123;

              .title {
                font-size: 0;
                line-height: 1.4;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: 500;

                a {
                  vertical-align: middle;
                  text-decoration: none;
                }

                span {
                  vertical-align: middle;
                }

                .project-name {
                  font-size: $px18;
                }

                .type {
                  font-size: $px13;
                  color: #3c3c3c;
                }

                .bar {
                  display: inline-block;
                  margin-left: $px7;
                  margin-right: $px7;
                  margin-top: $px1;
                  height: $px10;
                  border-right: $px1 solid #aaa;
                }
              }

              .desc {
                word-wrap: break-word;
                color: #777;
                padding-top: 0;
                width: 100%;
                font-size: $px14;
                margin-top: $px7;
                letter-spacing: -.25px;

                span {
                  white-space: normal;
                  word-break: break-all;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical
                }
              }
            }

            .apply {
              margin-top: $px8;
              font-size: $px14;

              .txt {
                > span, > b {
                  vertical-align: middle;
                }
              }
            }
          }
        }

        .act {
          width: 100%;
          margin-top: $px16;

          .reservation-btn::v-deep {
          }
        }
      }
    }
  }

  &.skeleton {
    .inner1 .inner2 {
      > .background {
        @include skeleton;

        span, a {
          text-shadow: none;
          @include skeleton;
        }

        .bg {
          visibility: hidden;
        }
      }

      > .below {
        > .texts .proj {
          .above {
            .title {
              @include skeleton;

              span {
                @include skeleton;
              }

              .bar {
                border-color: transparent;
              }

              a {
                color: transparent;
              }
            }

            .desc > span {
              @include skeleton;
              color: transparent;
            }
          }

          .apply > .txt {
            > b, > span {
              @include skeleton;
            }

            .alarm {
              padding: 0;
              bottom: 0;
              right: 0;
            }
          }
        }
      }

      > .heart {
        display: none;
      }

      .act {
        .btn {
          @include skeleton;
        }
      }
    }
  }

  &[data-page=home] {
    .inner1 .inner2 {
      > .background {
        .name {
          font-size: $px12;
        }
      }

      > .below {
        .texts .proj {
          .above {
            height: $px45;

            .title {
              .project-name {
                font-size: $px15;
              }
            }
          }

          .apply {
            margin-top: 0;
            font-size: $px13;

            .alarm {
              position: absolute;
              bottom: $px-8;
              right: $px-4;
              color: $colorPoint;
              font-size: $px20;
              padding: $px4;
              cursor: pointer;
              transition: color 0.18s;

              &:hover {
                color: $colorPointActive;
              }
            }
          }
        }
      }
    }
  }

  &.clickable:not(.skeleton) {
    &:hover {
      .inner1 .inner2 .background {
        .bg {
          &.home {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    &[data-page=list] .inner1 .inner2 {
      > .below {
        .texts {
          .proj {
            .above {
              height: auto;
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    &[data-page=home] .inner1 .inner2 {
      > .background {
        .name {
          font-size: $px10;
        }
      }

      > .below {
        .texts {
          margin-top: $px8;
          padding-top: 0 !important;

          .proj {
            .above {
              height: $px37;

              .title {
                margin-bottom: 0;
                line-height: 1.4;

                .type {
                  font-size: $px10;
                }

                .bar {
                  margin: 0 $px4;
                }

                .project-name {
                  font-size: $px12;
                }
              }
            }

            .apply {
              font-size: $px12;
              margin-top: $px8;

              .alarm {
                font-size: $px14;
                bottom: $px-4;
              }
            }
          }
        }

        .act {
          margin-top: $px8;
        }
      }
    }

    .inner1 .inner2 {
      > .below {
        .texts {
          .proj {
            .above {
              .desc > span {
                -webkit-line-clamp: 3;
              }
            }
          }
        }
      }
    }

    &.skeleton {
      .inner1 .inner2 > .below > .texts .proj .above {
        height: $px37;

        .title {
          height: $px20;
        }
      }
    }
  }
}
</style>